import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Landing from '../views/Landing.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/',
  //   name: 'Landing',
  //   component: Landing
  // },
  // Dynamic imports. Components are lazy-loaded when the route is visited
  // Generates a separate chunk for every component, e.g. jobs.[hash].js
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
    meta: {
      requiresAuth: true
    },
  },
    {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/vacancy',
    name: 'Vacancy',
    component: () => import(/* webpackChunkName: "vacancy" */ '../views/Vacancy.vue'),
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: () => import(/* webpackChunkName: "jobs" */ '../views/Jobs.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/hire',
    name: 'Hire',
    component: () => import(/* webpackChunkName: "hire" */ '../views/Hire.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/partnership',
    name: 'Partnership',
    component: () => import(/* webpackChunkName: "partnership" */ '../views/Partnership.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "faq" */ '../views/FAQ.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/careers',
    name: 'Careers',
    component: () => import(/* webpackChunkName: "careers" */ '../views/Careers.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: () => import(/* webpackChunkName: "sign-in" */ '../views/SignIn.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/PrivacyPolicy.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    component: () => import(/* webpackChunkName: "terms-of-use" */ '../views/TermsOfUse.vue'),
    meta: {
      requiresAuth: false,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
