<template>
  <div class="media-slider">
    <div class="media-slider__inner">
      <div 
        class="media-slider__inner__item-button media-slider__inner__item-button--left"
        @click="loadPreviousImage()"
      >
        <div class="media-slider__inner__item-button__overlay">
          <img 
            class="media-slider__inner__item-button__overlay__arrow" 
            src="../assets/arrow-left.svg"
          >
        </div>
        <img 
          :src="require(`../assets/${previousItem.img}`)"
          v-if="previousItem"
        >
      </div>
      <div class="media-slider__inner__item">
        <div class="media-slider__inner__item__media">
          <img :src="require(`../assets/${items[activeIndex].img}`)">
        </div>
        <div class="media-slider__inner__item__main-content">
          <img class="media-slider__inner__item__main-content__quotes" src="../assets/quotes.svg">
          <div class="media-slider__inner__item__main-content__text">{{items[activeIndex].text}}</div>
          <div class="media-slider__inner__item__main-content__name">{{items[activeIndex].name}}</div>
          <div class="media-slider__inner__item__main-content__job">{{items[activeIndex].jobPosition}}</div>
        </div>
      </div>
      <div 
        class="media-slider__inner__item-button media-slider__inner__item-button--right"
        @click="loadNextImage()"
      >
        <div class="media-slider__inner__item-button__overlay">
          <img 
            class="media-slider__inner__item-button__overlay__arrow" 
            src="../assets/arrow-right.svg"
          >
        </div>
        <img 
          :src="require(`../assets/${nextItem.img}`)"
          v-if="nextItem"
        >
      </div>
    </div>
    <div class="media-slider__progress">
      <img
       src="../assets/button-arrow-left.svg"
        @click="loadPreviousImage()"
      >
      <div class="media-slider__progress__dot-container">
        <div 
          class="media-slider__progress__dot-container__item"
          v-for="(item, index) in items.length"
          :key="'item-' + index"
          :is-active="index === activeIndex"
        ></div>
      </div>
      <img
       src="../assets/button-arrow-right.svg"
        @click="loadNextImage()"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'MediaSlider',
  props: {
    items: Array
  },
  data () {
    return {
      activeIndex: 1
    }
  },
  computed: {
    previousItem () {
      const previousIndex = this.activeIndex - 1
      if (previousIndex < 0) {
        return this.items[this.items.length - 1]
      }
      else {
        return this.items[previousIndex]
      }
    },
    nextItem () {
      const nextIndex = this.activeIndex + 1
      if (nextIndex > this.items.length - 1) {
        return this.items[0]
      }
      else {
        return this.items[nextIndex]
      }
    }
  },
  methods: {
    loadPreviousImage () {
      const previousIndex = this.activeIndex - 1
      if (previousIndex < 0) {
        return this.activeIndex = this.items.length - 1
      }
      else {
        return this.activeIndex -= 1
      }
    },
    loadNextImage () {
      const nextIndex = this.activeIndex + 1
      if (nextIndex > this.items.length - 1) {
        return this.activeIndex = 0
      }
      else {
        return this.activeIndex += 1
      }
    },
  }
}
</script>

<style lang="scss">
.media-slider {
  &__inner {
    display: grid;
    grid-template-columns: 150px 1fr 150px;
    gap: 24px;
    align-items: center;
    justify-content: center;
    @media (max-width: $md) {
      height: 550px;
      display: block;
    }
    @media (max-width: 650px) {
      height: unset;
    }
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 650px) {
        flex-direction: column;
      }
      &__media {
        position: relative;
        height: 415px;
        width: 285px;
        object-fit: cover;
        margin-right: 24px;
        @media (max-width: 650px) {
          margin-right: 0;
        }
        img {
          width: 285px;
          height: 100%;
          height: 415px;
          object-fit: cover;
          @media (max-width: $sm) {
            width: 150px;
          }
          @media (max-width: 650px) {
            width: 100%;
            height: 100%;
          }
        }
        @media (max-width: $sm) {
          width: 150px;
        }
        @media (max-width: 650px) {
          width: 100%;
          height: 200px;
        }
      }
      &__main-content {
        &__quotes {
          transform: translateX(-20px);
          @media (max-width: $sm) {
            width: 80px;
            height: 80px;
          }
        }
        &__text {
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 161%;
          letter-spacing: 0.04em;
          margin-bottom: 48px;
          @media (max-width: $sm) {
            font-size: 14px;
          }
          @media (max-width: 650px) {
            margin-bottom: 24px;
          }
        }
        &__name {
          font-weight: 600;
          font-size: 24px;
          line-height: 115%;
          letter-spacing: -0.045em;
        }
        &__job {
          font-weight: 300;
          font-size: 14px;
          line-height: 161%;
          letter-spacing: 0.04em;
          margin-bottom: 48px;
          @media (max-width: 650px) {
            margin-bottom: 24px;
          }
        }
      }
    }
    &__item-button {
      position: relative;
      width: 100%;
      height: 415px;
      object-fit: cover;
      cursor: pointer;
      user-select: none;
      transition: all 0.5s;
      &:hover {
        transform: scale(1.02);
        transition: all 0.5s;
      }
      @media (max-width: $md) {
        display: none;
      }
      &__overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background:
          linear-gradient(0deg, rgba(0, 81, 238, 0.6), 
          rgba(0, 81, 238, 0.6));
        &__arrow {
          user-select: none;
          pointer-events: none;
          width: 44px !important;
          height: 44px !important;
        }
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  &__progress {
    @media (max-width: $md) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__dot-container {
      display: flex;
      align-items: center;
      justify-content: center;
      &__item {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin: 4px;
        background-color: var(--blue-main);
        opacity: 0.4;
        &[is-active] {
          opacity: 1;
        }
      }
    }
    img {
      display: none;
      cursor: pointer;
      user-select: none;
      @media (max-width: $md) {
        display: block;
      }
    }
  }
}
</style>