<template>
  <footer class="main-footer" v-if="showFooter">
    <div class="main-footer__inner">
      <div class="main-footer__inner__header">
        <img 
          class="main-footer__inner__header__logo"
          @click="$router.push('/').catch(error => {})"
          src="../assets/logo.png" 
          alt="Humanicl logo"
        >
        <div class="main-footer__inner__main-content__section-4--sm">
          <dropdown-menu
            :items="availableLanguages"
            v-model="selectedLanguage"
            item-text="name"
          >
            <template v-slot:prepend>
              <i class="mdi mdi-web"></i>
            </template>
          </dropdown-menu>
        </div>
      </div>

      <div class="main-footer__inner__main-content">
        <div class="main-footer__inner__main-content__section-1">
          <div 
            class="main-footer__inner__main-content__section-1__text-1" 
            v-html="$localize.get('text_open_world_remotely')"
          ></div>
          <h-button
            @click.native="$store.dispatch('OPEN_LOGIN_PAGE')"
            large outlined green small-padding
          >{{$localize.get('join_platform')}}
          </h-button>
        </div>

        <div class="main-footer__inner__main-content__section-2">
          <div class="main-footer__nav" v-if="$route.name !== 'Landing'">
            <div 
              class="main-footer__nav__item fade-in"
              v-for="(navItem, index) in navItemsLocalized"
              :key="'nav-item' + index"
            >
              <div
                v-if="navItem.onClick || navItem.onClickAction"
              >{{navItem.text}}
              </div>

              <a
                v-else-if="navItem.link"
                :href="navItem.link"
                target="_blank"
              >{{navItem.text}}
              </a>
              
              <router-link
                v-else 
                :to="navItem.to"
              >
                {{navItem.text}}
              </router-link>
            </div>
          </div>
        </div>

        <div 
          class="main-footer__inner__main-content__careers-button--sm"
        >
          <h-button
            @click.native="$store.dispatch('OPEN_LOGIN_PAGE')"
            large outlined green small-padding
          >{{$localize.get('join_platform')}}
          </h-button>
        </div>

        <div class="main-footer__inner__main-content__section-3">
          <div 
            class="main-footer__inner__main-content__section-3__text-1"
            v-html="$localize.get('company_address')"
          ></div>
          <div class="main-footer__inner__main-content__section-3__social-icons">
            <a href="https://t.me/humanicl" target="_blank">
              <img src="../assets/icon-telegram.svg" alt="telegram social icon">
              @humanicl
            </a>
          </div>
        </div>

        <div class="main-footer__inner__main-content__section-4">
          <dropdown-menu
            :items="availableLanguages"
            v-model="selectedLanguage"
            item-text="name"
            font-20
          >
            <template v-slot:prepend>
              <i class="mdi mdi-web"></i>
            </template>
          </dropdown-menu>
        </div>
      </div>
    </div>
    
    <div class="main-footer__inner__bottom-content">
      <div class="main-footer__inner__bottom-content__inner">
        <div class="main-footer__inner__bottom-content__inner__copyright">
          Copyright © 2021 Humanicl. All Rights reserved.
        </div>
        <div class="main-footer__inner__bottom-content__inner__copyright--sm">
          Copyright © 2021 Humanicl.<br>All Rights reserved.
        </div>
        <div class="main-footer__inner__bottom-content__inner__buttons">
          <h-button
          @click.native="$router.push('privacy-policy')"
            text blue no-padding
          >{{$localize.get('privacy_policy')}}
          </h-button>
          <h-button
          @click.native="$router.push('terms-of-use')"
            text blue no-padding
          >{{$localize.get('terms_of_use')}}
          </h-button>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MainFooter',
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'navItemsLocalized',
    ]),
    availableLanguages () {
      return this.$store.state.storageData.settings.localization.availableLanguages
    },
    selectedLanguage: {
      get () {
        return this.$store.state.storageData.settings.localization.selectedLanguage
      },
      set (value) {
        this.$store.state.storageData.settings.localization.selectedLanguage = value
      }
    },
    showFooter () {
      const disallowedViews = ['SignIn', 'Profile', 'Admin', 'Vacancy']
      return !disallowedViews.includes(this.$route.name)
    }
  },
  methods: {
    handleCareersBtnClick () {
      if (this.$route.name === 'Landing') {
        this.$store.dispatch('OPEN_LOGIN_PAGE')
      }
      else {
        this.$router.push('careers').catch(error => {})
      }
    }
  },
}
</script>

<style lang="scss">
.main-footer {
  width: 100%;
  padding-top: 48px;
  background-color: var(--light-blue-bg-color);
  &__inner {
    margin: auto;
    max-width: var(--max-content-width);
    width: var(--main-view-section-width);
    @media (max-width: $xs) {
      width: var(--main-view-section-width-xs);
    }
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 375px) {
        flex-direction: column;
      }
      &__logo {
        width: 160px;
        height: 28px;
        cursor: pointer;
        @media (max-width: 375px) {
          margin-bottom: 16px;
        }
      }
    }
    &__main-content {
      display: grid;
      margin: 48px 0;
      grid-template-columns: 1fr 1fr 1.4fr 0.3fr;
      gap: 48px;
      @media (max-width: 1200px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
      @media (max-width: 770px) {
        grid-template-columns: 1fr;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      &__careers-button {
        &--sm {
          display: none;
          @media (max-width: 770px) {
            display: block;
          }
        }
      }
      &__section-1 {
        &__text-1 {
          width: 200px;
          font-weight: 600;
          font-size: 18px;
          line-height: 161%;
          letter-spacing: 0.04em;
          margin-bottom: 24px;
        }
        @media (max-width: 770px) {
          display: none;
        }
      }
      &__section-2 {
        @media (max-width: 770px) {
          font-size: 32px;
        }
      }
      &__section-3 {
        display: flex;
        flex-direction: column;
        font-weight: 600;
        font-size: 18px;
        @media (max-width: 770px) {
          align-items: center;
          justify-content: center;
        }
        &__text-1 {
          margin-bottom: 12px;
          @media (max-width: 770px) {
            width: 100%;
          }
        }
        &__text-2 {
          margin-bottom: 24px;
          @media (max-width: 770px) {
            margin-bottom: 56px;
          }
        }
        &__social-icons {
          display: flex;
          align-items: center;
          a {
            display: flex;
            align-items: center;
            text-decoration: none;
            &:not(:last-child) {
              margin-right: 32px;
            }
          }
          img {
            width: 22px;
            height: 22px;
            margin-right: 8px;
          }
        }
      }
      &__section-4 {
        display: block;
        @media (max-width: 1200px) {
          display: none;
        }
        &--sm {
          display: none;
          margin-top: 8px;
          @media (max-width: 375px) {
            margin-left: 32px;
          }
          @media (max-width: 1200px) {
            display: block;
          }
          .mdi-web {
            @media (max-width: $xs) {
              display: none;
            }
          }
        }
      }
    }
    &__bottom-content {
      height: 98px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      padding: 16px 0;
      @media (max-width: $sm) {
        height: unset;
        padding: 16px 0;
      }
      &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: var(--max-content-width);
        margin: auto;
        color: var(--blue-main);
        opacity: 0.5;
        font-weight: 600;
        width: var(--main-view-section-width);
        @media (max-width: $md) {
          flex-direction: column;
          text-align: center;
        }
        @media (max-width: $xs) {
          width: var(--main-view-section-width-xs);
        }
        &__copyright {
          font-size: 18px;
          display: block;
          @media (max-width: $sm) {
            display: none;
          }
        }
        &__copyright--sm {
          display: none;
          font-size: 18px;
          @media (max-width: $sm) {
            margin-bottom: 8px;
            display: block;
          }
        }
        &__buttons {
          display: flex;
          align-items: center;
          flex-direction: column;
          @media (min-width: $sm) {
            flex-direction: row;
          }
          .h-button {
            &:first-child {
              @media (min-width: $sm) {
                margin-right: 48px
              }
            }
          }
        }
      }
    }
  }
  &__nav {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 18px;
    @media (max-width: 770px) {
      font-size: 24px;
      margin: 12px 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__item {
      &:not(:last-child) {
        margin-bottom: 12px;
        @media (max-width: 770px) {
          margin-bottom: 48px;
        }
      }
    }
    a {
      width: fit-content;
      color: #2c3e50;
      text-decoration: none;
      padding: 6px 0;
      &.router-link-exact-active {
        position: relative;
      }
    }
  }
}
.main-footer__nav__item {
  cursor: pointer;
  &:hover {
   color: var(--blue-main);
   transition: all 0.3s;
 }
}
.v-application .main-footer__nav a {
  color: #2c3e50;
   &:hover {
    color: var(--blue-main);
    transition: all 0.3s;
  }
  &.router-link-exact-active {
    color: var(--blue-main);
  }
} 
</style>
