<template>
  <div id="home-view">
    <div class="content">
    <!-- <v-btn @click="$store.dispatch('SIGN_IN')">вход</v-btn>
    <v-btn @click="$store.dispatch('SIGN_UP')">регистрация</v-btn>
    <v-btn @click="$store.dispatch('SIGN_OUT')">выход</v-btn> -->

      <content-section
        type="main"
        asset="17494b064bf99f6c990fc1801e84943e-left.jpg"
        :title="$localize.get('careers_page_main_title')"
        :subtitle="$localize.get('careers_page_main_subtitle')"
      >
        <template v-slot:buttons>
          <h-button
            class="button--join"
            @click.native="$store.dispatch('OPEN_LOGIN_PAGE')"
            large filled green
          >{{$localize.get('join_platform')}}
          </h-button>
        </template>
      </content-section>

      <content-section
        type="sub-section"
        :title="$localize.get('about_us')"
      />

      <content-section
        id="section-about-us"
        type="simple"
        blue-bg
        center-buttons
      >
        <template v-slot:content>
          <ul 
            class="section--about-us__content__list" 
            v-html="$localize.get('about_us_content')"
          ></ul>
        </template>
        <template v-slot:buttons>
          <h-button
            class="button--join"
            @click.native="$store.dispatch('OPEN_LOGIN_PAGE')"
            large filled green
          >{{$localize.get('join_platform')}}
          </h-button>
        </template>
      </content-section>

      <content-section
        type="sub-section"
        :title="$localize.get('workflow')"
      />
     
      <content-section
        type="workflow-section"
        :tabs="workflowSectionTabs"
        blue-bg
      />

      <content-section
        type="sub-section"
        :title="$localize.get('features')"
      />

      <content-section
        type="simple"
        blue-bg
        center-buttons
      >
        <template v-slot:content>
          <div class="card-container">
            <card 
              v-for="(card, index) in featuresCards"
              :key="'feature-card-' + index"
              feature-card
            >
              <div class="card__preview">
                <img :src="card.imgSrc">
              </div>
              <div class="card__sub-title">
                {{card.description}}
              </div>
            </card>
          </div>
        </template>
        <template v-slot:buttons>
          <h-button
            class="button--join"
            @click.native="$store.dispatch('OPEN_LOGIN_PAGE')"
            large filled green
          >{{$localize.get('join_platform')}}
          </h-button>
        </template>
      </content-section>
      
      <content-section
        type="sub-section"
        :title="$localize.get('advantages')"
      />
     
      <content-section
        type="advantages-section"
        :tabs="advantagesSectionTabs"
        blue-bg
      />
  
      <content-section
        type="simple"
        :title="$localize.get('testimonials')"
        center-buttons
      >
        <template v-slot:content>
          <media-slider
            :items="testimonials"
          ></media-slider>
        </template>
        <template v-slot:buttons>
          <h-button
            class="button--join"
            @click.native="$store.dispatch('OPEN_LOGIN_PAGE')"
            large filled green
          >{{$localize.get('join_platform')}}
          </h-button>
        </template>
      </content-section>
     
      <content-section
        type="slogan-section"
      />
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'Home',
  data () {
    return {
    }
  },
  computed: {
    ...mapFields({
      dialogs: 'dialogs'
    }),
    testimonials () {return this.$store.state.testimonials},
    featuresCards () {
      return [
        {
          imgSrc: require('../assets/icon-contract.svg'),
          description: this.$localize.get('single_contact')
        },
        {
          imgSrc: require('../assets/icon-interview.svg'),
          description: this.$localize.get('automated_interview')
        },
        {
          imgSrc: require('../assets/icon-clock.svg'),
          description: this.$localize.get('automated_system')
        },
        {
          imgSrc: require('../assets/icon-auto-generation.svg'),
          description: this.$localize.get('automatic_generation')
        },
        {
          imgSrc: require('../assets/icon-chat.svg'),
          description: this.$localize.get('built_in_messaging')
        },
        {
          imgSrc: require('../assets/icon-cv.svg'),
          description: this.$localize.get('depersonalized_cv')
        },
      ]
    },
    workflowSectionTabs () {
      return {
        headers: [
          // this.$localize.get('for_customers'),
          this.$localize.get('for_it_agencies')
        ],
        content: [
          [
            {
              title: this.$localize.get('post_your_project'),
              subtitle: this.$localize.get('post_your_project_subtitle')
            },
            {
              title: this.$localize.get('interview'),
              subtitle: this.$localize.get('interview_subtitle')
            },
            {
              title: this.$localize.get('work_on_project'),
              subtitle: this.$localize.get('work_on_project_subtitle')
            },
            {
              title: this.$localize.get('completion_of_the_project'),
              subtitle: this.$localize.get('completion_of_the_project_subtitle')
            },
          ],
          [
            {
              title: this.$localize.get('project'),
              subtitle: this.$localize.get('post_your_project_subtitle_2')
            },
            {
              title: this.$localize.get('interview'),
              subtitle: this.$localize.get('interview_subtitle_2')
            },
            {
              title: this.$localize.get('work_on_project'),
              subtitle: this.$localize.get('work_on_project_subtitle_2')
            },
            {
              title: this.$localize.get('completion_of_the_project'),
              subtitle: this.$localize.get('completion_of_the_project_subtitle_2')
            },
          ]
        ]
      }
    },
    advantagesSectionTabs () {
      return {
        headers: [
          // this.$localize.get('for_customers'),
          this.$localize.get('for_it_agencies')
        ],
        content: [
          [
            {
              title: this.$localize.get('top_skills'),
              subtitle: this.$localize.get('top_skills_subtitle'),
              icon: 'icon-top-skills-tools.svg'
            },
            {
              title: this.$localize.get('no_risk'),
              subtitle: this.$localize.get('no_risk_subtitle'),
              icon: 'icon-no-risk-calendar.svg'
            },
            {
              title: this.$localize.get('engagement'),
              subtitle: this.$localize.get('engagement_subtitle'),
              icon: 'icon-engagement.svg'
            },
            {
              title: this.$localize.get('cost_management'),
              subtitle: this.$localize.get('cost_management_subtitle'),
              icon: 'icon-cost-management.svg'
            }
          ],
          [
            {
              title: this.$localize.get('no_risk'),
              subtitle: this.$localize.get('no_risk_subtitle_2'),
              icon: 'icon-no-risk-card.svg'
            },
            {
              title: this.$localize.get('extra_income'),
              subtitle: this.$localize.get('extra_income_subtitle'),
              icon: 'icon-extra-income.svg'
            },
            {
              title: this.$localize.get('international_market'),
              subtitle: this.$localize.get('international_market_subtitle'),
              icon: 'icon-international-market.svg'
            },
            {
              title: this.$localize.get('engagement'),
              subtitle: this.$localize.get('engagement_subtitle_2'),
              icon: 'icon-engagement-2.svg'
            },
          ]
        ]
      }
    }
  }
}
</script>

<style lang="scss">
</style>