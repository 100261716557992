import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router/index.js'
import { getField, updateField } from 'vuex-map-fields'
import countries from '../utils/countires.js'
const localize = require('../utils/localize')  
const axios = require('axios')

Vue.use(Vuex)

export default new Vuex.Store({
  strict: false,
  state: {
    backendAuthURL: '/v1/auth',
    countries,
    user: {
      loggedIn: false,
      signIn: {
        type: 'client',
        action: 'sign-up'
      }
    },
    mainDrawer: {
      value: false
    },
    drawer: {
      value: false
    },
    overlays: {
      window: false
    },
    dialogs: {
      joinPlatform: {
        value: false
      },
      prompt: {
        value: false,
        data: {
          text: '',
          buttons: []
        }
      }
    },
    notifications: [],
    landingPageAnchorItems: [
      {name: 'section_features', nodeSelector: '#section-features'},
      {name: 'section_workflow', nodeSelector: '#section-workflow'},
      {name: 'section_opportunities', nodeSelector: '#section-opportunities'},
      {name: 'section_why_us', nodeSelector: '#section-why-us'},
      {name: 'section_testimonials', nodeSelector: '#section-testimonials'},
    ],
    storageData: {
      settings: {
        localization: {
          selectedLanguage: {
            name: 'Русский',
            locale: 'ru'
          },
          availableLanguages: [
            {
              name: 'English',
              locale: 'en'
            },
            {
              name: 'Русский',
              locale: 'ru'
            }
          ]
        }
      }
    },
    navItems: [
      { 
        to: '/', 
        name: 'about_us', 
        text: 'about_us'
      },
      { 
        to: '/projects', 
        name: 'projects', 
        text: 'projects',
        link: 'https://t.me/telegram'
      },
      // { 
      //   to: '/jobs', 
      //   name: 'jobs', 
      //   text: 'Jobs'
      // },
      // { 
      //   to: '/careers', 
      //   name: 'careers', 
      //   text: 'Careers'
      // },
      { 
        to: '/faq', 
        name: 'faq', 
        text: 'FAQ'
      },
    ],
    navProfileItems: [
      { 
        to: '/projects', 
        name: 'projects', 
        text: 'Projects'
      },
      { 
        to: '/talents', 
        name: 'talents', 
        text: 'Talents'
      },
      { 
        to: '/faq', 
        name: 'faq', 
        text: 'FAQ'
      },
    ],
    englishLevels: [
      'A1 – Beginner',
      'A2 – Beginner',
      'B1 – Intermediate',
      'B2 – Intermediate',
      'C1 – Advanced',
      'C2 – Advanced',
    ],
    specialistGrades: [
      'Junior',
      'Middle',
      'Middle+',
      'Senior',
    ],
    customers: [
      {
        name: 'АДВ'
      },
      {
        name: 'СВОИ'
      },
      {
        name: 'ЭКСТИЛ'
      },
      {
        name: 'ИПОЛ'
      },
    ],
    vacancy: {
      type: 'new',
      data: {
        formIsValid: false,
        rolesSelected: '',
        roles: ['Frontend developer', 'Backend developer', 'Designer'],
        specialistGradesSelected: [],
        englishLevelSelected: '',
        countrySelected: [],
        customerSelected: null,
        minimumRequirements: '',
        skills: '',
        hourlyRate: '',
        aboutProject: '',
        specialistAmount: '',
        jobType: 'Full-time',
      }
    },
    admin: {
      vacancies: [
        {
          id: '1',
          name: 'Mobile developer',
          role: 'Vue',
          date: '01.01.21',
          customer: 'АДВ', 
          status: {
            value: 'archived',
            text: 'Archived',
          },
          cv: '/link'
        },
        {
          id: '2',
          name: 'Web frontend',
          role: 'Laravel',
          date: '01.01.21',
          customer: 'СВОИ', 
          status: {
            value: 'available',
            text: 'Available',
          },
          cv: '/link'
        },
        {
          id: '3',
          name: 'Web frontend',
          role: 'Java, Kotlin',
          date: '01.01.21',
          customer: 'ЭКСТИЛ', 
          status: {
            value: 'onProject',
            text: 'On a project',
          },
          cv: '/link'
        },
        {
          id: '4',
          name: 'Desktop frontend',
          role: 'Vue',
          date: '01.01.21',
          customer: 'ИПОЛ', 
          status: {
            value: 'onProject',
            text: 'On a project',
          },
          cv: '/link'
        },
        {
          id: '5',
          name: 'Mobile developer',
          role: 'AWS, Kubernetes',
          date: '01.01.21',
          customer: 'АДВ', 
          status: {
            value: 'archived',
            text: 'Archived',
          },
          cv: '/link'
        },
        {
          id: '6',
          name: 'Web frontend',
          role: 'Laravel',
          date: '01.01.21',
          customer: 'СВОИ', 
          status: {
            value: 'available',
            text: 'Available',
          },
          cv: '/link'
        },
        {
          id: '7',
          name: 'Web frontend',
          role: 'AWS, Kubernetes',
          date: '01.01.21',
          customer: 'ЭКСТИЛ', 
          status: {
            value: 'available',
            text: 'Available',
          },
          cv: '/link'
        },
        {
          id: '8',
          name: 'Desktop frontend',
          role: 'Vue',
          date: '01.01.21',
          customer: 'ИПОЛ', 
          status: {
            value: 'onProject',
            text: 'On a project',
          },
          cv: '/link'
        },
      ]
    },
    profile: {
      specialists: [
        {
          id: '1',
          name: 'Ivan Ivanov',
          role: 'Mobile developer',
          format: 'Свой',
          grade: 'Upper Junior', 
          status: {
            value: 'archived',
            text: 'Archived',
          },
          cv: '/link'
        },
        {
          id: '2',
          name: 'Ivan Ivanov 2',
          role: 'Web frontend',
          format: 'Чужой',
          grade: 'Senior', 
          status: {
            value: 'available',
            text: 'Available',
          },
          cv: '/link'
        },
        {
          id: '3',
          name: 'Ivan Ivanov 3',
          role: 'Web frontend',
          format: 'Чужой',
          grade: 'Senior', 
          status: {
            value: 'onProject',
            text: 'On a project',
          },
          cv: '/link'
        },
        {
          id: '4',
          name: 'Ivan Ivanov 4',
          role: 'Desktop frontend',
          format: 'Чужой',
          grade: 'Middle', 
          status: {
            value: 'onProject',
            text: 'On a project',
          },
          cv: '/link'
        },
        {
          id: '5',
          name: 'Ivan Ivanov 5',
          role: 'Mobile developer',
          format: 'Свой',
          grade: 'Upper Junior', 
          status: {
            value: 'archived',
            text: 'Archived',
          },
          cv: '/link'
        },
        {
          id: '6',
          name: 'Ivan Ivanov 6',
          role: 'Web frontend',
          format: 'Чужой',
          grade: 'Middle Junior', 
          status: {
            value: 'available',
            text: 'Available',
          },
          cv: '/link'
        },
        {
          id: '7',
          name: 'Ivan Ivanov 7',
          role: 'Web frontend',
          format: 'Чужой',
          grade: 'Senior', 
          status: {
            value: 'available',
            text: 'Available',
          },
          cv: '/link'
        },
        {
          id: '8',
          name: 'Ivan Ivanov 8',
          role: 'Desktop frontend',
          format: 'Чужой',
          grade: 'Middle Senior', 
          status: {
            value: 'onProject',
            text: 'On a project',
          },
          cv: '/link'
        },
      ]
    },
    views: {
      admin: {
        sideMenu: {
          activeItem: {
            name: 'my_specialists', 
            text: 'my specialists'
          },
          topMenuItems: [
            {
              name: 'my_specialists', 
              text: 'my specialists'
            },
            {
              name: 'my_projects', 
              text: 'my projects'
            },
            {
              name: 'interview', 
              text: 'interview'
            },
            {
              name: 'responses', 
              text: 'responses'
            },
          ],
          bottomMenuItems: [
            {
              name: 'applications', 
              text: 'applications'
            },
            {
              name: 'acts', 
              text: 'acts'
            },
            {
              name: 'reports', 
              text: 'reports'
            },
            {
              name: 'invoices', 
              text: 'invoices'
            },
          ],
        },
      },
      profile: {
        sideMenu: {
          activeItem: {
            name: 'my_specialists', 
            text: 'my specialists'
          },
          topMenuItems: [
            {
              name: 'my_specialists', 
              text: 'my specialists'
            },
            {
              name: 'my_projects', 
              text: 'my projects'
            },
            {
              name: 'interview', 
              text: 'interview'
            },
            {
              name: 'responses', 
              text: 'responses'
            },
          ],
          bottomMenuItems: [
            {
              name: 'applications', 
              text: 'applications'
            },
            {
              name: 'acts', 
              text: 'acts'
            },
            {
              name: 'reports', 
              text: 'reports'
            },
            {
              name: 'invoices', 
              text: 'invoices'
            },
          ],
        },
      },
    },
    testimonials: [
      {
        text: 'На данный момент я работаю в своем первом проекте с Humanicl - это B2B маркетплейс для IT сферы. Проект большой - я на нем уже 3 месяца и он продлиться еще несколько. Задачи разные и интересные - я создаю дизайн пользовательской части - интерфейсы личных кабинетов и страниц сайта. Мне все нравиться). Спасибо за эту возможность! ',
        name: 'Юлия Бродская - https://www.behance.net/ylb',
        jobPosition: 'UX/UI Designer',
        img: 'julia_brodskaya.jpg'
      },
      {
        text: 'Отзыв меня попросили написать, так как у многих разработчиков возникают вопросы относительно методики расчетов. Меня тоже по началу слегка напрягло, но я взвесил риски и решил попробовать, ну и в итоге все оказалось в порядке. Я не люблю работать на долгих проектах, поэтому участвую в тех, которые не больше 3х месяцев. За все время работы, я прям реально круто прокачал свои скиллы.',
        name: 'Дмитрий Баулов (Данные изменены)',
        jobPosition: 'Fullstack Developer (ссылка на страницу не указана по просьбе Исполнителя для отсечки запросов от рекрутеров)',
        img: 'dmitriy_baulov.jpg'
      },
      {
        text: 'Я противник full-time занятости на каком-либо проекте, так как в параллель я работаю над своими и поэтому humanicl предложили мне сотрудничество в формате аутсорс - когда я работаю над небольшими задачами не более 40 часов в месяце. Это конечно сильно режет мои возможности по доходу, но зато дает мне возможность заниматься своими проектами и при этом получать приемлемый доход. Огромный плюс в том, что администрация супероперативно реагирует на мои запросы. По выплатам претензий вообще никаких.',
        name: 'Алексей Харламов (Данные изменены)',
        jobPosition: 'Frontend Developer (ссылка на страницу не указана по просьбе Исполнителя для отсечки запросов от рекрутеров)',
        img: 'alexey_harlamov.jpg'
      }
    ],
    faqItemsForCustomers: {
      ru: [
        {
          title: '1) Как быстро специалист выйдет на наш проект?',
          description: 'Как только контракт будет одобрен обеими сторонами и вы выберете разработчика из предложенных нами. Типичный срок поиска составляет от нескольких дней до 3 недель в зависимости от требуемых навыков и знаний разработчика.'
        },
        {
          title: '2) Каким уровнем знаний обладают ваши разработчики?',
          description: 'У нас и наших партнеров работают специалисты уровня middle и выше, а также имеющие стаж в коммерческих проектах не менее 3х лет.'
        },
        {
          title: '3) Какой минимальный срок работы специалиста на проекте и какие ставки предусмотрены за час работы?',
          description: 'Наша модель сотрудничества - “time & material” с минимальным сроком работы специалиста на проекте - 40 часов. Ставка в час составляет от 1500 до 3500 рублей РФ, в зависимости от требуемой технологии и опыта разработчика.'
        },
        {
          title: '4) Какие финансовые риски мы несем при работе с вашими специалистами?',
          description: 'Риски сведены к минимуму, так как специалисты работают в ваших проектах и под вашим руководством. Расчеты осуществляются постоплатой не ранее чем через две недели после старта работ.'
        },
        {
          title: '5) Что делать, если разработчик по какой-то причине не подходит нам после того, как он(она) начнет работать на проекте?',
          description: 'Мы постараемся подобрать вам альтернативу в кратчайшие сроки и без дополнительных затрат'
        },
        {
          title: '6) А вы пристроите нам на проект фрилансера?',
          description: 'С нами работают только специалисты, трудоустроенные у наших партнеров или заключившие прямой контракт с нами'
        },
        {
          title: '7) Как вы улаживаете разницу в часовых поясах при работе с различными клиентами?',
          description: 'На каждый проект выходят только те разработчики, которые могут работать в конкретном часовом поясе. Мы имеем широкую сеть партнеров в самых разнообразных локациях'
        },
        {
          title: '8) Какие методы оплаты вы принимаете?',
          description: 'Банковский перевод, Pay Pal. При необходимости мы можем подобрать любой законный альтернативный метод, который подойдет и вам и нам.'
        },
        {
          title: '9) В чем преимущества при работе с вашими специалистами?',
          description: 'Все специалисты проверены нами или нашими партнерами, стоимость часа работы не завышена, сначала работа - потом оплата'
        },
      ],
      en: [
        {
          title: '1) How soon do I receive a developer?',
          description: 'Once the contract is approved by both sides and you choose a developer from offered by us. A typical search time frame is between several days and 3 weeks depending on the developer skills and expertise required.'
        },
        {
          title: '2) What level of expertise do your developers possess?',
          description: 'Our developers range from middle to leader and each of them has three or more years of commercial development experience.'
        },
        {
          title: '3) Is there a minimum hire period and what is the pricing?',
          description: 'Our cooperation model is “time and material” with the minimum development hire period of 3 months for one developer. Pricing is done on the rate per hour basis which is $30 – $75 per hour, depending on the required technology and the developer’s expertise.'
        },
        {
          title: '4) What risks are involved?',
          description: 'You have no risk because our developers work in your project under your management. And you pay after the first two working weeks, not before.'
        },
        {
          title: '5) What can I do if the developer for some reason does not suit me after he(she) starts working in the project?',
          description: 'We guarantee a replacement candidate for no extra cost.'
        },
        {
          title: '6) Will you stick me with a freelancer?',
          description: 'Absolutely not. Humanicl is focused on the rapid provision of professional developers who are experts in one or more IT field. All of our developers are employees of Humanicl or employees of our Partners and have been put through a very strict selection process.'
        },
        {
          title: '7) How do your developers overcome time zone differences?',
          description: 'Our developers keep all communications in your time zone and use a shared calendar to maintain a schedule for each project.'
        },
        {
          title: '8) What payment methods do you accept?',
          description: 'For your convenience, we offer 2 main payment options: Regular wire transfer, PayPal or we can consider any alternative methods'
        },
        {
          title: '9) What benefits can I get by choosing outstaffing developer from you?',
          description: 'When choosing outstaffing, you optimize development cost by reducing personnel expenses and overheads. Plus the selection of personnel is mainly carried out on the side of the outstaffing company, so you only pick the best fitted candidate. Also, you do not need to think about the accompanying processes (workspace, arrangement of the workplace, other benefits for employees such as insurance, lunch, training, corporate events).'
        },
      ],
    },
    faqItemsForContractors: {
      ru: [
        {
          title: '1) Какие компании могут работать с Humanicl?',
          description: 'Мы работаем с компаниями, которые имеют юридическую регистрацию.'
        },
        {
          title: '2) Какие разработчики могут работать над проектами Humanicl?',
          description: 'Специалисты уровня middle и выше, а также имеющие стаж в коммерческих проектах не менее 3х лет. Если проект предусматривает общение на английском языке, то необходим уровень B2 или выше.'
        },
        {
          title: '3) Какие риски мы несем, если Заказчики не оплачивают работы по необъективным причинам?',
          description: 'Если специалисты качественно выполнили свою работу, то вне зависимости от поведения Заказчика - Вы получите свою оплату.'
        },
        {
          title: '4) Когда осуществляются выплаты?',
          description: 'Ежемесячно до 20го числа месяца следующего за отчетным, после подписания закрывающих документов.'
        },
        {
          title: '5) Это биржа фриланс-заказов?',
          description: 'Нет, наши клиенты - это представители среднего и крупного бизнеса, заинтересованные в долгосрочном сотрудничестве, Каждый из которых обладает легальной регистрацией и хорошей репутацией.'
        },
        {
          title: '6) Как правильно организовать выход специалиста на проект?',
          description: 'Органнизацией процессов и комуникациями с Заказчиком занимается менеджер Humanicl.'
        },
        {
          title: '7) У специалиста произошел инцидент, который помешал ему выйти на работу. Что делать?',
          description: '"Вам нужно немедленно написать об этом своему менеджеру в Humanicl'
        },
        {
          title: '8) Какие этапы должен пройти специалист, чтобы его взяли на проект?',
          description: '1. Предварительное собеседование для понимания уровня английского языка 2. Техническое собеседование с представителем клиента 3. В некоторых случаях специалистам предлагается выполнить тестовое задание.'
        },
        {
          title: '9) Можем ли мы отказаться от работы в проекте?',
          description: 'Да, но только до того, как специалист начнет работать над проектом'
        },
      ],
      en: [
        {
          title: '1) What kind of companies can work with Humanicl?',
          description: 'We work with companies which has a legal registration.'
        },
        {
          title: '2) What kind of developers can work on Humanicl projects?',
          description: 'Developers with mid-level or higher, and commercial development experience of three years or more. Also English must be B2-level or higher.'
        },
        {
          title: '3) What risks do we have if the Customers do not pay to Humanicl for biased reasons?',
          description: 'You have no risks, because if your Developers had done their job successfully we will pay you from our special insurance fund.'
        },
        {
          title: '4) When will we receive payment for the time worked?',
          description: 'Payments are made monthly until the 20th day of the month following the reporting one. Based on the closing documents.'
        },
        {
          title: '5) Is this a freelance platform?',
          description: 'Absolutely not. We work only with legal entities and individual entrepreneurs and carefully check our clients. Clients are most often large and medium-sized companies that actively attract specialists in the format of an outstaff model to solve IT problems.'
        },
        {
          title: '6) How to build the first day of a specialists work with a client?',
          description: 'The organization of onboarding and transferring of access data is under control of manager of Humanicl. You also need to transfer all the data received from the Humanicl to your specialist and get confirmation that he has read the information and will start work on the project on the specified day.'
        },
        {
          title: '7) The specialist had an incident that prevented him from going to work. What to do?',
          description: 'You need to immediately write about this to your manager at Humanicl.'
        },
        {
          title: '8) What stages should a specialist go through in order to reach a client on a project?',
          description: '1. Preliminary interview to understand the level of English 2. Technical interview with a representative of the client 3. In some cases, specialists are asked to perform a test task.'
        },
         {
          title: '9) Can we refuse to work until the specialist comes to the project?',
          description: 'Yes, but only before the specialist starts working on the project.'
        },
        
      ],
    }
  },
  getters: {
    getField,
    unreadNotifications: (state) => {
      return state.notifications.filter(item => !item.isRead)
    },
    navItemsLocalized: (state) => {
      return state.navItems.map(item => {
        item.text = localize.get(item.name)
        return item
      })
    },
    navProfileItemsLocalized: (state) => {
      return state.navProfileItems.map(item => {
        item.text = localize.get(item.name)
        return item
      })
    },
    profileTopSideMenuItemsLocalized: (state) => {
      return state.views.profile.sideMenu.topMenuItems.map(item => {
        item.text = localize.get(item.name)
        return item
      })
    },
    profileBottomSideMenuItemsLocalized: (state) => {
      return state.views.profile.sideMenu.bottomMenuItems.map(item => {
        item.text = localize.get(item.name)
        return item
      })
    },
    adminTopSideMenuItemsLocalized: (state) => {
      return state.views.admin.sideMenu.topMenuItems.map(item => {
        item.text = localize.get(item.name)
        return item
      })
    },
    adminBottomSideMenuItemsLocalized: (state) => {
      return state.views.admin.sideMenu.bottomMenuItems.map(item => {
        item.text = localize.get(item.name)
        return item
      })
    },
  },
  mutations: {
    updateField,
  },
  actions: {
    AUTO_SIGN_IN (store, payload) {
      axios.defaults.headers.common['Auth'] = payload
      store.state.user.loggedIn = true
    },
    SIGN_IN (store) {
      axios.post(store.state.backendAuthURL, {
        login: 'Moloch',
        password: 'q1w2e3r4'
      })
        .then(response => {
          axios.defaults.headers.common['Auth'] = response.data.message.jwt
          localStorage.setItem('user-token', response.data.message.jwt)
          store.state.user.loggedIn = true
        })
        .catch(error => {
          console.log(error)
        })
    },
    SIGN_UP (store) {
      axios.post('http://humacl.x-or.ru/v1/register', {
        login: 'Moloch',
        password: 'q1w2e3r4'
      })
        .then(response => {
          if (response.status === 'ok') {
            // 1. Проверка почты
            alert('Проверь почту')
            // router.push('/sign-in').catch(error => {})
          }
          else {
            return
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    SIGN_OUT (store) {
      axios.defaults.headers.common['Auth'] = null
      localStorage.removeItem('user-token')
      store.state.user.loggedIn = false
    },
    OPEN_LOGIN_PAGE (store) {
      // TODO: use this line when user profile is ready
      // router.push('sign-in').catch(error => {})
      document.querySelector('.bitrix-form-1').click()
    },
    DOWNLOAD_CV (store, params) {
      console.log(params)
    },
    DUPLICATE_VACANCY (store, params) {
      console.log(params)
    },
    DELETE_TABLE_ITEM (store, params) {
      console.log(params)
    },
    CANCEL_EDIT_VACANCY (store, params) {
      store.state.dialogs.prompt.value = true
      store.state.dialogs.prompt.data = {
        title: localize.get('vacancy_editor_prompt_title'),
        text: localize.get('changes_will_be_lost'),
        buttons: [
          {
            text: localize.get('exit'),
            onClick: () => {
              store.state.dialogs.prompt.value = false
              router.push('/profile').catch(error => {})
            }
          },
          {
            text: localize.get('continue'),
            onClick: () => {
              store.state.dialogs.prompt.value = false
            }
          }
        ]
      }
    },
    EDIT_VACANCY (store, params) {
      console.log(params)
    },
    ADD_VACANCY (store, params) {
      console.log(params)
    },
    OPEN_VACANCY_EDITOR (store, params) {
      console.log(params)
      if (params.type === 'new') {
        store.state.vacancy.data = {
          ...store.state.vacancy.data,
          title: localize.get('new_vacancy'),
        }
      }
      else if (params.type === 'edit') {
        store.state.vacancy.data = {
          ...store.state.vacancy.data,
          title: localize.get('new_vacancy'),
        }
      }
      router.push('/vacancy').catch(error => {})
    }
  }
})
