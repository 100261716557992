const description = {
  name: 'Русский',
  locale: 'ru'
}

const data = {
  // Main toolbar
  main: 'главная',
  home: 'главная',
  jobs: 'проекты',
  careers: 'карьера',
  hire: 'нанять',
  partnership: 'партнерство',
  faq: 'FAQ',
  projects: 'проекты',
  talents: 'таланты',
  login: 'войти',
  logout: 'выйти',
  date: 'дата',
  publish: 'опубликовать',
  cancel: 'отменить',
  exit: 'выйти',
  continue: 'продолжить',
  changes_will_be_lost: 'изменения будут потеряны',
  filters: 'фильтры',
  search: 'поиск',
  back: 'назад',
  apply: 'применить',
  support: 'поддержка',
  customer: 'заказчик',
  sort_by: 'сортировать по',
  sorting_order: 'сортировка',
  asc: 'по возрастанию',
  desc: 'по убыванию',
  chat: 'чат',
  notifications: 'уведомления',
  delete: 'удалить',
  download: 'скачать',
  vacancies_jobs: 'вакансий',
  selected_filters: 'выбранных фильтров',
  read_more: 'узнать больше',
  show: 'показать',
  show_on_page: 'отображать на странице',
  reset: 'сбросить',
  other: 'других',
  of: 'из',
  previous_short: 'пред',
  next_short: 'след',
  countries: 'страны',
  sectors: 'секторы',
  duration: 'продолжительность',
  payment_method: 'способ оплаты',
  location: 'местоположение',
  status: 'статус',
  available_for: 'доступный для',
  category: 'категория',
  work_area: 'область работы',
  role: 'роль',
  level: 'уровень',
  hard_skills: 'навыки',
  project_description: 'описание проекта',
  role_description: 'описание обязанностей',
  role_responsibilities: 'обязанности',
  preferred_skills: 'предпочтительные навыки',
  minimum_requirements_and_work_experience: 'минимальные требования и опыт работы',
    // Validation
  field_is_required: 'обязательное поле',
  some_form_fields_are_invalid: 'некоторые поля некорректны',
  // Sections
  section_features: 'Особенности',
  section_workflow: 'Как это работает',
  section_opportunities: 'Возможности',
  section_why_us: 'Почему мы',
  section_testimonials: 'Отзывы',
  // Main footer
  text_open_world_remotely: 'Для регистрации -<br>нажмите кнопку ниже',
  company_address: 'Россия,<br>Москва,<br>Молодогвардейская, 41-10',
  phone_number: '+7 499 322 98 09',
  // Home page
  home_page_main_title: '<span class="text--highlight" text>B2B-платформа</span> для быстрого найма проверенных ИТ-специалистов со всего мира',
  home_page_main_subtitle: 'Мы соединяем специалистов IT-компаний с крупными компниями и стартапами, которым нужны их услуги',
  home_page_slogan: `
    <div>
      <span class="text--highlight" text blue-1>Мир меняется</span> 
      каждую секунду...
    </div>
    <div>
      Пришло время быть по-настоящему  
      <span class="text--highlight" text blue>гибким</span> 
      и 
      <span class="text--highlight" text blue>быстрым.</span>
    </div>
    <div>
      <span class="text--highlight" text green>Присоединяйтесь к HUMANICL,</span> 
      чтобы вместе строить ваше 
      <span class="text--highlight" text green>новое будущее.</span>
    </div>
  `,
  join_platform: 'Присоединиться',
  learn_more: 'Подробнее',
  for_customers: 'для Заказчиков',
  for_it_agencies: 'для Исполнителей',
  for_contractors: 'для Подрядчиков',
  // Careers page
  careers_page_main_title: '!!Зарабатывайте с нашей платформой<span class="text--highlight" text> до 2х раз больше средней зарплаты по рынку</span> и самостоятельно выбирайте проекты',
  careers_page_main_subtitle: 'Долгосрочные проекты в средних и крупных компаниях для IT-специалистов',
  // Section: equal opportunities
  equal_opportunities: 'Равные возможности',
  equal_opportunities_description: 'Мы предоставляем равные возможности и выступаем против дискриминации и притеснений, независимо от расы, цвета кожи, религии, пола, сексуальной ориентации, гендерной идентичности или самовыражения, возраста, статуса инвалидности, статуса ветерана или любой другой характеристики, охраняемой законом.',
  equal_opportunities_1: 'Компании со всего мира',
  equal_opportunities_2: 'Удаленная работа',
  equal_opportunities_3: 'Быстрый профессиональный рост',
  equal_opportunities_4: 'Зарплата выше средней',
  apply_for_job: 'Присоединиться',
  apply_for_this_job: 'откликнуться',
  // Section: Why we
  why_work_with_us: 'Почему мы?',
  why_work_with_us_1: 'Humanicl - это международная платформа.',
  why_work_with_us_2: 'Мы гарантируем равные возможности нашим партнерам.',
  why_work_with_us_3: 'Мы юридически легитимны.',
  why_work_with_us_4: 'Вам нужен только один контракт для работы со всеми нашими партнерами.',
  // Section: Testimonials
  testimonials: 'Отзывы',
  // Features
  features: 'Особенности',
  single_contact: 'Единый контракт с платформой для работы со всеми клиентами.',
  automated_interview: 'Автоматизированная система управления интервью',
  automated_system: 'Автоматизированная система проверки отработанных часов',
  automatic_generation: 'Автоматическая генерация рабочих документов',
  built_in_messaging: 'Встроенная система обмена сообщениями между заказчиком и специалистом',
  depersonalized_cv: 'Де-персонализированные резюме специалистов с вашим логотипом доступны для скачивания.',
  // Advantages
  advantages: 'Преимущества',
  top_skills: 'Топ скиллы',
  top_skills_subtitle: 'Специалисты с наиболее востребованными навыками',
  no_risk: 'Без рисков',
  no_risk_subtitle: 'Оплата не ранее чем через две недели после старта',
  no_risk_subtitle_2: 'Гарантия оплаты за работу',
  engagement: 'Вовлеченность',
  engagement_subtitle: 'Эксперты работают в проектах, которые им реально нравятся',
  engagement_subtitle_2: 'Эксперты работают в проектах, которые им реально нравятся',
  cost_management: 'Лучшая цена',
  cost_management_subtitle: 'Адекватные ставки соответствующие уровню специалистов',
  extra_income: 'Увеличивайте доходы',
  extra_income_subtitle: 'Больше никакого бенча - всем найдется работа',
  international_market: 'Международный рынок',
  international_market_subtitle: 'Получите доступ к заказчиками со всего мира',
  // Workflow
  workflow: 'Как это работает',
  work_cycle: 'Рабочий цикл',
  project: 'Выбор проекта',
  post_your_project: 'Разместите проект',
  choose_your_project: 'Выбери проект',
  choose_your_project_subtitle: 'Выбор проекта, который соответствует вашим навыкам',
  project_interview: 'Интервью по проекту',
  project_interview_subtitle: 'Общение с клиентом, чтобы понять, что вы можете справиться с работой',
  post_your_project_subtitle: 'Мы подготовим вам резюме специалистов, заинтересованных в вашем проекте',
  post_your_project_subtitle_2: 'Выберите проекты соответствующие навыкам ваших сотрудников',
  interview: 'Интервью',
  interview_subtitle: 'Запланируйте собеседования с кандидатами, которые откликнулись',
  interview_subtitle_2: 'Согласуйте с заказчиком время и даты интервью для специалистов',
  completion_of_the_project: 'Завершение проекта',
  completion_of_the_project_subtitle: 'После завершения проекта - разместите следующий проект',
  completion_of_the_project_subtitle_2: 'После завершения проекта - выберите следующий проект',
  completion_of_the_project_subtitle_3: 'После завершения проекта - выберите следующий проект',
  work_on_project: 'Работа на проекте',
  work_on_project_subtitle: 'Специалист работает под вашим руководством',
  work_on_project_subtitle_2: 'Ваш специалист работает под руководством заказчика',
  work_on_project_subtitle_3: 'Вы работаете удаленно под руководством заказчика',
  careers_at_humanicl: 'Карьера в humanicl',
  privacy_policy: 'Политика обработки ПД',
  terms_of_use: 'Пользовательское соглашение',
  privacy_policy_content: `
    <h2>ПОЛИТИКА ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
    <h3>Общие положения</h3>
    <p>
      Политика обработки персональных данных (далее – «Политика») разработана в соответствии с
      Федеральным законом от 27.07.2006 г. №152-ФЗ «О персональных данных» (далее – «ФЗ-152»).
      Настоящая Политика определяет порядок обработки персональных данных и меры по обеспечению
      безопасности персональных данных на сайте <a href="https://humanicl.com/" target="_blank">https://humanicl.com/</a> 
      (далее – «Оператор») с целью защиты прав и свобод человека и гражданина при обработке его персональных данных, в том числе
      защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
    </p>
    <p>
      В Политике используются следующие основные понятия и термины:
      субъект персональных данных – физическое лицо, направляющее собственные персональные
      данные Оператору в целях получения доступа к Сервису Оператора;
      персональные данные – любая информация, прямо или косвенно относящаяся к определенному
      или определяемому физическому лицу (Субъекту персональных данных);
      обработка персональных данных – любое действие (операция) или совокупность действий
      (операций), совершаемых с использованием средств автоматизации или без использования таких
      средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение,
      уточнение (обновление, изменение), извлечение, использование, передачу (распространение,
      предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных
      данных;
      автоматизированная обработка персональных данных – обработка персональных данных с
      помощью средств вычислительной техники;
      блокирование персональных данных – временное прекращение обработки персональных
      данных (за исключением случаев, если обработка необходима для уточнения персональных данных);
      информационная система персональных данных – совокупность содержащихся в базах данных
      персональных данных и обеспечивающих их обработку информационных технологий и технических
      средств;
      обезличивание персональных данных – действия, в результате которых невозможно определить
      без использования дополнительной информации принадлежность персональных данных конкретному
      субъекту персональных данных;
      предоставление персональных данных – действия, направленные на раскрытие персональных
      данных определенному лицу или определенному кругу лиц;
      распространение персональных данных – действия, направленные на раскрытие персональных
      данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с
      персональными данными неограниченного круга лиц, в том числе обнародование персональных
      данных в средствах массовой информации, размещение в информационно-телекоммуникационных
      сетях или предоставление доступа к персональным данным каким-либо иным способом;
      трансграничная передача персональных данных – передача персональных данных на
      территорию иностранного государства, органу власти иностранного государства, иностранному
      физическому или иностранному юридическому лицу;
      уничтожение персональных данных – действия, в результате которых невозможно восстановить
      содержание персональных данных в информационной системе персональных данных, и/или в
      результате которых уничтожаются материальные носители персональных данных.
      сервис Оператора – программный комплекс, являющийся собственностью Оператора,
      включающий в себя WEB – интерфейс, размещенный в сети Интернет по адресу 
      <a href="https://humanicl.com/" target="_blank">https://humanicl.com/</a>
      пользовательское соглашение – соглашение, размещенное на сайте Оператора по адресу
      <a href="https://humanicl.com/terms-of-use/" target="_blank">https://humanicl.com/terms-of-use/</a> 
      заключаемое между Оператором и Субъектом,
      регламентирующее отношения между Оператором и Субъектом по использованию интернет-ресурса
      <a href="https://humanicl.com/" target="_blank">https://humanicl.com/</a>, 
      направленное на обеспечение доступа субъекта к персонифицированным
      инструментам Сервиса.
    </p>
    <p>
      Оператор обязан опубликовать или иным образом обеспечить неограниченный доступ всех
      заинтересованных лиц к настоящей Политике обработки персональных данных в соответствии с ч. 2
      ст. 18.1. ФЗ-152.
    </p>
    <h3>1. Принципы обработки humanicl.com персональных данных</h3>
    <p>
      1.1 humanicl.com осуществляет обработку персональных данных на основе следующих принципов:
    </p>
    <li>законности и справедливой основы;</li>
    <li>ограничения обработки персональных данных достижением конкретных, заранее определенных</li>
    и законных целей;
    <li>недопущения обработки персональных данных, несовместимой с целями сбора персональных</li>
    данных;
    <li>недопущения объединения баз данных, содержащих персональные данные, обработка которых</li>
    осуществляется в целях, несовместимых между собой;
    <li>обработки только тех персональных данных, которые отвечают целям их обработки;</li>
    <li>соответствия содержания и объема обрабатываемых персональных данных заявленным целям</li>
    обработки;
    <li>недопущения обработки персональных данных, избыточных по отношению к заявленным целям</li>
    их обработки;
    <li>обеспечения точности, достаточности и актуальности персональных данных по отношению к</li>
    целям обработки персональных данных;
    <li>уничтожения либо обезличивания персональных данных по достижении целей их обработки или</li>
    в случае утраты необходимости в достижении этих целей, при невозможности устранения
    Оператором допущенных нарушений персональных данных, если иное не предусмотрено
    федеральным законом.
    <h3>2. Состав персональных данных</h3>
    <p>
      2.1 Персональные данные, разрешенные к обработке в рамках настоящей Политики,
      предоставляются Субъектом персональных данных (получаются Оператором в автоматизированном
      режиме) путем заполнения регистрационных и иных форм на сайте Оператора, направления
      субъектом в адрес humanicl.com информации и документов, содержащий персональные данные
      Субъекта.
    </p>
    <p>
      2.2 В соответствии с настоящей Политикой Оператор гарантирует защиту персональных
      данных, направляемых Субъектом на электронный адрес info@humanicl.com, принадлежащий
      humanicl.com.
    </p>
    <p>
      2.3 Персональные данные, предоставляемые Субъектом и/или получаемые Оператором в
      автоматизированном режиме, включают в себя:
    </p>
    <li>имя пользователя (логин);</li>
    <li>адрес электронной почты;</li>
    <li>номер телефона и других мессенджеров;</li>
    <li>данные об образовании и опыте работы</li>
    <h3>3. Цели и основания обработки персональных данных</h3>
    <p>
      3.1. Целью обработки Оператором персональных данных является предоставление Субъекту
      доступа к функционалу Сервиса Оператора посредством создания уникальной учетной записи
      субъекта для обеспечения исполнения обязательств Сторон согласно Пользовательского соглашения,
      обеспечения исполнения обязательств из иных договорных отношений, устанавливаемых с
      использованием функционала Сервиса Оператора, защиты прав и охраняемых законом интересов
      Пользователя и Оператора, в том числе предотвращения фактов мошенничества, улучшения качества
      работы Сервиса Оператора, установления Оператором обратной связи с Субъектом персональных
      данных, включая, но не ограничиваясь осуществлением рассылки материалов и контента сайта,
      осуществления информационной/рекламной/новостной рассылки.
    </p>
    <p>
      3.2. Правовыми основаниями обработки персональных данных являются в том числе:
    </p>
    <li>Пользовательское соглашение, заключаемое между Оператором и Субъектом персональных данных;</li>
    <li>Согласие на обработку персональных данных, предоставляемое Субъектом персональных данных.</li>
    <h3>4. Условия обработки персональных данных</h3>
    <p>
      4.1. Оператор производит обработку персональных данных при наличии хотя бы одного из
      следующих условий:
      <li>
        обработка персональных данных осуществляется с согласия Субъекта персональных данных на
        обработку его персональных данных;
      </li>
      <li>
        обработка персональных данных необходима для достижения целей, предусмотренных
        международным договором Российской Федерации или законом, для осуществления и выполнения
        возложенных законодательством Российской Федерации на Оператора функций, полномочий и
        обязанностей;
      </li>
      <li>
        обработка персональных данных необходима для осуществления правосудия, исполнения
        судебного акта, акта другого органа или должностного лица, подлежащих исполнению в
        соответствии с законодательством Российской Федерации об исполнительном производстве;
      </li>
      <li>
        обработка персональных данных необходима для осуществления прав и обеспечения законных
        интересов Оператора или третьих лиц либо для достижения общественно значимых целей при
        условии, что при этом не нарушаются права и свободы Субъекта персональных данных;
      </li>
      <li>
        осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым
        предоставлен субъектом персональных данных либо по его просьбе (далее – «общедоступные
        персональные данные»);
      </li>
      <li>
        осуществляется обработка персональных данных, подлежащих опубликованию или
        обязательному раскрытию в соответствии с федеральным законом.
        Передача персональных данных третьим лицам может осуществляться только в случаях,
        установленных законодательством Российской Федерации, договором с участием Субъекта или с его
        согласия.
      </li>
    </p>
    <h3>5. Конфиденциальность персональных данных</h3>
    <p>   
      5.1. Обязанности Оператора по обеспечению конфиденциальности персональных данных являются
      первостепенными задачами при обработке последних. Оператор обязуется не раскрывать третьим
      лицам и не распространять персональные данные без согласия Субъекта персональных данных, если
      иное не предусмотрено федеральным законом.
    </p>
    <h3>6. Специальные категории персональных данных</h3>
    <p>  
      6.1. Оператором не осуществляется обработка персональных данных, касающихся расовой,
      национальной принадлежности, политических взглядов, религиозных или философских убеждений,
      состояния здоровья, интимной жизни Субъекта персональных данных.
    </p>
    <h3>7. Биометрические персональные данные</h3>
    <p>
      7.1. Сведения, которые характеризуют физиологические и биологические особенности человека,
      на основании которых можно установить его личность (биометрические персональные данные) не
      обрабатываются.
    </p>
    <h3>8. Поручение обработки персональных данных другому лицу</h3>
    <p>
      8.1. Оператор вправе поручить обработку персональных данных другому лицу с согласия
      Субъекта персональных данных, если иное не предусмотрено федеральным законом, на основании
      заключаемого с этим лицом договора. Лицо, осуществляющее обработку персональных данных по
      поручению Оператора, обязано соблюдать принципы и правила обработки персональных данных,
      предусмотренные ФЗ-152 и настоящей Политикой.
    </p>
    <h3>9. Трансграничная передача персональных данных</h3>
    <p> 
      9.1. Оператор осуществляет трансграничную передачу персональных данных Субъектов
      персональных данных.
    </p>
    <h3>10. Согласие Субъекта персональных данных на обработку его персональных данных</h3>
    <p>
      10.1. Субъект персональных данных принимает решение о предоставлении его персональных
      данных и дает согласие на их обработку свободно, своей волей и в своем интересе. Согласие на
      обработку персональных данных предоставляется Субъектом персональных данных или его
      представителем в письменной форме путем совершения действий в регистрационной форме
      Оператора (проставления соответствующего значка «check-box», «галочка»), сопутствующих
      регистрации субъекта в системе для пользования Сервисом Оператора. Обязанность предоставить
      доказательство получения согласия Субъекта персональных данных на обработку его персональных
      данных или доказательство наличия оснований, указанных в ФЗ-152, возлагается на Оператора.
    </p>
    <p>
      10.2. Оператор исходит из того, что Субъект предоставляет достоверные персональные данные и
      самостоятельно обеспечивает актуальность предоставленной информации.
    </p>
    <p>
      10.3. Оператор вправе производить проверку предоставленных персональных данных. При
      наличии неустранимых сомнений в достоверности и/или актуальности предоставленных
      персональных данных Оператор вправе отказать Субъекту в доступе к Сервису, включая
      персонифицированный функционал Сервиса Оператора.
    </p>
    <h3>11. Права субъекта персональных данных</h3>
    <p>
      11.1. Субъект персональных данных имеет право на получение у Оператора информации,
      касающейся обработки его персональных данных, если такое право не ограничено в соответствии с
      федеральными законами.
    </p>
    <p>
      11.2. Субъект персональных данных вправе требовать от Оператора уточнения его персональных
      данных, их блокирования или уничтожения в случаях, предусмотренных договорными отношениями
      с Оператором, а также в случаях, если персональные данные являются неполными, устаревшими,
      неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки,
      а также принимать предусмотренные законом меры по защите своих прав.
      Для реализации вышеуказанных прав субъект направляет на адрес электронной почты Оператора
      info@team.humanicl.com с адреса электронной почты, предоставленного Оператору как адрес субъекта,
      соответствующее Заявление, которое должно быть обработано Оператором в срок, не превышающий
      5 (пяти) рабочих дней с момента его получения.
    </p>
    <p>
      11.3. Обработка персональных данных в целях продвижения товаров, работ, услуг на рынке путем
      осуществления прямых контактов с потенциальным потребителем с помощью средств связи, а также
      в целях политической агитации допускается только при условии предварительного согласия субъекта
      персональных данных. Указанная обработка персональных данных признается осуществляемой без
      предварительного согласия субъекта персональных данных, если Оператор не докажет, что такое
      согласие было получено. Оператор обязан немедленно прекратить по требованию субъекта
      персональных данных обработку его персональных данных в вышеуказанных целях.
    </p> 
    <p>
      11.4. В соответствии с настоящей Политикой запрещается принятие на основании исключительно
      автоматизированной обработки персональных данных решений, порождающих юридические
      последствия в отношении субъекта персональных данных или иным образом затрагивающих его
      права и законные интересы, за исключением случаев, предусмотренных федеральными законами, или
      при наличии согласия в письменной форме субъекта персональных данных.
    </p>
    <p>
      11.5. Если Субъект персональных данных считает, что Оператор осуществляет обработку его
      персональных данных с нарушением требований ФЗ-152 или иным образом нарушает его права и
      свободы, последний вправе обжаловать действия или бездействие Оператора в Уполномоченный
      орган по защите прав субъектов персональных данных или в судебном порядке.
    </p>  
    <p>
      11.6. Субъект персональных данных имеет право на защиту своих прав и законных интересов, в
      том числе на возмещение убытков и/или компенсацию морального вреда в судебном порядке.
    </p>
    <p>
      11.7. Субъект вправе в любой момент отозвать свое согласие на обработку персональных данных,
      для чего таковым направляется с адреса электронной почты, предоставленного Оператору как адрес
      субъекта, письменное уведомление Оператору на электронный адрес info@humanicl.com. При
      этом субъект признает, что соответствующее уведомление будет означать его волеизъявление на
      прекращение каких-либо договорных отношений с Оператором по пользованию Сервисом Оператора
      с момента получения последним соответствующего уведомления. Риски доступа к собственной
      электронной почте третьих лиц и совершения ими юридически значимых действий от имени субъекта
      персональных данных последний несет самостоятельно.
    </p>
    <h3>12. Обеспечение безопасности персональных данных</h3>
    <p>
      12.1. Безопасность персональных данных, обрабатываемых Оператором, обеспечивается
      реализацией правовых, организационных и технических мер, необходимых для обеспечения
      требований федерального законодательства в области защиты персональных данных.
      Для предотвращения несанкционированного доступа к персональным данным Оператором
      применяются следующие организационно-технические меры:
      <ul>
        <li>назначение должностных лиц, ответственных за организацию обработки и защиты персональных</li>
        данных;
        <li>ограничение состава лиц, имеющих доступ к персональным данным;</li>
        <li>ознакомление Субъектов с требованиями федерального законодательства и нормативных</li>
        документов Оператора по обработке и защите персональных данных;
        <li>организация учета, хранения и обращения носителей информации;</li>
        <li>определение угроз безопасности персональных данных при их обработке, формирование на их</li>
        основе моделей угроз, в том числе при автоматизированной обработке данных;
        <li>разработка на основе модели угроз системы защиты персональных данных, в том числе при</li>
        автоматизированной обработке данных;
        <li>проверка готовности и эффективности использования средств защиты информации, в том числе</li>
        при автоматизированной обработке данных;
        <li>разграничение доступа пользователей к информационным ресурсам, персонализация и учет</li>
        доступа сотрудников Оператора к программно-аппаратным средствам обработки информации;
        <li>регистрация и учет действий пользователей информационных систем персональных данных;</li>
        <li>использование антивирусных средств и средств восстановления системы защиты персональных</li>
        данных;
        <li>применение в необходимых случаях средств межсетевого экранирования, обнаружения</li>
        вторжений, анализа защищенности и средств криптографической защиты информации;
        <li>организация пропускного режима на территорию Оператора, охрана помещений с техническими</li>
        средствами обработки персональных данных.
      </ul>
    </p>
    <p>
      12.2. Персонал Оператора, имеющий доступ к персональным данным субъектов, является
      прошедшим индивидуальный инструктаж по работе с персональными данными, ознакомленным с
      правовым регулированием обработки персональных данных, внутренними локальными актами
      Оператора и взявшим на себя обязательства по сохранению конфиденциальности указанной
      информации.
    </p>
    <p>
      12.3. Оператор не несет ответственности за сайты и программное обеспечение третьих лиц, на
      которое субъект может перейти по ссылкам, доступным при пользовании Сервисом Оператора.
    </p>
    <h3>13. Заключительные положения</h3>
    <p>
      13.1. Иные права и обязанности Оператора, как оператора персональных данных, определяются
      законодательством Российской Федерации в области персональных данных. Должностные лица
      Оператора, виновные в нарушении норм, регулирующих обработку и защиту персональных данных,
      несут материальную, дисциплинарную, административную, гражданско-правовую или уголовную
      ответственность в порядке, установленном действующим законодательством и локальными актами
      Оператора.
    </p>
    <p>
      13.2. Фактом принятия и согласия с настоящей Политикой является предоставление субъектом
      Согласия на обработку персональных данных на основании и в соответствии с настоящим
      документом.
    </p>
    <p>
      13.3. Политика Оператора в отношении обработки персональных данных вступает в силу с
      момента ее размещения на сайте Оператора по адресу
      <a href="https://humanicl.com/privacy-policy/" target="_blank">https://humanicl.com/privacy-policy/</a> 
      Политика может быть в любое время изменена
      Оператором в пределах, установленных действующим законодательством РФ. Субъект
      самостоятельно отслеживает изменения в Политике и в случае несогласия с таковыми обязан
      прекратить пользование Сервисом Оператора и направить в адрес Оператора уведомление о
      расторжении Соглашения об использовании интернет-сервиса. В случае если изменения в Политике
      предполагают подписание новой редакции Согласия субъекта на обработку персональных данных,
      Оператор оставляет за собой право запросить указанное Согласие. В случае несогласия и/или
      бездействия субъекта Оператор вправе отказать в использовании Сервиса/расторгнуть Соглашение об
      использовании интернет-сервиса.
    </p>
    <p>
      13.4. Контроль за исполнением требований настоящей Политики осуществляется лицом,
      ответственным за организацию обработки персональных данных в humanicl.com
    </p>
    <p>
      Humanicl LLC
      <br>TIN 4345511388
    </p>
  `,
  terms_of_use_content: `
    <h2>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h2>
    <p>
      Настоящее Соглашение регламентирует отношения между Пользователем с одной стороны и
      Humanicl LLC - правообладателем (далее – «Администратор») интернет - ресурса
      <a href="https://humanicl.com/" target="_blank">https://humanicl.com/</a> (далее – «Сайт») 
      с другой стороны по предоставлению Пользователю права
      доступа к персонифицированным сервисам Сайта
      Настоящее Соглашение является офертой Администратора на получение доступа к
      функциональным возможностям сервисов Сайта и действительно с момента публикации на
      сайте по адресу <a href="https://humanicl.com/terms-of-use/" target="_blank">https://humanicl.com/terms-of-use/</a> 
      Принятие оферты на условиях настоящего Соглашения происходит путем выражения
      Пользователем согласия с его условиями при совершении регистрации на Сайте.
    </p>
    <h3>ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h3>
    <p>
      Пользователь – лицо, добровольно прошедшее регистрацию на Сайте Администратора,
      имеющее аккаунт с уникальным логином и паролем. Пользователь является адресным
      получателем данной оферты по предоставлению доступа к персонифицированным сервисам
      Сайта.
    </p>
    <p>
      Администратор - правообладатель интернет - ресурса 
      <a href="https://humanicl.com/" target="_blank">https://humanicl.com/</a>, которому
      принадлежат все соответствующие исключительные имущественные права на Сайт, включая
      права на доменное имя Сайта.
    </p>
    <p>
      Сайт - интернет-ресурс, размещенный в сети Интернет по адресу 
      <a href="https://humanicl.com/" target="_blank">https://humanicl.com/</a> со
      следующими функциональными возможностями:
      <li>получение доступа к личному кабинету пользователя с возможностью загрузки</li>
      собственных фотографий и изменению своих персональных данных;
      <li>получение возможности публикации проектов и вакансий;</li>
      <li>получение возможности поиска заказчика/исполнителя;</li>
      <li>получение возможности публикации портфолио;</li>
      <li>получение доступа к системе ведения проекта;</li>
    </p>
    <p>
      Комплекс услуг, предоставляемых Сайтом, является необходимым и достаточным для
      реализации настоящего Пользовательского соглашения.
      Сайт, его составляющие и отдельные компоненты (в том числе, но не ограничиваясь:
      программы для ЭВМ, базы данных, коды, лежащие в их основе ноу – хау, алгоритмы, элементы
      дизайна, шрифты, логотипы, а также текстовые, графические и иные материалы) являются
      объектами интеллектуальной собственности, охраняемыми в соответствии с действующим
      национальным и международным законодательством, любое использование которых
      допускается только на основании разрешения Администратора.
    </p>
    <p>
      Регистрация – процедура, в ходе которой Пользователь предоставляет о себе достоверные
      данные по утвержденному Администратором перечню и получает пароль для пользования
      персонифицированными сервисами Сайта.
    </p>
    <p>
      Личный кабинет - персонифицированный интерфейс Сайта с набором пользовательских
      инструментов, позволяющих изменить персональные данные пользователя.
    </p>
    <p>
      Пароль – цифровой код, сгенерированный системой при регистрации, либо установленный
      Пользователем самостоятельно и используемый в сочетании с логином для получения доступа
      Пользователя к персонифицированным сервисам Сайта.
    </p>
    <p>
      Аккаунт – учетная запись Пользователя в электронной системе сайта по адресу
      <a href="https://humanicl.com/account" target="_blank">https://humanicl.com/account</a>, 
      предназначенная для хранения персональной информации Пользователя,
      идентификации последнего при работе с системой и юридически значимых действий с
      использованием уникальной пары «логин плюс пароль».
    </p>
    <p>
      Персональная информация о Пользователе – любая информация, которую Пользователь
      предоставляет о себе самостоятельно при регистрации (создании аккаунта Пользователя) или в
      процессе использования Сайта, включая персональные данные Пользователя, информацию,
      которая автоматически передается Администратору Сайта в процессе использования Сайта с
      помощью установленного на устройстве Пользователя программного обеспечения, в том числе
      IP-адрес, информация из cookie, информация о браузере пользователя, а также иные данные о
      Пользователе.
    </p>
    <p>
      Материалы – любая информация, размещаемая (размещенная) Пользователем или
      Администратором Сайта на Сайте, в том числе: Персональная информация о Пользователе,
      ссылки на другие сайты, любые текстовые сообщения, видео / аудио материалы, фотографии
      (изображения), программы, и др. файлы.
      Любые термины и понятия, использующиеся в Пользовательском соглашении и не нашедшие
      отражения в разделе «Термины и определения», будут толковаться в соответствии со смыслом,
      вытекающим из текста Пользовательского соглашения. В случае возникновения любых
      разногласий относительно толкования термина и/или понятия, используемого в Пользовательском
      соглашении, применяться будет то толкование, которое будет определено Администратором
      Сайта.
    </p>
    <h3>1. ПРЕДМЕТ СОГЛАШЕНИЯ</h3>
    <p>
      1.1 По настоящему Соглашению Администратор предоставляет Пользователю доступ к Сайту и
      его функциональным возможностям в соответствии с понятийным аппаратом настоящего
      Соглашения, в том числе в целях обеспечения возможности использования программного
      обеспечения Администратора. Пользователь, в свою очередь, имеет право использовать Сайт на
      условиях, предусмотренных настоящим Соглашением.
    </p>
    <p>
      1.2. Использование всех предлагаемых Администратором функциональных возможностей
      Сайта доступно для Пользователя на условиях настоящего Соглашения после прохождения
      регистрации Пользователя.
    </p>
    <h3>2. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h3>
    Права и обязанности Администратора:
    <p>
      2.1. Администратор обязан предоставить Пользователю доступ к Сайту и поддерживать Сайт в
      рабочем состоянии в режиме 7 дней в неделю 24 часа в сутки без выходных и праздничных
      дней.
    </p>
    <p>
      2.2. Администратор обязан пресекать нарушение Пользователями законодательства
      доступными способами и методами.
    </p>
    <p>
      2.3. Администратор обязан оказывать Пользователю информационную и техническую
      поддержку посредством электронной почты info@humanicl.com. Указанная в настоящем
      пункте информационная и техническая поддержка имеет своей целью консультирование
      Пользователя по вопросам использования Сайта, совершение по указанию Пользователя, но от
      имени Администратора действий, за исключением юридически значимых. Инициатором
      обращения за поддержкой является Пользователь. Администратор обязан обеспечить
      функционирование работы поддержки в круглосуточном режиме семь дней в неделю. Однако,
      срок ответа на запрос может быть ограничен режимом работы компании по месту ее
      регистрации.
    </p>
    <p>
      2.4. Администратор оставляет за собой право использовать для непосредственной связи с
      Пользователем адрес электронной почты и другие контактные данные, указанные
      Пользователем в своем аккаунте. Пользователь подтверждает и гарантирует, что адрес
      электронной почты, предоставленный в качестве логина в рамках настоящего Соглашения,
      зарегистрирован на имя Пользователя.
    </p>
    <p>
      2.5. Администратор обязуется обеспечить конфиденциальность и сохранность персональных
      данных Пользователя от разглашения, либо какой – либо передачи третьим лицам, кроме
      случаев, когда на передачу таковых определенному лицу получено согласие Пользователя,
      случаев, когда разглашение произошло по независящим от Администратора причинам, а также
      за исключением случаев, предусмотренных действующим законодательством. Администратор
      производит обработку персональных данных Пользователей в соответствии с Политикой в
      отношении обработки персональных данных, размещенной на сайте Администратора по адресу
      <a href="https://humanicl.com/" target="_blank">https://humanicl.com/</a>
      Права и обязанности Пользователя:
    </p>
    <p>
      2.6. В целях реализации настоящего Соглашения Пользователь самостоятельно обеспечивает
      наличие необходимых для этого технических характеристик используемого электронного
      устройства, его подключение к сети Интернет.
    </p>
    <p>
      2.7. Пользователь обязан заблаговременно ознакомиться с документами, регламентирующими
      предоставление персонифицированных услуг через Сайт Администратора и размещенными на
      Сайте Администратора по адресу <a href="https://humanicl.com/" target="_blank">https://humanicl.com/</a>
    </p>
    <p>
      2.8. Пользователь обязан предоставить достоверную информацию и данные, запрашиваемые
      Администратором как в процессе Регистрации на сайте, так и в персонализированном
      пространстве Пользователя, обеспечивать актуальность предоставленных данных.
    </p>
    <p>
      2.9. Пользователь дает согласие на обработку персональных данных на условиях Политики в
      отношении обработки персональных данных к настоящему Соглашению, размещенной в сети
      Интернет по адресу 
      <a href="https://humanicl.com/privacy-policy/" target="_blank">https://humanicl.com/privacy-policy/</a>. 
      Данное условие настоящего Соглашения является существенным. Отзыв Согласия на обработку
      персональных данных влечет расторжение настоящего Соглашения.
    </p>
    <p>
      2.10. Пользователь обязан использовать Сайт не нарушая местных, государственных,
      национальных или международных законов.
    </p>
    <p>
      2.11. Пользователю запрещено размещать и использовать материалы, нарушающие
      интеллектуальную собственность третьих лиц, а также материалы, нарушающие
      неприкосновенность частной жизни третьих лиц.
    </p>
    <p>
      2.12. Пользователю запрещено размещать материалы, которые являются незаконными
      непристойными, клеветническими, угрожающими, оскорбительными, клеветническими,
      вызывающие ненависть по отношению к любому другому физическому или юридическому
      лицу.
    </p>
    <p>
      2.13. Пользователю запрещено размещать рекламу без согласования с Администратором.
    </p>
    <p>
      2.14. Пользователю запрещено осуществлять спам-рассылку используя Сайт.
    </p>
    <p>
      2.15. Пользователю запрещено осуществлять сбор персональных данных используя Сайт.
    </p>
    <h3>3. ОТВЕТСТВЕННОСТЬ СТОРОН</h3>
    <p>
      3.1. Администратор обязуется обеспечить стабильную работу Сайта, его совершенствование и
      максимально быстрое избавление от ошибок в работе Сайта, однако Сайт предоставляется
      Пользователю по принципу «как есть». Указанное означает, что Администратор:
      - не гарантирует отсутствие ошибок в работе Сайта;
      - не несет ответственность за его бесперебойную работу, совместимость с программным
      обеспечением и техническими средствами Пользователя;
      - не несет ответственность за неисполнение, либо ненадлежащее исполнение своих
      обязательств вследствие сбоев в телекоммуникационных или энергетических сетях, действий
      вредоносных программ, а также недобросовестных действий третьих лиц, направленных на
      несанкционированный доступ и/или выведение из строя программного и/или аппаратного
      комплекса Администратора;
    </p>
    <p>
      3.2. Администратор не несет ответственности за содержание Материалов, размещенных
      Пользователем и за соответствие их требованиям законодательства, за нарушение авторских
      прав, несанкционированное использование товарных знаков, наименований фирм и их
      логотипов, а также за возможные нарушения прав третьих лиц в связи с размещением
      Материалов на Сайте. В случае поступления претензий от третьих лиц, связанных с
      размещением Материалов, Пользователь самостоятельно и за свой счет урегулирует указанные
      претензии.
      </p> 
    <p>  
      3.3. Пользователь несет полную ответственность за сохранность своего логина и пароля для
      доступа к Личному кабинету и за убытки, которые могут возникнуть по причине
      несанкционированного использования его доступа.
    </p>
    <p>
      3.4. Пользователь несет ответственность за достоверность, актуальность, полноту и
      соответствие законодательству предоставленной при регистрации информации и ее чистоту от
      претензий третьих лиц.
    </p>
    <p>
      3.5. Администратор не несет ответственности за любой ущерб компьютеру Пользователя или
      иного лица, мобильным устройствам, любому другому оборудованию или программному
      обеспечению, вызванный или связанный со скачиванием материалов с Сайта или переходом по
      ссылкам, размещенным на Сайте. Во избежание недоразумений Пользователю следует
      соблюдать меры предосторожности при скачивании материалов с Сайта или при переходе по
      размещенным на нем ссылкам, и использовании любых файлов, в том числе программного
      обеспечения. Администратор настоятельно рекомендует использовать только лицензионное, в
      том числе антивирусное, программное обеспечение.
    </p> 
    <p> 
      3.6. Администратор или ее представители ни при каких обстоятельствах не несут
      ответственность перед Пользователем или перед любыми третьими лицами за любой
      косвенный, случайный, неумышленный ущерб, включая упущенную выгоду или потерянные
      данные, вред чести, достоинству или деловой репутации, вызванный в связи с использованием
      Сайта, содержимого Сайта или иных материалов, к которым Пользователи или иные лица
      получили доступ с помощью Сайта.
    </p>
    <h3>4. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>
    <p>
      4.1. Настоящее Соглашение вступает в силу с момента присоединения к нему Пользователя и
      действует бессрочно.
    </p>
    <p>
      4.2. Настоящее Соглашение может быть расторгнуто Пользователем в одностороннем
      внесудебном порядке с момента направления соответствующего волеизъявления на
      электронную почту Администратора info@humanicl.com
    </p>
    <p>
      4.3. Настоящее Соглашение может быть расторгнуто в одностороннем внесудебном порядке по
      инициативе Администратора с момента совершения действий, предусмотренных пп. 2.11 – 2.15
      настоящего Соглашения.
    </p>
    <p>
      4.4. Настоящее Соглашение полностью, либо в части может быть изменено Администратором
      без какого-либо специального уведомления Пользователя, в связи с чем Пользователь обязуется
      регулярно отслеживать изменения в Соглашении. При несогласии с условиями новой редакции
      Пользователь обязан прекратить пользование Сервисом. Новая редакция Соглашения вступает
      в силу с момента ее размещения на сайте, если иное не предусмотрено новой редакцией
      Соглашения.
    </p>  
    <p>  
      4.5. Все вопросы неурегулированные настоящим соглашением, разрешаются основе
      законодательства Российской Федерации
    </p>
  `,
  // SignIn
  sign_in: 'Войти',
  sign_up: 'Зарегистрироваться',
  dont_have_account: 'Еще нет аккаунта?',
  already_signed_up: 'Уже зарегистрированы?',
  sign_in_to_account: 'Войти в аккаунт',
  forgot_password: 'Забыли пароль?',
  fill_up_personal_info: 'Заполните личную информацию и начните работать вместе с нами.',
  if_you_already_have_account: 'Если у вас уже есть аккаунт, войдите.<br>Мы скучали по вам!',
  email_address: 'Email адрес',
  password: 'Пароль',
  sign_up_for_an_account: 'Зарегистрировать аккаунт',
  choose_type_of_account_you_need: 'Выберите тип аккаунта',
  i_am_a_client: 'Я - Клиент',
  i_need_specialists: 'Мне нужны специалисты',
  we_are_a_team: 'Я - Исполнитель',
  we_have_specialists: 'Я один или с командой',
  create_password: 'Создайте пароль',
  i_agree_to: 'Я согласен с',
  terms_and_conditions: 'правилами и условиями сервиса',
  remember_me: 'запомнить меня',
  enter_email: 'Введите email',
  enter_password: 'Введите пароль',
  email_is_required: 'Электронная почта обязательна',
  enter_valid_email: 'Укажите корректный email адрес',
  password_is_required: 'Пароль обязателен',
  choose_stronger_password: 'Укажите более надежный (длинный) пароль',
  have_to_agree_to_terms: 'Вы должны согласиться с условиями и положениями, чтобы зарегистрироваться',  
  // About us
  about_us: 'О нас',
  about_us_content: `
    <li>
      Мы связываем сотрудников различных местных ИТ-компаний из Восточной Европы и Средней Азии (Россия, Украина, Беларусь и другие страны СНГ) с предприятиями по всему миру, используя модель аутстаффинга.
    </li>
    <li>
      Такая модель сотрудничества позволит минимизировать риски и получить ресурсы для заказчиков по оптимальным ценам, а также позволяет исполнителям работать на международном рынке.
    </li>
    <li>
      У нас есть собственные внутренние команды разработки и большая сеть локальных партнеров в разных регионах мира со своими собственными внутренними командами.
    </li>
    <li>
      Для работы с платформой достаточно одного контракта - как для исполнителей, так и для заказчиков.
    </li>
  `,
  // Landing-view
  // Landing-footer
  landing_view_company_address: 'Россия, Москва,<br> Молодогвардейская, 41<br> Россия, Киров, <br>Ленина, 7',
  landing_view_phone_number: ' ',
  // Title
  landing_view_page_main_title: '!!!Зарабатывайте с нашей платформой<span class="text--highlight" text> до 2 раз больше средней зарплаты по рынку</span> и самостоятельно выбирайте проекты',
  landing_view_page_main_subtitle: 'Долгосрочные проекты в средних и крупных компаниях для IT-специалистов',
  // Section: equal opportunities
  landing_view_equal_opportunities: 'Равные возможности',
  landing_view_equal_opportunities_description: 'Мы предоставляем равные возможности и выступаем против дискриминации и притеснений, независимо от расы, цвета кожи, религии, пола, сексуальной ориентации, гендерной идентичности или самовыражения, возраста, статуса инвалидности, статуса ветерана или любой другой характеристики, охраняемой законом.',
  landing_view_equal_opportunities_1: 'Проверенные заказчики',
  landing_view_equal_opportunities_2: 'Удаленная работа',
  landing_view_equal_opportunities_3: 'Быстрый профессиональный рост',
  landing_view_equal_opportunities_4: 'Доход выше среднего',
  landing_view_apply_for_job: 'Присоединиться',
  landing_view_apply_for_this_job: 'Откликнуться',
  // Section: Why do people like working with us
  landing_view_why_work_with_us: 'Почему мы?',
  landing_view_why_work_with_us_1: 'Humanicl - это междунарожная платформа.',
  landing_view_why_work_with_us_2: 'Мы гарантируем равные возможности нашим партнерам.',
  landing_view_why_work_with_us_3: 'Мы юридически легитимны.',
  landing_view_why_work_with_us_4: 'Вам нужен только один контракт для работы со всеми нашими партнерами.',
  // Section: Testimonials
  landing_view_testimonials: 'Отзывы',
  // Features
  landing_view_features: 'Особенности',
  landing_view_single_contact: ' ооо Единый электронный контракт с платформой для работы со всеми клиентами.',
  landing_view_automated_interview: 'Автоматизированная система управления интервью(α)',
  landing_view_automated_system: 'Автоматизированная система проверки отработанных часов(α)',
  landing_view_automatic_generation: 'Автоматическая генерация рабочих документов(α)',
  landing_view_built_in_messaging: 'Встроенная система обмена сообщениями между заказчиком и специалистом(α)',
  landing_view_depersonalized_cv: 'Де-персонализированные резюме для отсечки нерелевантных запросов.',
  // Workflow
  landing_view_workflow: 'Как это работает',
  landing_view_work_cycle: 'Рабочий цикл',
  landing_view_choose_your_project: 'Пройди интервью на проекте',
  landing_view_choose_your_project_subtitle: 'если ваши навыки удовлетворяют требованиям проекта и вы готовы на нем работать, то вы приняты.',
  landing_view_project_interview: 'Работа на проекте',
  landing_view_project_interview_subtitle: 'Удаленно, в среднем - 40 часов в неделю под руководством заказчика и в его системе таск-трекинга',
  landing_view_work_on_project: 'Расчеты',
  landing_view_work_on_project_subtitle_3: 'Ежемесячная выплата начиная со второго месяца работы. Сумма за первый месяц выплачивается по окончании контракта',  
  landing_view_completion_of_the_project: 'Переход на новый проект',
  landing_view_completion_of_the_project_subtitle_3: 'По окончании очередного проекта вы можете завершить сотрудничество либо пройти интервью на следующем проекте.',
  // Profile
  profile: 'профиль',
  my_profile: 'мой профиль',
  specialists: 'специалисты',
  add_specialist: 'добавить специалиста',
  my_specialists: 'мои специалисты',
  my_projects: 'мои проекты',
  responses: 'отклики',
  applications: 'заявки',
  acts: 'акты',
  reports: 'отчеты',
  invoices: 'счета',
  grade: 'уровень',
  nameRole: 'имя / роль',
  format: 'формат',
  actions: 'действия',
  devInternalAffiliation: 'свой',
  devExternalAffiliation: 'чужой',
  // Admin
  vacancies: 'вакансии',
  add_vacancy: 'добавить вакансию',
  new_vacancy: 'новая вакансия',
  denomination: 'наименование',
  // Vacancy
  vacancy_label_role: 'Необходимая роль*',
  vacancy_label_specialist_grade: 'Уровень специалиста*',
  vacancy_label_specialist_amount: 'Количество специалистов*',
  vacancy_label_english_level: 'Уровень английского*',
  vacancy_label_job_type: 'Занятость*',
  vacancy_label_specialist_min_requirements: 'Минимальные требования',
  vacancy_label_project_description: 'О проекте',
  vacancy_label_location: 'Локация (оставьте поле пустым, если локация не важна)',
  vacancy_label_hourly_rate: 'Ставка в час, max $',
  vacancy_label_customer: 'Заказчик*',
  vacancy_label_skills: 'ключевые навыки',
  vacancy_field_project_description: 'Краткое описание проекта',
  vacancy_field_role: 'наименование роли в международном формате, например: Fronted developer',
  vacancy_field_select: 'Выберите',
  vacancy_field_specialist_amount: 'укажите нужное количество специалистов',
  vacancy_field_specialist_min_requirements: 'Опишите минимальные требования к специалистам',
  vacancy_field_hourly_rate: 'укажите ставку за час',
  vacancy_field_skills: 'Укажите ключевые навыки международном формате, например: HTML, CSS, JS',
  // Dialogs
  vacancy_editor_prompt_title: 'Закрыть редактор вакансии?',
}

export {
  description,
  data
}
