<template>
  <div 
    class="data-table-header"
    :style="{
      'grid-template-columns': itemStyles.gridTemplateColumns
    }"
  >
    <div 
      class="data-table-header__item"
      v-for="(item, index) in headerData"
      :key="'header-item-' + index"
    >
      <div
        class="prop id"
        v-if="item.prop === 'id'"
      >
        {{item.title}}
      </div>
      
      <div
        class="prop actions"
        v-else-if="item.prop === 'actions'"
      >
        {{item.title}}
      </div>

      <div
        class="prop"
        v-else
      >
        {{item.title}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataTableHeader',
  props: {
    headerData: Array,
    itemStyles: Object,
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style lang="scss">
.data-table-header {
  display: grid;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  padding: 0 24px;

  &__item {
    color: var(--grey-2);
    .prop.id {
      margin-left: 8px;
      margin-right: 8px;
    }
    .prop.actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
</style>