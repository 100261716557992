<template>
  <div class="drawer" :show="drawerValue" v-click-outside="onClickOutside">  
    <div class="drawer__inner">
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vClickOutside from 'v-click-outside'

export default {
  name: 'drawer',
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
    }
  },
  watch: {
    '$store.state.drawer.value' (value) {
      if (!value) {
        this.$store.state.overlays.window = false
      }
    }
  },
  computed: {
    ...mapGetters([
      'navItemsLocalized',
    ]),
    drawerValue: {
      get () {
        return this.$store.state.drawer.value
      },
      set (value) {
        this.$store.state.drawer.value = value
        this.$store.state.overlays.window = value
      }
    },
    availableLanguages () {
      return this.$store.state.storageData.settings.localization.availableLanguages
    },
    selectedLanguage: {
      get () {
        return this.$store.state.storageData.settings.localization.selectedLanguage
      },
      set (value) {
        this.$store.state.storageData.settings.localization.selectedLanguage = value
      }
    },
    user () {
      return this.$store.state.user
    },
  },
  methods: {
    onClickOutside (event) {
      const isMenuButtonNode = event.target.parentNode.classList
      if (!isMenuButtonNode) {
        this.$store.state.drawer.value = false
      }
    },
    loginBtn () {
      this.$store.dispatch('OPEN_LOGIN_PAGE')
      this.$store.state.drawer.value = false
    },
    profileBtn () {
      this.$store.dispatch('OPEN_LOGIN_PAGE')
      this.$store.state.drawer.value = false
    },
  }
}
</script>

<style lang="scss">
.drawer {
  display: none;
  top: var(--main-toolbar-height);
  right: 0;
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  max-width: 100vw;
  background-color: #fff;
  z-index: 1;
  @media (max-width: $xs) {
  }
  &[show] {
    display: block;
  }
  &__inner {
    height: calc(100vh - var(--main-toolbar-height));
    @media (max-width: $xs) {
      height: calc(100vh - var(--main-toolbar-height-xs));
    }
    overflow-y: auto;
  }
}
</style>