<template>
  <div class="data-table-footer">
    <div class="data-table-footer__container">
      <div class="data-table-footer__container__results">
        {{$localize.get('show_on_page')}}:
        <v-menu offset-y>
          <template v-slot:activator="{on, attrs}">
            <div
              class="data-table-footer__container__results__selector"
              v-bind="attrs"
              v-on="on"
              dark
              text
            >
              {{footerData.itemsPerPage}}
              <img src="../assets/chevron-down-grey.svg">
            </div>
          </template>
          <v-list>
            <v-list-item
              v-for="(number, index) in footerData.itemsPerPageArray"
              :key="index"
              @click="footerData.itemsPerPage = number"
            >
              <v-list-item-title>{{number}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        {{$localize.get('of', {capitalize: false})}} {{tableData.length}}
      </div>
      <div class="data-table-footer__container__pagination">
        <pagination
          :page="footerData.page"
          :items="tableData"
          :itemsPerPage="footerData.itemsPerPage"
          @input="footerData.page = $event"
        >
        </pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataTableFooter',
  props: {
    footerData: Object,
    tableData: Array,
  },
  data () {
    return {

    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style lang="scss">
.data-table-footer {
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;

    &__results {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      color:  #939CB4;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      @media (max-width: $md) {
        display: none;
      }

      &__selector {
        margin: 0 12px;
        padding: 8px 16px;
        background-color: #fff;

        img {
          margin-left: 32px;
        }
      }
    }

    &__pagination {
    }
  }
}
</style>