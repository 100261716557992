<template>
  <div class="tabs-section__tabs__container" :blue-bg="blueBg">
    <div class="tabs-section__tabs__header" v-if="tabItems.length > 1">
      <div 
        class="tabs-section__tabs__header__item"
        v-for="(tabItem, index) in tabItems"
        :key="'tabItem-' + index"
        :active="index === activeTabIndex"
        @click="activeTabIndex = index"
        :blue-bg="blueBg"
      >
        {{ tabItem }}
      </div>
    </div>
    <div 
      class="tabs-section__tabs__content" 
      :fill-width="fillWidth"
      :blue-bg="blueBg"
    >
      <div 
        class="tabs-section__tabs__content__inner" 
      >
        <div class="tabs-section__tabs__content__title" v-if="hasTitle">
          <slot name="title"></slot>
        </div>
        <div class="tabs-section__tabs__content__main">
          <slot :name="`content-${activeTabIndex}`"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabsSection',
  props: {
    tabItems: Array,
    fillWidth: Boolean,
    blueBg: Boolean,
    hasTitle: Boolean,
  },
  data () {
    return {
      activeTabIndex: 0
    }
  }
}
</script>

<style lang="scss">
.tabs-section__tabs {
  &__container {
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    font-size: 18px;
    font-family: 'Proxima Nova Lt';
    &__item {
      width: 295px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px 4px 0 0;
      background-color: #DDECFF;
      font-weight: 600;
      cursor: pointer;
      box-shadow:
        -2px -2px 4px rgba(0, 0, 0, 0.04), 
        inset 0px -4px 4px rgba(0, 0, 0, 0.04);
      &[active] {
        background-color: #fff;
        color: var(--main-color-1);
        box-shadow: -2px -4px 4px rgba(0, 0, 0, 0.04);
        &[blue-bg] {
          background-color: var(--light-blue-bg-color);
        }
      }
      @media (max-width: $xs) {
        padding: 0 16px;
        height: 54px;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 161%;
        letter-spacing: 0.04em;
      }
      @media (max-width: 370px) {
        font-size: 14px;
        padding: 0 17px;
      }
      @media (max-width: 280px) {
        padding: 0 4px;
      }
    }
  }
  &__content {
    background-color: #fff;
    &[blue-bg] {
      background-color: var(--light-blue-bg-color);
    }
    &__inner {
      margin: 0 auto;
      padding: 128px 0;
      max-width: var(--max-content-width);
      @media (max-width: 1264px) {
        padding: 64px 0;
      }
      @media (max-width: $xs) {
        width: var(--main-view-section-width-xs);
      }
      &:not([fill-width]) {
        max-width: var(--max-content-width);
        width: var(--main-view-section-width);
        @media (max-width: $xs) {
          width: var(--main-view-section-width-xs);
        }
      } 
    }
    &__title {
      text-align: left;
      margin-bottom: 84px;
      color: var(--main-color-1);
      font-weight: 900;
      line-height: 114%;
      font-family: 'Proxima Nova Bl';
      font-size: 60px;
      @media (max-width: $xs) {
        font-size: 32px;
        margin-bottom: 56px;
        text-align: center;
      }
    }
    &__main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>