<template>
  <div class="dropdown-menu">
    <button
      class="dropdown-menu__button"
      @click="isOpened = !isOpened"
      @blur="isOpened = false"
      :border="border"
    >
      <span class="dropdown-menu__button__prepend">
        <slot name="prepend"></slot>
      </span>
      <span>{{ value[itemText] }}</span>
      <span class="dropdown-menu__button__append">
        <i class="mdi mdi-chevron-down"></i>
      </span>
    </button>
    <transition name="dropdown-menu">
      <div
        class="dropdown-menu__list-container"
        v-show="isOpened"
      >
        <ul
          class="dropdown-menu__list"
        >
          <div
            class="dropdown-menu__list__item"
            v-for="(item, index) in items"
            :key="'drop-down-menu-' + index"
            @mousedown.prevent="setItem(item)"
          >
            {{ item[itemText] }}
          </div>
        </ul>
      </div> 
    </transition>
  </div>
</template>

<script>
export default {
  name: 'DropdownMenu',
  props: {
    value: {
      type: Object,
      required: true
    },
    items: Array,
    itemText: String,
    border: Boolean
  },
  data () {
    return {
      isOpened: false
    }
  },
  methods: {
    setItem (item) {
      this.$emit('input', item)
      this.isOpened = false
    }
  }
}
</script>

<style lang="scss">
.dropdown-menu {
  position: relative;
  &__button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    min-width: 100px;
    width: fit-content;
    font-size: 18px;
    font-family: 'Proxima Nova Lt';
    font-weight: 600;
    color: inherit;
    border: none;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &[font-20] {
      font-size: 20px;
    }
    &[border] {
      border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    }
    i {
      display: flex;
    }
    &__prepend {
      i {
        margin-right: 8px;
        font-size: 22px;
        min-width: 20px;
        min-height: 20px;
      }
    }
    &__append {
      i {
        min-width: 32px;
        min-height: 20px;
        margin-left: 12px; 
        font-size: 24px;
      }
    }
  }
  &__list {
    position: absolute;
    left: 0;
    right: 0;
    width: fit-content;
    min-width: 152px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15),
      0px 16px 24px rgba(0, 0, 0, 0.15);
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    cursor: pointer;
    z-index: 5;
    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      padding: 12px 24px;
      transition: all 0.3s;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
        transition: all 0.3s;
      }
    }
  }
}
.v-application .dropdown-menu ul {
  padding-left: 0;
}
</style>