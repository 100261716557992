<template>
  <div class="pagination" v-show="numberOfPages > 1">
    <div 
      class="pagination__button"
      @click="previousPage()"
      :is-disabled="isFirstPageSelected"
    >{{$localize.get('previous_short')}}
    </div>
    <v-pagination
      :value="page"
      @input="changePage($event)"
      :length="numberOfPages"
      :total-visible="totalVisiblePages"
      circle
    >
    </v-pagination>
    <div 
      class="pagination__button"
      @click="nextPage()"
      :is-disabled="isLastPageSelected"
    >{{$localize.get('next_short')}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    items: Array,
    itemsPerPage: Number,
    page: Number,
    scrollIntoViewSelector: String,
  },
  data () {
    return {
    }
  },
  computed: {
    numberOfPages () {
      return Math.ceil(this.items.length / this.itemsPerPage)
    },
    isFirstPageSelected () {
      return this.page === 1 
    },
    isLastPageSelected () {
      return this.numberOfPages === this.page
    },
    totalVisiblePages () {
      if (this.$vuetify.breakpoint.xs) {
        return 3
      }
      else if (this.$vuetify.breakpoint.sm) {
        return 6
      }
      else if (this.$vuetify.breakpoint.md) {
        return 7
      }
      else {
        return 5
      }
    },
  },
  methods: {
    changePage (event) {
      this.$emit('input', event)
      this.scrollIntoView()
    },
    previousPage () {
      if (this.page - 1 >= 1) {
        this.$emit('input', this.page - 1)
      }
      this.scrollIntoView()
    },
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) {
        this.$emit('input', this.page + 1)
      }
      this.scrollIntoView()
    },
    scrollIntoView () {
      if (this.scrollIntoViewSelector) {
        document.querySelector(this.scrollIntoViewSelector).scrollIntoView()
      }
    }
  }
}
</script>

<style lang="scss">
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    cursor: pointer;
    background-color: #fff;
  }
}
.pagination {
  user-select: none;
  &__button {
    margin: 0 12px;
    cursor: pointer;
    color: var(--blue-main);
    &[is-disabled] {
      color: rgba(0, 0, 0, 0.4);
      cursor: default;
    }
  }
}
.v-application {
  .pagination {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    .v-pagination__item {
      color: var(--grey-4) !important;
      width: 48px;
      height: 48px;
      box-shadow: none;
    } 
    .v-pagination__navigation {
      display: none;
    }
    .v-pagination__item--active {
      color: #fff !important;
      background-color: var(--blue-main) !important;
    }
  } 
}
</style>