import {createNewSortInstance} from 'fast-sort'

const naturalSort = createNewSortInstance({
  comparer: new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'}).compare
})

export function sort (params) {
  const order = params.sorting.order
  const sortingType = params.sorting.selectedType

  if (sortingType === 'status') {
    params.items = order === 'asc'
      ? naturalSort(params.items).asc(item => item.properties.status.text)
      : naturalSort(params.items).desc(item => item.properties.status.text)
  }
  else if (sortingType === 'description') {
    params.items = order === 'asc'
      ? naturalSort(params.items).asc(item => item.properties.description.name)
      : naturalSort(params.items).desc(item => item.properties.description.name)
  }
  else if (sortingType === 'role') {
    params.items = order === 'asc'
      ? naturalSort(params.items).asc(item => item.properties.role.name)
      : naturalSort(params.items).desc(item => item.properties.role.name)
  }
  else {
    params.items = order === 'asc'
      ? naturalSort(params.items).asc(item => item.properties[sortingType])
      : naturalSort(params.items).desc(item => item.properties[sortingType])
  }
  return params.items
}