<template>
  <div>
    <v-dialog
      v-if="dialogs.prompt.value"
      v-model="dialogs.prompt.value"
      :max-width="$vuetify.breakpoint.smAndDown ? '95vw' : maxWidth || 500"
    >
      <v-card
        class="dialog-card"
        :style="{
          'max-height': height || '200px'
        }"
      >
        <v-card-title class="sticky-scroller__header unselectable">
          <v-layout justify-space-between>
            <div class="dialog-card__title">
              {{dialogs.prompt.data.title}}
            </div>
            <v-btn @click="dialogs.prompt.value = false" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-layout>
        </v-card-title>

        <div class="dialog-card__content">
          {{dialogs.prompt.data.text}}
        </div>

        <v-card-actions
          v-if="dialogs.prompt.data.buttons"
          class="dialog-card__actions-container px-4"
        >
          <v-spacer></v-spacer>
          <h-button
            v-for="(button, index) in dialogs.prompt.data.buttons"
            :key="index"
            :disabled="button.disabled"
            @click.native="button.onClick()"
            text green x-small-padding small
          >{{button.text}}
          </h-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  props: {
    height: String,
    maxWidth: String
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapFields({
      dialogs: 'dialogs'
    }),
  },
}
</script>

<style lang="scss">
.dialog__toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid var(--border-color-1);
  &__title {
    font-size: 18px;
  }
}

.dialog-card__title {
  font-weight: 700;
}

.dialog-card__content {
  padding: 4px 24px;
  display: flex;
  align-items: center;
  font-family: 'Proxima Nova Rg';
  font-weight: 500;
}
</style>