const description = {
  name: 'English',
  locale: 'en'
}

const data = {
  // Main toolbar
  main: 'main',
  home: 'home',
  jobs: 'jobs',
  careers: 'careers',
  hire: 'hire',
  partnership: 'partnership',
  faq: 'FAQ',
  projects: 'projects',
  talents: 'talents',
  login: 'login',
  logout: 'logout',
  date: 'date',
  publish: 'publish',
  cancel: 'cancel',
  exit: 'exit',
  continue: 'continue',
  changes_will_be_lost: 'changes will be lost',
  filters: 'filters',
  search: 'search',
  back: 'back',
  vacancies_jobs: 'jobs',
  apply: 'apply',
  support: 'support',
  customer: 'customer',
  sort_by: 'sort by',
  sorting_order: 'sorting order',
  asc: 'ascending',
  desc: 'descending',
  chat: 'chat',
  notifications: 'notifications',
  delete: 'delete',
  download: 'download',
  read_more: 'read more',
  selected_filters: 'Selected filters',
  reset: 'reset',
  other: 'other',
  of: 'of',
  previous_short: 'prev',
  next_short: 'next',
  show: 'show',
  show_on_page: 'show on page',
  countries: 'countries',
  sectors: 'sectors',
  duration: 'duration',
  payment_method: 'payment method',
  location: 'location',
  status: 'status',
  available_for: 'available for',
  category: 'category',
  work_area: 'work area',
  role: 'role',
  level: 'level',
  hard_skills: 'hard skills',
  project_description: 'project description',
  role_description: 'role description',
  role_responsibilities: 'role responsibilities',
  preferred_skills: 'preferred skills',
  minimum_requirements_and_work_experience: 'minimum requirements and work experience',
  // Validation
  field_is_required: 'field is required',
  some_form_fields_are_invalid: 'some fields are invalid',
  // Sections
  section_features: 'Features',
  section_workflow: 'Workflow',
  section_opportunities: 'Opportunities',
  section_why_us: 'Why us',
  section_testimonials: 'Testimonials',
  // Main footer
  text_open_world_remotely: 'One click opens the world of new possibilities',
  company_address: 'Russia,<br>Moscow,<br> Molodogvardeyskaya st., 41-10',
  phone_number: '+7 499 322 98 09',
  // Home page
  home_page_main_title: '<span class="text--highlight" text>B2B platform</span> for quick hiring vetted IT professionals from around the world',
  home_page_main_subtitle: 'We connect employees of different IT companies with businesses using the outstaffing model',
  home_page_slogan: `
    <div>
      <span class="text--highlight" text blue-1>The world is changing</span> 
      every moment.
    </div>
    <div>
      It's time to be really 
      <span class="text--highlight" text blue-3>flexible, agile</span> 
      and 
      <span class="text--highlight" text blue>fast.</span>
    </div>
    <div>
      <span class="text--highlight" text green>Join the HUMANICL</span> 
      to build your new 
      <span class="text--highlight" text green>future.</span>
    </div>
  `,
  join_platform: 'join platform',
  learn_more: 'learn more',
  for_customers: 'for customers',
  for_it_agencies: 'for IT-agencies',
  for_contractors: 'for contractors',
  // Careers page
  careers_page_main_title: '<span class="text--highlight" text>Choose any projects you like</span> and work with us remotely',
  careers_page_main_subtitle: 'Long-term projects in medium and large companies for IT specialists',
  // Section: equal opportunities
  equal_opportunities: 'Equal opportunities',
  equal_opportunities_description: 'We are an equal opportunity employer and oppose discrimination and harassment of any person regardless of race, color, religion, gender, sexual orientation, gender identity or expression, age, disability status, protected veteran status, or any other characteristic protected by law',
  equal_opportunities_1: 'Сompanies from all over the world',
  equal_opportunities_2: 'Remote job',
  equal_opportunities_3: 'Fast professional growth',
  equal_opportunities_4: 'Salary higher than average',
  apply_for_job: 'Apply for job',
  apply_for_this_job: 'apply for this job',
   // Section: Why do people like working with us
  why_work_with_us: 'Why we?',
  why_work_with_us_1: 'Humanicl - it is an international platform',
  why_work_with_us_2: 'We are guarantee equal opportunities to our partners',
  why_work_with_us_3: 'We are legal.',
  why_work_with_us_4: 'You need only one contract with us to work with all of our partners',
   // Section: Testimonials
  testimonials: 'testimonials',
  // Features
  features: 'Features',
  single_contact: 'A single contract with a platform for working with all customers',
  automated_interview: 'Automated interview management system',
  automated_system: 'Automated system for checking hours worked',
  automatic_generation: 'Automatic generation of working documents',
  built_in_messaging: 'Built-in messaging system between the customer and the specialist',
  depersonalized_cv: 'Depersonalized CVs of specialists available for download with your logo',
  // Advantages
  advantages: 'Advantages',
  top_skills: 'Top skills',
  top_skills_subtitle: '100+ most popular skills',
  no_risk: 'No risk',
  no_risk_subtitle: 'Get payed every 2 weeks after you start working on a project',
  no_risk_subtitle_2: 'Work without risks — we guarantee the payment',
  engagement: 'engagement',
  engagement_subtitle: 'High engagement of specialists in the project',
  engagement_subtitle_2: 'Your experts can work in projects that they like',
  cost_management: 'cost management',
  cost_management_subtitle: 'Best rates from Eastern Europe and Middle Asia',
  extra_income: 'extra income',
  extra_income_subtitle: 'Our rates are hihger than in your local area',
  international_market: 'international market',
  international_market_subtitle: 'Opportunity to work with costumers from all world ',
  // Workflow
  workflow: 'How it works',
  work_cycle: 'work cycle',
  project: 'project',
  post_your_project: 'request the specialist',
  choose_your_project: 'Choose the project',
  choose_your_project_subtitle: 'Choosing a project that matches your skills',
  project_interview: 'Project interview',
  project_interview_subtitle: 'Communication with the customer to understand that you can handle the work',
  post_your_project_subtitle: 'Tell us what you need and we\'ll prepare vetted CV\'s of our specialists for you',
  post_your_project_subtitle_2: 'Сhoose the project that matches skills of your your employees',
  interview: 'interview',
  interview_subtitle: 'Schedule interviews for the candidates who responded',
  interview_subtitle_2: 'Customer schedule interviews for the candidates who responded',
  completion_of_the_project: 'completion of the project',
  completion_of_the_project_subtitle: 'If you enjoyed working with us you can post other projects',
  completion_of_the_project_subtitle_2: 'After your specialist finish working on the project, select the next project',
  completion_of_the_project_subtitle_3: 'After you finish working on the project, select the next project',
  work_on_project: 'work on project',
  work_on_project_subtitle: 'The specialist work under your management',
  work_on_project_subtitle_2: 'Your specialist works under the direction of the customer',
  work_on_project_subtitle_3: 'You work remotely under the direction of the customer',
  careers_at_humanicl: 'Careers at humanicl',
  privacy_policy: 'Privacy Policy',
  terms_of_use: 'Terms and Conditions',
  privacy_policy_content: `
    <h2>Privacy Policy</h2>
    <p>
      This Privacy Policy explains how Humanicl, LLC (“Service”, or “we”, “us” or “our”) collects, uses and
      shares your personal information when you visit and access the Humanicl web site. References in the
      Privacy Policy to the Humanicl, along with information and services made available to you through the
      Humanicl are considered the “Services.”
      Humanicl’s mission is to help Customers to find IT contractors from Eastern Europe and Middle Asia. And from other side - help our Contractors to find work on International market.  
    </p>
    By their very nature, some of the Services will need to collect “private” information. Accordingly, we take special care
    to hold this personal information in the strictest confidence and protect it from unauthorized access,
    disclosure or use by Humanicl or third parties. We are committed to full transparency in how we collect,
    use and share your personal information. If you have any questions or concerns about your personal
    information or this Privacy Policy, email us at info@humanicl.com.
    This Privacy Policy applies to personal information and other information we collect:

    <li>On the web site - <a href="https://humanicl.com/" target="_blank">https://humanicl.com/</a>.</li>
    <li>In emails, text, and other electronic messages between you and Humanicl.</li>
    <li>By your access to and use of the Services offered on the web site - <a href="https://humanicl.com/" target="_blank">https://humanicl.com/</a>.</li>

    Please read this Privacy Policy carefully to understand our policies and practices regarding your personal
    information and how we will treat it. By accessing or using the Services, you agree to the terms and
    conditions described in the Privacy Policy. If you do not agree with these terms and conditions, your choice
    is not to access and use our Services. By accessing or using the Services, you agree to the terms and
    conditions described in the Privacy Policy. Please also refer to our Terms & Conditions regarding the terms
    of use for the operation of the web site, into which this Privacy Policy is incorporated.
    All contents of the Humanicl and the Services, such as text, graphics, images, and other material and
    information contained on the web site - <a href="https://humanicl.com/" target="_blank">https://humanicl.com/</a> 
    are for engaging contractors and contractors hereby agrees to be engaged by the Customers to develop the software.
    
    <h3>Information Collected About You</h3>
    To access some of our Services it is not necessary to provide your personal information, while other Services
    require that you register with us, which includes providing certain personal information as described in this
    Privacy Policy. Even if you do not register with or provide any personal information, we collect information
    about your use of the Services. We may also acquire certain information about our users on an anonymous and
    aggregate basis from external sources to improve the functionality of the Service and the enhance the user
    experience.

    <h3>Information we collect automatically</h3> 
    Our servers may automatically record certain information about how
    you when you access or use certain Services. This information may include your Internet Protocol (IP)
    address, device and browser type, operating system, the pages or particular Service that you viewed and 
    the time spent on those pages or features or Service, the frequency with which you use access the Service,
    search terms, the links that you click on or use, and other statistics. We collect this information in server
    logs and by using cookies and similar tracking technologies to analyze trends, administer Services, track
    users’ movements across the Services, and gather demographic information about our user base as a whole.
    Information you provide voluntarily. In order to access certain portions of the Services, it is necessary that
    you register with Humanicl. Registering requires that you provide certain personal information, including
    name, email address, telephone number and country of location. You can always terminate your
    registration at any time and for any reason.
    The Service don’t request you to provide “sensitive” or “special categories” of personal information, such
    as health-related information (physical and mental). At any point where this type of information is needed
    for the requested Service, you will be advised of this need and asked to provide your explicit consent.
    Passwords. When you register to access the Services, you will be asked to provide a unique log-in ID and
    password. You are responsible for taking reasonable precautions to protect your login data. You should
    not share your password with anyone. Humanicl cannot be held responsible for breach that results from
    a lost or stolen login or password information.

    <h3>Interactive Services</h3> 
    Some of the available Services are “Interactive” Services, meaning that you may
    interact in real time with our managers, who may ask you to provide certain additional personal information,
    which you are free to decline to do so. Any information disclosed during these “Interactive” Services is
    held in the strictest confidence. These professionals are bound by ethical requirements to maintain the
    confidentiality of this information. To appear on the Services, these professionals also agree to abide by
    this Privacy Policy and to maintain the confidentiality of any user information they may be provided.
    Any information (including personal information) you share in any online message board or other chats
    is by its nature open to the public and is not private. Please carefully consider before posting any personal
    information in any public chats. If you mistakenly post personal information in our message board or
    other public chats and would like it removed, you can send us an email to info@humanicl.com request
    that we remove it; however, in some cases, be advised that we may not be able to remove your personal
    information.

    <h3>How Information Collected About You Is Used</h3>
    Information about your use of the Services may be used for the following purposes:

    <li>To provide, improve and create new Services.</li>
    <li>To respond to your inquiries and to send you administrative communications about your registration, your activity using the Services.</li>
    <li>To obtain your feedback about your experience using the Services.</li>
    <li>To advise you regarding new services, offers or promotions that may be of interest to you based on your use of the Services, including news, announcements, reminders and opportunities from Humanicl.</li>
    <li>To statistically analyze trends and user behavior and activity including how frequently areas of the Services are visited, how the Services are being used and how many messages are received and opened.</li>
    <li>To protect our legitimate business interests.</li>
    <li>To detect and defend against fraud and other threats to the Services and our users.</li>
    <li>To identify and resolve problems with the Services.</li>
    <li>To conduct research and measurement activities, including those described below.</li>
    <li>To administer your registration.</li>

    We may also use your personal information, such as an email address you provided, to send you offers or
    promotions about other goods and services from us that may be of interest to you. If you do not want us to
    use your personal information in this way, you can opt out at any time and at no cost. How to opt out is
    described in the section below, “Your Choices and Rights.” Be advised that you cannot opt out of
    transactional or administrative emails, such regarding your registration status or Services you have accessed
    or requested. We do not provide any personal information to third parties for their marketing
    purposes.

    <h3>How We Share Your Information</h3>
    We may disclose personal information that we collect or you provide as described in this Privacy Policy:
   
    <li>To fulfill the purpose for which you provided your personal information.</li>
    <li>To our subsidiaries and affiliates.</li>
    <li>To contractors, vendors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.</li>
    <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Humanicl’s assets, including the lists of our users.</li>
    <li>For any other purpose disclosed by us when you provide your personal information.</li>
    <li>With your consent.</li>
    However, we reserve the right to disclose aggregated or anonymous information (i.e., information that does
    not identify you) about our users without restriction.
    We do not disclosure your email to any third party for their marketing purposes.
    We may also disclose your personal information:

    <li>To enter into a contract.</li>
    <li>Comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
    <li>To enforce or apply our End User License Agreement and other agreements, including for billing and collection purposes.</li>
    <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Humanicl, our users, or others. This may include exchanging information with other companies and
    organizations for the purposes of fraud protection and credit risk reduction.
    We may also include social widgets, which enable you to interact with the associated social media platforms.
    These widgets may collect browsing data which may be received by the third party that provided the widget and
    are controlled by these third parties. You may be able to manage your privacy preferences directly with the
    applicable social network platform.

    <h3>How Your Information is Secured and Retained</h3>
    We have implemented reasonable and appropriate technical, physical and administrative measuresto protect the
    security of your personal information on our servers and systems. Be advised, however, that the transmission
    of information via the internet or wireless communications technologies is not completely secure. Although 
    we do our best to protect your personal information when you enter your personal information on the
    Service, for example by encrypting the transmission of personal information or using SSL connections
    (SSL) technology, we nonetheless cannot guarantee the security of your personal information transmitted
    to the Services. Any transmission of personal information is at your own risk. We are not responsible for
    circumvention of any privacy settings or security measures contained on the Services.
    We will retain your personal information as long as your registration is active or as needed to provide you the
    requested Services. When you terminate your registration, we will take steps to remove your personal
    information, but be aware that it is not technologically possible to remove each and every record of the
    information you have provided from our servers. In addition, see the section, “Your Choices and Rights”, below
    for more information on how you can update or remove your personal information. We will also retain your
    personal information as necessary to comply with legal obligations, resolve disputes, prevent fraud and enforce
    our agreements.

    <h3>Your Choices and Rights</h3>
    You can correct, update or review personal information you have previously submitted by going back to the
    Service, logging-in with your registration information and making the desired change. You can also update any
    personal information you have submitted by contacting us at info@humanicl.com.
    If you have registered and desire to delete any of your registration information you have provided to us from our
    systems, please contact us also at info@humanicl.com. Upon your request, we will delete your registration
    information from our active databases and where feasible from our back-up backup database. You should be
    aware that it is not technologically possible to remove each and every record of the information you have
    provided to us from our servers.
    When you sign up to receive email communications from us, you can choose to opt out from receiving them at
    any time. You can unsubscribe from our emails by:

    <li>Following the directions included at the bottom of the email by clicking on the “Unsubscribe” link.</li>
    <li>Sending us an email at info@humanicl.com requesting that you be removed from our communications.</li>

    Be advised, however, that you cannot opt out of transactional or administrative emails, such regarding your
    registration status or Services you have accessed or requested.
    
    <h3>Links to Third Parties</h3>
    The Humanicl web site may contain links to other web sites and services. We do not control such thirdparty web sites and services and are not responsible for their actions or privacy practices. The existence of
    a link does not mean that we endorse or have vetted such third-party website or service. You are encouraged
    to carefully review the policies and practices of every website and service that you visit or use.
    For Users Outside the Russia
    Humanicl is located in the Russian Federation and the Services are maintained in the Russian Federation. Your
    personal and other information will be stored, processed and accessed on servers located in the Russian Federation. If you are located outside the Russian Federation, you understand and agree that Humanicl may transfer,
    collect, use, disclose, and otherwise process the personal information you provide in the Russian Federation as
    described in this Privacy Policy. We also may transfer your information outside the Russian Federation to service
    providers under contract with us with operations in other countries. By using the Services, you consent to such
    collection, storage and processing in the Russian Federation and elsewhere, though the Russian Federation and other 
    jurisdictions may not afford the same level of data protection as considered adequate in your own country. We
    will take reasonable steps to protect your personal information. Note that your personal information may be
    available to the Russian Federation government or its agencies under legal process made in the Russian Federation.
   
    <h3>Children's Privacy</h3>
    We are committed to protecting the privacy of children. You should be aware that the Humanicl are not
    intended or designed to attract children under the age of 13. We do not knowingly collect personally identifiable
    information from any person we actually know is a child under the age of 13. If a child under the age of 13
    has provided Humanicl with their personal information, we ask that their parent or guardian contact us
    as provided below to inform us of that fact. We will use reasonable efforts to delete such information from
    our systems and servers.
    
    <h3>Changes to This Privacy Policy</h3>
    The Effective Date of the current version of the Privacy Policy is provided above. From time to time we
    may modify this Privacy Policy. We will notify you of any material change by sending a notice to the email address you provided to us or by placing a prominent notice on the website. You can view the most
    recent version of the Privacy Policy at any time by clicking the “Privacy Policy” link provided on the web
    site - <a href="https://humanicl.com/" target="_blank">https://humanicl.com/</a>. Your continued use of the Services after any modification indicates your
    agreement to the new terms and conditions.

    <h3>Comments and Questions</h3>
    <p>
      If you have comments or questions about our Privacy Policy or your personal information, you may email
      us at info@humanicl.com.
    </p>
    <p>
      Humanicl LLC
      <br>TIN 4345511388
    </p>
  `,
  terms_of_use_content: `
    <h2>Terms and Conditions</h2>
    
    <h3>Introduction</h3>
    These Website Standard Terms and Conditions written on this webpage shall manage your use of our
    website, humanicl.com accessible at <a href="https://humanicl.com/" target="_blank">https://humanicl.com/</a>
    These Terms will be applied fully and affect to your use of this Website. By using this Website, you
    agreed to accept all terms and conditions written in here. You must not use this Website if you disagree
    with any of these Website Standard Terms and Conditions.
    Minors or people below 18 years old are not allowed to use this Website.
    
    <h3>Intellectual Property Rights</h3>
    Other than the content you own, under these Terms, Humanicl LLC and/or its licensors own all the
    intellectual property rights and materials contained in this Website.
    You are granted limited license only for purposes of viewing the material contained on this Website.
    
    <h3>Restrictions</h3>
    You are specifically restricted from all of the following:
    <li>publishing any Website material in any other media;</li>
    <li>selling, sublicensing and/or otherwise commercializing any Website material;</li>
    <li>publicly performing and/or showing any Website material;</li>
    <li>using this Website in any way that is or may be damaging to this Website;</li>
    <li>using this Website in any way that impacts user access to this Website;</li>
    <li>using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;</li>
    <li>engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;</li>
    <li>using this Website to engage in any advertising or marketing.</li>
    Certain areas of this Website are restricted from being access by you and Humanicl LLC may further
    restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and
    password you may have for this Website are confidential and you must maintain confidentiality as well.
   
    <h3>Your Content</h3>
    In these Website Standard Terms and Conditions, "Your Content" shall mean any audio, video text,
    images or other material you choose to display on this Website. By displaying Your Content, you grant
    Humanicl LLC a non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt,
    publish, translate and distribute it in any and all media.
    Your Content must be your own and must not be invading any third-party’s rights. Humanicl LLC
    reserves the right to remove any of Your Content from this Website at any time without notice.

    <h3>Your Privacy</h3>
    Please read Privacy Policy.
  
    <h3>No warranties</h3>
    This Website is provided "as is," with all faults, and Humanicl LLC express no representations or
    warranties, of any kind related to this Website or the materials contained on this Website. Also, nothing
    contained on this Website shall be interpreted as advising you.
   
    <h3>Limitation of liability</h3>
    In no event shall Humanicl LLC, nor any of its officers, directors and employees, shall be held liable
    for anything arising out of or in any way connected with your use of this Website whether such liability is
    under contract. Humanicl LLC, including its officers, directors and employees shall not be held liable
    for any indirect, consequential or special liability arising out of or in any way related to your use of this
    Website.
  
    <h3>Indemnification</h3>
    You hereby indemnify to the fullest extent Humanicl LLC from and against any and/or all liabilities,
    costs, demands, causes of action, damages and expenses arising in any way related to your breach of any
    of the provisions of these Terms.
   
    <h3>Severability</h3>
    If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be
    deleted without affecting the remaining provisions herein.
    Variation of Terms
    Humanicl LLC is permitted to revise these Terms at any time as it sees fit, and by using this Website
    you are expected to review these Terms on a regular basis.
   
    <h3>Assignment</h3>
    The Humanicl LLC is allowed to assign, transfer, and subcontract its rights and/or obligations under
    these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract
    any of your rights and/or obligations under these Terms.
   
    <h3>Entire Agreement</h3>
    These Terms constitute the entire agreement between Humanicl LLC and you in relation to your use of
    this Website, and supersede all prior agreements and understandings.
   
    <h3>Governing Law & Jurisdiction</h3>
    <p>
      These Terms will be governed by and interpreted in accordance with the laws of the Russian Federation,
      and you submit to the non-exclusive jurisdiction of the state and federal courts located in us for the
      resolution of any disputes.
    </p>
  `,
  // SignIn
  sign_in: 'Sign in',
  sign_up: 'Sign up',
  dont_have_account: 'Don\'t have an account?',
  already_signed_up: 'Already Signed up?',
  sign_in_to_account: 'Sign in to Account',
  forgot_password: 'Forgot Password?',
  fill_up_personal_info: 'Fill up personal information and start journey with us.',
  if_you_already_have_account: 'If you already have an account sign in.<br>We\'ve missed you!',
  email_address: 'Email address',
  password: 'Password',
  sign_up_for_an_account: 'Sign Up for An Account',
  choose_type_of_account_you_need: 'Choose type of account you need',
  i_am_a_client: 'For Сlients',
  i_need_specialists: 'I need specialists',
  we_are_a_team: 'For Contractors',
  we_have_specialists: 'Single or team',
  create_password: 'Create password',
  i_agree_to: 'I agree to',
  terms_and_conditions: 'terms & conditions',
  remember_me: 'Remember me',
  enter_email: 'Enter email',
  enter_password: 'Enter password',
  email_is_required: 'Email is required',
  enter_valid_email: 'Enter a valid email address',
  password_is_required: 'Password is required',
  choose_stronger_password: 'Choose a stronger (longer) password',
  have_to_agree_to_terms: 'You have to agree to terms & conditions to sign up',
  // About us
  about_us: 'About us',
  about_us_content: `
    <li>
      We connect employees of different local IT companies from Eastern Europe and Middle Asia (Russia, Ukraine, Belarus and other CIS countries) with businesses in all over the world using the outstaffing model.
    </li>
    <li>
      This model of cooperation allows you to minimize risks and get resources for customers at optimal prices, and will allow performers to work on the international market.
    </li>
    <li>
      We have two own inhouse teams in different offices of development and big net of local partners with their own inhouse teams.
    </li>
    <li>
      To work with the platform, one contract is enough - both for performers and for customers.
    </li>
  `,
  // Landing-view
  landing_view_phone_number: '+7 499 322 98 09',
  // Landing-footer
  landing_view_company_address: '',
   // Title
  landing_view_page_main_title: '<span class="text--highlight" text>B2B platform</span> for quick hiring vetted IT professionals from around the world',
  landing_view_page_main_subtitle: 'We connect employees of different IT companies with businesses using the outstaffing model',
  landing_view_equal_opportunities: '',
  landing_view_equal_opportunities_description: '',
  landing_view_equal_opportunities_1: '',
  landing_view_equal_opportunities_2: '',
  landing_view_equal_opportunities_3: '',
  landing_view_equal_opportunities_4: '',
  landing_view_apply_for_job: '',
  landing_view_apply_for_this_job: '',
  // Section: Why do people like working with us
  landing_view_why_work_with_us: '',
  landing_view_why_work_with_us_1: '',
  landing_view_why_work_with_us_2: '',
  landing_view_why_work_with_us_3: '',
  landing_view_why_work_with_us_4: '',
  // Section: Testimonials
  landing_view_testimonials: '',
  // Features
  landing_view_features: '',
  landing_view_single_contact: '',
  landing_view_automated_interview: '',
  landing_view_automated_system: '',
  landing_view_automatic_generation: '',
  landing_view_built_in_messaging: '',
  landing_view_depersonalized_cv: '',
  // Workflow
  landing_view_workflow: '',
  landing_view_work_cycle: '',
  landing_view_choose_your_project: '',
  landing_view_choose_your_project_subtitle: '',
  landing_view_project_interview: '',
  landing_view_project_interview_subtitle: '',
  landing_view_work_on_project: '',
  landing_view_work_on_project_subtitle_3: '',  
  landing_view_completion_of_the_project: '',
  landing_view_completion_of_the_project_subtitle_3: '',
  // Profile
  profile: 'profile',
  my_profile: 'my profile',
  specialists: 'specialists',
  add_specialist: 'add specialist',
  my_specialists: 'my specialists',
  my_projects: 'my projects',
  responses: 'responses',
  applications: 'applications',
  acts: 'acts',
  reports: 'reports',
  invoices: 'invoices',
  grade: 'grade',
  nameRole: 'name / role',
  format: 'format',
  actions: 'actions',
  devInternalAffiliation: 'свой',
  devExternalAffiliation: 'чужой',
  // Admin
  vacancies: 'vacancies',
  add_vacancy: 'add vacancy',
  new_vacancy: 'new vacancy',
  denomination: 'description',
  // Vacancy
  vacancy_label_role: 'role*',
  vacancy_label_specialist_grade: 'specialist grade*',
  vacancy_label_specialist_amount: 'specialist amount*',
  vacancy_label_english_level: 'english level*',
  vacancy_label_job_type: 'job type*',
  vacancy_label_specialist_min_requirements: 'minimum requirements',
  vacancy_label_project_description: 'about project',
  vacancy_label_location: 'location (leave blank if location is not important)',
  vacancy_label_hourly_rate: 'hourly rate, max $',
  vacancy_label_customer: 'customer*',
  vacancy_label_skills: 'skills',
  vacancy_field_project_description: 'Short project description',
  vacancy_field_role: 'specify the role in international format, for example: Fronted developer',
  vacancy_field_select: 'select',
  vacancy_field_specialist_amount: 'specify the number of specialists needed',
  vacancy_field_specialist_min_requirements: 'describe specialist minimum requirements',
  vacancy_field_hourly_rate: 'specify hourly rate',
  vacancy_field_skills: 'specify key skills in international format, for example: HTML, CSS, JS',
  // Dialogs
  vacancy_editor_prompt_title: 'Close vacancy editor?',
}

export {
  description,
  data
}
