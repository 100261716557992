<template>
  <div class="control-panel">
    <div class="control-panel__left-panel"></div>
    <div class="control-panel__right-panel"></div>
    <div class="control-panel__inner">
      <slot name="side-menu"></slot>
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'ControlPanel',
  data() {
    return {
      tables: {
        specialists: {
          filterData: []
        }  
      }
    }
  },
  mounted () {
    this.fetchTablesSpecialistsFilterData()
  },
  watch: {
    selectedLanguage () {
      this.fetchTablesSpecialistsFilterData()
    }
  },
  computed: {
    ...mapGetters([
      'profileTopSideMenuItemsLocalized',
      'profileBottomSideMenuItemsLocalized',
    ]),
    profile () {
      return this.$store.state.profile
    },
    selectedLanguage () {
      return this.$store.state.storageData.settings.localization.selectedLanguage
    },
    profileSideMenuActiveItem: {
      get () {
        return this.$store.state.views.profile.sideMenu.activeItem
      },
      set (value) {
        this.$store.state.views.profile.sideMenu.activeItem = value
      }
    },
    profileSpecialistsTableDataGrades () {
      return [...new Set(this.profileSpecialistsTableData.map(item => item.grade))]
    },
    profileSpecialistsTableDataStatuses () {
      return [...new Set(this.profileSpecialistsTableData.map(item => item.status.text))]
    },
    profileSpecialistsTableData () {
      let specialists = JSON.parse(JSON.stringify(this.profile.specialists))
      let data = []
      specialists.forEach((item) => {
        let dataObject = {
          id: item.id,
          description: {
            name: item.name,
            role: item.role,
          },
          format: item.format,
          grade: item.grade,
          status: item.status,
          actions: {
            ...item.actions,
            ...{
              delete: true,
            }
          }
        }
        data.push(dataObject)
      })
      return data
    },
    profileSpecialistsTableHeaderData () {
      return [
        {
          prop: 'id',
          title: '№',
        },
        {
          prop: 'description',
          title: this.$localize.get('nameRole'),
        },
        {
          prop: 'format',
          title: this.$localize.get('format'),
        },
        {
          prop: 'grade',
          title: this.$localize.get('grade'),
        },
        {
          prop: 'status',
          title: this.$localize.get('status'),
        },
        {
          prop: 'actions',
          title: this.$localize.get('actions'),
        },
      ]
    },
  },
  methods: {
    fetchTablesSpecialistsFilterData () {
      this.tables.specialists.filterData = [
        {
          prop: 'grade',
          title: this.$localize.get('grade'),
          multiple: true,
          type: true,
          items: this.profileSpecialistsTableDataGrades,
          selectedItems: this.profileSpecialistsTableDataGrades,
        },
        {
          prop: 'status',
          title: this.$localize.get('status'),
          multiple: true,
          type: true,
          items: this.profileSpecialistsTableDataStatuses,
          selectedItems: this.profileSpecialistsTableDataStatuses,
        },
        {
          title: this.$localize.get('sort_by'),
          prop: 'sortType',
          multiple: false,
          items: this.profileSpecialistsTableHeaderData,
          selectedItems: this.profileSpecialistsTableHeaderData[0],
          sortDirection: 'asc'
        },
      ]
    },
    setFilterData (params) {
      this.tables.specialists.filterData = params
    }
  },
}
</script>

<style lang="scss">
.control-panel {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__left-panel {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #e5f0ff;
    z-index: 0;
  }

  &__right-panel {
    position: absolute;
    top: 0;
    left: 50vw;
    height: 100%;
    width: 100%;
    background-color: var(--light-blue-bg-color);
    z-index: 0;
  }

  .control-panel__inner {
    display: flex;
    align-items: flex-start;
    width: var(--main-view-section-width);
    max-width: var(--max-content-width);
    height: 100%;
    margin: 0 auto;
    background-color: #e5f0ff;
    z-index: 1;
    @media (max-width: $xs) {
      width: var(--main-view-section-width-xs);
    }
  }

  .side-menu {
    min-width: 230px;
    padding-top: 142px;
    padding-right: 24px;

    &__top {
      margin-bottom: 64px;
    }

    &__item {
      margin-bottom: 16px;
      padding: 4px 0;
      padding-left: 16px;
      color: var(--black-1);
      font-family: 'Proxima Nova Lt';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      &[is-active] {
        color: var(--main-color-1);
        padding-left: 13px;
        border-left: 3px solid var(--main-color-1);
      }
    }

    &__support-button {
      margin-top: 64px;
    }
  }

  .content {
    width: 100%;
    height: 100%;
    padding: 36px 82px;
    padding-right: 0;
    background-color: var(--light-blue-bg-color);

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 48px;
      
      &__title {
        color: var(--main-color-1);
        font-family: 'Proxima Nova';
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
      }
    }
  }
}
</style>