import store from '../store'
import router from '../router'

export function get (key, options = {}) {
  // If no locale provided, set user selected locale
  const selectedLocale = store.state.storageData.settings.localization.selectedLanguage.locale
  const { 
    locale = options.locale ?? selectedLocale, 
    capitalize = options.capitalize ?? true
  } = options

  const englishData = require('../localization/en.js').data
  let selectedLanguageData 
  try {
    selectedLanguageData = require(`../localization/${locale}`).data
  }
  catch (error) {
    console.warn(`Selected locale file is not available: ${locale}.js`)
    selectedLanguageData = englishData
  }
  key = modifyKey(key, selectedLanguageData)
  // If property doesn't exist in choosen language, but exists in English, return English translation
  if (selectedLanguageData[key] === undefined && englishData[key] !== undefined) {
    return capitalize
      ? englishData[key].replace(/^\p{CWU}/u, char => char.toLocaleUpperCase(locale))
      : englishData[key]
  }
  // If property doesn't exist in choosen language nor in English, return requested property string
  if (englishData[key] === undefined && englishData[key] === undefined) {
    return key
  }
  // If translation exists in choosen language, return translation
  else {
    return capitalize 
      ? selectedLanguageData[key].replace(/^\p{CWU}/u, char => char.toLocaleUpperCase(locale))
      : selectedLanguageData[key]
  }
}

function modifyKey (key, selectedLanguageData) {
  // Modify key depending on the current view
  if (router.history.current.name === 'Landing') {
    const modifiedKey = `landing_view_${key}`
    if (selectedLanguageData[modifiedKey]) {
      return modifiedKey
    }
    else {
      return key
    }
  }
  else {
    return key
  }
}
