import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import utils from './utils/utils'

import MainToolbar from './components/MainToolbar.vue'
import MainFooter from './components/MainFooter.vue'
import Dialogs from './components/Dialogs.vue'
import HButton from './components/HButton.vue'
import Pagination from './components/Pagination.vue'
import Card from './components/Card.vue'
import ContentSection from './components/ContentSection.vue'
import MediaSlider from './components/MediaSlider.vue'
import TabsSection from './components/TabsSection.vue'
import DropdownMenu from './components/DropdownMenu.vue'
import MainDrawer from './components/MainDrawer.vue'
import Drawer from './components/Drawer.vue'
import DataTable from './components/DataTable.vue'
import DataTableItem from './components/DataTableItem.vue'
import DataTableFilter from './components/DataTableFilter.vue'
import DataTableHeader from './components/DataTableHeader.vue'
import DataTableFooter from './components/DataTableFooter.vue'
import ControlPanel from './components/ControlPanel.vue'

import '@mdi/font/css/materialdesignicons.min.css'
import vClickOutside from 'v-click-outside'

const localize = require('./utils/localize')
const axios = require('axios')

Vue.prototype.$localize = localize
Vue.prototype.$utils = utils

Vue.use(vClickOutside)

// Register components
Vue.component('main-toolbar', MainToolbar)
Vue.component('main-footer', MainFooter)
Vue.component('dialogs', Dialogs)
Vue.component('h-button', HButton)
Vue.component('pagination', Pagination)
Vue.component('card', Card)
Vue.component('content-section', ContentSection)
Vue.component('media-slider', MediaSlider)
Vue.component('tabs-section', TabsSection)
Vue.component('dropdown-menu', DropdownMenu)
Vue.component('main-drawer', MainDrawer)
Vue.component('drawer', Drawer)
Vue.component('data-table', DataTable)
Vue.component('data-table-item', DataTableItem)
Vue.component('data-table-filter', DataTableFilter)
Vue.component('data-table-header', DataTableHeader)
Vue.component('data-table-footer', DataTableFooter)
Vue.component('control-panel', ControlPanel)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  created () {
    const token = localStorage.getItem('user-token')
    axios.defaults.baseURL = 'http://humacl.x-or.ru'
    if (token) {
      this.$store.dispatch('AUTO_SIGN_IN', token)
    }

    router.beforeEach((to, from, next) => {
      if (to.matched.some((route) => route.meta.requiresAuth)) {
        if (!store.state.user.loggedIn) {
          next('/sign-in')
        }
        else {
          next()
        }
      } 
      else {
        next()
      }
    })
  }
}).$mount('#app')
