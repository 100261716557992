<template>
  <div class="main-drawer" :show="mainDrawerValue" v-click-outside="onClickOutside">  
    <div class="main-drawer__inner">
      <div class="main-drawer__inner__toolbar">
        <dropdown-menu
          :items="availableLanguages"
          v-model="selectedLanguage"
          item-text="name"
        >
        </dropdown-menu>
        <div @click="mainDrawerValue = false">
          <i class="mdi mdi-close" style="font-size: 32px"></i>
        </div>
      </div>
      <div class="main-drawer__inner__content">
        <div 
          id="nav"
          class="main-drawer__inner__content__nav-container"
        >
          <!-- landing-view: section anchors -->
          <template v-if="$route.name === 'Landing'">
            <div 
              class="main-drawer__inner__content__nav-container__item"
              v-for="(item, index) in landingPageAnchorItems"
              @click="$utils.scrollIntoView(item.nodeSelector)"
              :key="'item-' + index"
            >
              <a>
                {{$localize.get(item.name)}}
              </a>
            </div>
          </template>
          
          <!-- nav-items -->
          <template v-if="$route.name !== 'Landing'">
            <div 
              class="main-drawer__inner__content__nav-container__item fade-in"
              v-for="(navItem, index) in navItemsLocalized"
              :key="'nav-item' + index"
            >
              <div
                v-if="navItem.onClick || navItem.onClickAction"
              >{{navItem.text}}
              </div>
              
              <a
                v-else-if="navItem.link"
                :href="navItem.link"
                target="_blank"
              >{{navItem.text}}
              </a>
              
              <router-link
                v-else 
                :to="navItem.to"
              >
                <div @click="mainDrawerValue = false">
                  {{navItem.text}}
                </div>
              </router-link>
            </div>
          </template>
          <div
            class="
              main-drawer__inner__content__nav-container__item
              main-drawer__inner__content__nav-container__action-items
            "
          >
            <h-button
            class="main-drawer__inner__content__nav-container__action-items__item"
            v-if="!user.loggedIn"
            @click.native="loginBtn()"
            outlined blue x-small-padding
          >
            {{$localize.get('join_platform')}}
          </h-button>
          <h-button
            class="main-drawer__inner__content__nav-container__action-items__item"
            v-if="user.loggedIn"
            @click.native="profileBtn()"
            outlined blue x-small-padding
          >
            {{$localize.get('my_profile')}}
          </h-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vClickOutside from 'v-click-outside'

export default {
  name: 'MainDrawer',
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
    }
  },
  watch: {
    '$store.state.mainDrawer.value' (value) {
      if (!value) {
        this.$store.state.overlays.window = false
      }
    }
  },
  computed: {
    ...mapGetters([
      'navItemsLocalized',
    ]),
    availableLanguages () {return this.$store.state.storageData.settings.localization.availableLanguages},
    user () {return this.$store.state.user},
    landingPageAnchorItems () {return this.$store.state.landingPageAnchorItems},
    mainDrawerValue: {
      get () {
        return this.$store.state.mainDrawer.value
      },
      set (value) {
        this.$store.state.mainDrawer.value = value
        this.$store.state.overlays.window = value
      }
    },
    selectedLanguage: {
      get () {
        return this.$store.state.storageData.settings.localization.selectedLanguage
      },
      set (value) {
        this.$store.state.storageData.settings.localization.selectedLanguage = value
      }
    }
  },
  methods: {
    onClickOutside (event) {
      const isMenuButtonNode = event.target.parentNode.classList
      if (!isMenuButtonNode) {
        this.$store.state.mainDrawer.value = false
      }
    },
    loginBtn () {
      this.$store.dispatch('OPEN_LOGIN_PAGE')
      this.$store.state.mainDrawer.value = false
    },
    profileBtn () {
      this.$store.dispatch('OPEN_LOGIN_PAGE')
      this.$store.state.mainDrawer.value = false
    },
  }
}
</script>

<style lang="scss">
.main-drawer {
  position: fixed;
  top: 0;
  right: 0;
  min-height: 100vh;
  height: 100%;
  width: 384px;
  max-width: 100vw;
  background-color: #fff;
  z-index: 9;
  transform: translateX(500px);
  transition: all 0.5s;
  @media (max-width: $xs) {
    width: 284px;
  }
  &[show] {
    box-shadow: -16px 16px 64px rgba(0, 0, 0, 0.2);
    transform: translateX(0);
    transition: all 0.5s;
  }
  &__inner {
    &__toolbar {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 48px;
      height: var(--main-toolbar-height);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
      @media (max-width: $xs) {
        height: var(--main-toolbar-height-xs);
        padding: 0 16px;
      }
      @media (max-width: 375px) {
        height: var(--main-toolbar-height-xxs);
      }
      i {
        cursor: pointer;
      }
    }
    &__content {
      height: calc(100vh - var(--main-toolbar-height));
      @media (max-width: $xs) {
        height: calc(100vh - var(--main-toolbar-height-xs));
      }
      padding: 32px 26px;
      overflow-y: auto;
      &__nav-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        font-weight: 600;
        font-size: 24px;
        &__action-items {
          font-size: 20px;
          margin-top: 8px;
        }
        &__item {
          display: flex;
          &:not(:last-child) {
            margin-bottom: 24px;
          }
        }
        a {
          color: #2c3e50;
          text-decoration: none;
          padding: 12px;
          transition: all 0.3s;
          &:hover {
            color: var(--blue-main);
            transition: all 0.3s;
          }
          &.router-link-exact-active {
            position: relative;
            color: var(--blue-main) !important;
          }
          &.router-link-exact-active:after {
            content: "";
            position: absolute;
            bottom: -4px;
            left: 0;
            width: 100%;
            border-bottom: 3px solid var(--blue-main);
          }
        }
      }
    }
  }
}
.v-application .main-drawer__inner__content__nav-container a {
  color: #2c3e50;
   &:hover {
    color: var(--blue-main);
    transition: all 0.3s;
  }
} 
</style>