<template>
  <div 
    class="card"
    :class="{
      'card--selector': selector
    }"
    :selector="selector"
    :is-active="isActive"
  >
    <slot></slot>    
    <div class="card--selector__inner" v-if="selector">
      <div class="card--selector__preview">
        <slot name="preview"></slot>    
      </div>
      <div class="card--selector__content">
        <div class="card--selector__title">
          <slot name="title"></slot>    
        </div>
        <div class="card--selector__subtitle">
          <slot name="subtitle"></slot>    
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    selector: Boolean,
    isActive: Boolean
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss">
.card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 12px 60px;
  background: #FFFFFF;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  @media (max-width: $xs) {
    padding: 12px 30px;
  }
  &[feature-card] {
    height: 230px;
    transition: background-color 0.2s;
    @media (max-width: $xs) {
      height: 194px;
    }
    &:hover {
      color: #fff;
      background-color: var(--blue-main);
      transition: background-color 0.2s;
      .card__preview {
        img {
          filter: brightness(100)
        }
      }
    }
  }
  &__preview {
    img {
      width: 86px;
      height: 86px;
      margin-bottom: 16px;
      @media (max-width: $xs) {
        width: auto;
        height: 50px;
      }
    }
  }
  &__sub-title {
    font-size: 18px;
    line-height: 161%;
    text-align: center;
    letter-spacing: 0.04em;
    @media (max-width: $xs) {
      font-size: 14px;
      line-height: 24px;
    }
  }
  &--selector {
    height: 64px;
    width: auto;
    min-width: 234px;
    padding: 0;
    border-radius: 6px;
    cursor: pointer;
    user-select: none;
    box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.06);
    @media (max-width: $sm) {
      min-width: unset;
    }
    &__inner {
      display: grid;
      grid-template-columns: 64px 1fr;
      align-items: center;
      justify-content: center;
      gap: 20px;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      overflow: hidden;
      @media (max-width: $sm) {
        grid-template-columns: 1fr;
      }
    }
    &__preview {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      @media (max-width: $sm) {
        display: none;
      }
    }
    &__content {
      @media (max-width: $sm) {
        padding: 0 16px;
      }
    }
    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
    }
    &__subtitle {
      font-size: 16px;
      line-height: 17px;
      font-style: normal;
      font-weight: normal;
      color: var(--grey-2);
    }
  }
  &--selector[is-active] {
    border: 1px solid var(--blue-main);
    background-color: var(--grey-5);
    box-shadow: 0px 4px 14px 1px rgba(0, 0, 0, 0.04);
    .card--selector__preview {
      background-color: var(--blue-main);
    }
  }
}
</style>