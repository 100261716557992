<template>
  <div class="data-table-fitler">
    <div class="data-table-fitler__header">
      <img src="../assets/icon-filter-grey.svg">
      <div class="data-table-fitler__header__title">
        {{$localize.get('filters')}}
      </div>
    </div>
    <div class="data-table-fitler__content">
      <v-select
        class="filter__item padding-16"
        v-for="(value, index) in filterData"
        :key="'data-table-filter-item-' + index"
        v-model="value.selectedItems"
        :items="filterDataProcessedItems(value)"
        @change="handleChangeEvent(value)"
        :label="$localize.get(value.title)"
        :multiple="value.multiple"
        outlined
        :menu-props="{'offset-y': true}"
        hide-details
        :return-object="value.type === undefined"
        item-text="title"
      >
        <template v-slot:append>
          <img src="../assets/chevron-down-blue.svg">
        </template>
       
        <template 
          v-if="['grade', 'status', 'customer'].includes(value.prop)"
          v-slot:selection="{item: selectionItem, index}"
        >
          <span v-if="index === 0">{{selectionItem}}</span>
          <span
            v-if="index === 1"
            class="grey--text caption ml-1"
          >
            +{{ value.selectedItems.length - 1 }}
          </span>
        </template>
        
        <template 
          v-if="['sortType'].includes(value.prop)"
          v-slot:prepend-item
        >
          <v-list-item @click="handleChangeSortingOrder()">
            <v-icon
              class="mr-3"
            >
              {{ sorting.order === 'asc'
                ? 'mdi-sort-variant'
                : 'mdi-sort-reverse-variant'
              }}
            </v-icon>
            {{$localize.get('sorting_order')}}: {{$localize.get(sorting.order)}}
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DataTableFilter',
  props: {
    filterData: Array,
    sorting: Object,
    changeSorting: Function,
    changeFilterData: Function,
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    filterDataProcessedItems (value) {
      if (value.prop === 'sortType') {
        return value.items.filter(item => item.prop !== 'actions')
      }
      else {
        return value.items
      }
    },
    handleChangeEvent (value) {
      if (['sortType'].includes(value.prop)) {
        this.changeSorting({
          order: this.sorting.order,
          selectedType: value.selectedItems.prop
        })
      }
      else {
        this.changeFilterData(this.filterData)
      }
    },
    handleChangeSortingOrder () {
      this.changeSorting({
        order: this.sorting.order === 'asc'
          ? 'desc'
          : 'asc',
        selectedType: this.sorting.selectedType
      })
    }
  }
}
</script>

<style lang="scss">
.data-table-fitler {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 52px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;

    &__title {
      margin-left: 16px;
      color: var(--grey-4);
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .filter {
    &__item {
      max-width: 182px;
      &:not(:last-child) {
        margin-right: 16px;
      }
      label {
        color: var(--grey-2);
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
      }
      .v-select__selection {
        flex-wrap: nowrap;
        white-space: nowrap;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 90%;
      }
      .v-select__selections {
        flex-wrap: nowrap;
        max-width: 90%;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .v-input.v-text-field.v-select {
    background-color: #fff;
  }
  .v-text-field--outlined.v-text-field--outlined > .v-input__control > .v-input__slot {
    align-items: stretch;
    min-height: 44px !important;
    height: 44px !important;
  }
  .v-text-field .v-input__append-inner {
    margin-top: 19px !important;
  }
  .v-input .v-label {
    line-height: 12px;
  }
  .v-input .v-label.v-label--active {
    line-height: 16px;
  }
  .v-text-field--outlined fieldset {
    border: none
  }
}
</style>