<template>
  <div 
    class="h-button"
    :outlined="outlined"
    :filled="filled"
    :blue="blue"
    :white="white"
    :small="small"
    :block="block"
  >
    <div class="h-button__icon mr-3">
      <slot name="icon-left"></slot>
    </div>
    <slot name="default"></slot>
    <div class="h-button__icon ml-3">
      <slot name="icon-right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    outlined: Boolean,
    filled: Boolean,
    blue: Boolean,
    white: Boolean,
    small: Boolean,
    block: Boolean,
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss">
.h-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  font-family: 'Proxima Nova Lt';
  padding: 0 48px;
  height: 48px;
  max-width: fit-content;
  cursor: pointer;
  border-radius: 4px;
  flex: 0 0 auto;
  outline: 0;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  &[uppercase] {
    text-transform: uppercase;
  }
  &[block] {
    display: flex;
    max-width: unset;
  }
  &[link] {
    height: unset;
    padding: 0;
    display: inline;
  }
  &[no-padding] {
    padding: 0;
  }
  &__content {
    align-items: center;
    color: inherit;
    display: flex;
    flex: 1 0 auto;
    justify-content: inherit;
    line-height: normal;
    position: relative;
  }
  &[green] {
    color: var(--green-main);
  }
  &[blue] {
    color: var(--main-color-1);
  }
  &[white] {
    color: #fff;
  }
  &[small] {
    height: 36px;
  }
  &[large] {
    height: 64px;
    padding: 0 64px;
  }
  &[small-padding] {
    padding: 0 36px;
  }
  &[x-small-padding] {
    padding: 0 16px;
  }
  &[filled] {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
    @media (max-width: $xs) {
      box-shadow: none;
    }
  }
  &[filled][green] {
    color: #fff; 
    background-color: var(--green-main);  
    &:hover {
      background-color: var(--green-main-lighten-1);  
    }
    &:active {
      background-color: var(--green-main-darken-1);  
    }
  }
  &[filled][blue] {
    color: #fff; 
    background-color: var(--main-color-1);  
  }
  &[outlined][blue] {
    border: 2px solid var(--main-color-1);
  }
  &[outlined][green] {
    border: 1px solid var(--green-main);
  }
  &[outlined][white] {
    border: 1px solid #fff;
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>