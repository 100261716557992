<template>
  <div 
    class="data-table-item"
    @click="data.onClick"
    :style="{
      'grid-template-columns': itemStyles.gridTemplateColumns
    }"
  >
    <div 
      v-for="(value, key, index) in data.properties"
      :key="'data-table-item-' + index"
    >
      <div
        class="prop id"
        v-if="key === 'id'"
      >
        {{value}}
      </div>

      <div 
        class="prop description"
        v-if="key === 'description'"
      >
        <div class="description__line-1">{{data.properties.description.name}}</div>
        <div class="description__line-2">{{data.properties.description.role}}</div>
      </div>

      <div 
        class="prop role"
        v-if="key === 'role'"
      >
        <div class="role__line-1">{{data.properties.role.name}}</div>
        <div class="role__line-2">{{data.properties.role.role}}</div>
      </div>

      <div 
        class="prop format"
        v-if="key === 'format'"
      >
        {{value}}
      </div>

      <div 
        class="prop date"
        v-if="key === 'date'"
      >
        {{value}}
      </div>

      <div 
        class="prop grade"
        v-if="key === 'grade'"
      >
        {{value}}
      </div>

      <div 
        class="prop customer"
        v-if="key === 'customer'"
      >
        {{value}}
      </div>

      <div 
        class="prop status"
        v-if="key === 'status'"
        :status="value.value"
      >
        {{value.text}}
      </div>

      <div 
        class="prop actions"
        v-if="key === 'actions'"
      >
        <div 
          v-for="(action, index) in value"
          :key="'action-' + index"
          class="prop"
          @click.stop="action.onClick()"
        >
          <div 
            :class="action.iconClass"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <img 
                  v-on="on"
                  :src="action.icon" 
                >
              </template>
              <span>{{action.tooltipText}}</span>
            </v-tooltip>
          </div>
        </div>
        <!-- <div class="open-icon">
          <img 
            src="../assets/chevron-down-grey.svg" 
            alt="Open icon"
          >
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Datadata',
  props: {
    data: Object,
    itemStyles: Object,
  },
  data () {
    return {
      page: 1,
      itemsPerPageArray: [6, 12, 24, 100],
      itemsPerPage: 6,
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style lang="scss">
.data-table-item {
  display: grid;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  margin-bottom: 16px;
  padding: 0 24px;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  &:hover {
    background-color: #F6F7FB;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.03);
  }
  .prop.id {
    margin-left: 8px;
    margin-right: 8px;
    font-size: 18px;
    color: var(--blue-main);
  }
  .prop.description,
  .prop.role {
    // width: 200px;
    margin-right: 36px;
    .description__line-1,
    .role__line-1 {
      font-size: 18px;
      color: var(--blue-main);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 24px;
    }
  }
  .prop.format {
    // width: 80px;
  }
  .prop.grade {
    // width: 120px;
  }
  .prop.status {
    // width: 120px;
    &[status="available"] {
      color: var(--blue-main);
    }
    &[status="onProject"], &[status="archived"] {
      color: var(--grey-4);
    }
  }
  .prop.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    > div {
      margin-left: 16px;
    }
  }

  .open-icon {
    transform: rotateZ(-90deg);
    img {
      height: 12px;
    }
  }
}
</style>