<template>
  <div class="data-table">
    <data-table-filter 
      :filterData="filterData"
      :sorting="sorting"
      :changeSorting="changeSorting"
      :changeFilterData="changeFilterData"
    ></data-table-filter>

    <data-table-header 
      :headerData="headerData"
      :itemStyles="itemStyles"
    ></data-table-header>
    
    <v-data-iterator
      :items="tableDataSortedFiltered"
      :items-per-page.sync="footerData.itemsPerPage"
      :page.sync="footerData.page"
      hide-default-footer
    >
      <template v-slot:default="props" class="cards">
        <data-table-item 
          v-for="(tableItem, index) in props.items"
          :key="'data-table-item-' + index"
          :data="tableItem"
          :itemStyles="itemStyles"
        ></data-table-item>
      </template>
      <template v-slot:footer>
        <data-table-footer 
          :tableData="tableData"
          :footerData="footerData"
        ></data-table-footer>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
import {sort} from '../utils/sort.js'

export default {
  name: 'DataTable',
  props: {
    filterData: Array,
    headerData: Array,
    tableData: Array,
    itemStyles: Object,
    setFilterData: Function,
  },
  data () {
    return {
      sorting: {
        order: 'asc',
        selectedType: 'id'
      },
      footerData: {
        page: 1,
        itemsPerPageArray: [4, 8, 12, 24],
        itemsPerPage: 4,
      }
    }
  },
  computed: {
    tableDataSorted () {
      return sort({
        items: this.tableData,
        sorting: this.sorting
      })
    },
    tableDataSortedFiltered () {
      let gradePropSelectedItems = this.filterData.find(item => item.prop === 'grade') || []
      let statusPropSelectedItems = this.filterData.find(item => item.prop === 'status') || []
      let customerPropSelectedItems = this.filterData.find(item => item.prop === 'customer') || []
      try {
        let filteredItems = this.tableDataSorted
        if (gradePropSelectedItems.selectedItems) {
          filteredItems = filteredItems.filter(item => gradePropSelectedItems.selectedItems.includes(item.properties.grade))
        }
        if (statusPropSelectedItems.selectedItems) {
          filteredItems = filteredItems.filter(item => statusPropSelectedItems.selectedItems.includes(item.properties.status.text))
        }
        if (customerPropSelectedItems.selectedItems) {
          filteredItems = filteredItems.filter(item => customerPropSelectedItems.selectedItems.includes(item.properties.customer))
        }
        return filteredItems
      }
      catch (error) {
        return this.tableDataSorted
      }
    },
  },
  methods: {
    changeSorting (params) {
      this.sorting = params
    },
    changeFilterData (params) {
      this.setFilterData(params)
    }
  }
}
</script>

<style lang="scss">

</style>