<template>
  <v-app-bar 
    class="main-toolbar" 
    v-if="!['SignIn'].includes($route.name)"
    :view="$route.name"
    app flat 
    :height="$vuetify.breakpoint.smAndDown ? '84px' : '98px'"
  >
    <div 
      class="main-toolbar__inner"
      v-if="['Profile', 'Admin', 'Vacancy'].includes($route.name)"
      :view-name="$route.name"
    >
      <img 
        class="main-toolbar__logo"
        @click="$router.push('/').catch(error => {})"
        src="../assets/logo.png" 
        alt="Humanicl logo"
      >
      <div class="main-toolbar__main-container">
        <div 
          id="nav"
          class="main-toolbar__nav-container"
          :route-name="$route.name"
        >
          <router-link 
            class="main-toolbar__nav-container__item fade-in"
            v-for="(navItem, index) in navProfileItemsLocalized"
            :key="'nav-item' + index"
            :to="navItem.to"
          >
            {{navItem.text}}
          </router-link>
        </div>
          
        <div class="main-toolbar__main-container__actions">
          <dropdown-menu
            :items="availableLanguages"
            v-model="selectedLanguage"
            item-text="name"
          >
            <template v-slot:prepend>
              <i class="mdi mdi-web"></i>
            </template>
          </dropdown-menu>
          
          <div
            class="
              main-toolbar__nav-container__action-items
              fade-in
            "
          >

            <div
              class="
                main-toolbar__nav-container__action-icons
                fade-in
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <img 
                    class="main-toolbar__nav-container__action-icons__item"
                    v-on="on"
                    src="../assets/icon-chat-bubble.svg" 
                    alt="Chat icon"
                  >
                </template>
                <span>{{$localize.get('chat')}}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <img 
                    class="main-toolbar__nav-container__action-icons__item"
                    v-show="unreadNotifications.length > 0"
                    v-on="on"
                    src="../assets/icon-notifications-indicator.svg" 
                    alt="Notifications icon"
                  >
                </template>
                <span>{{$localize.get('notifications')}}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <img 
                    class="main-toolbar__nav-container__action-icons__item"
                    v-show="unreadNotifications.length === 0"
                    v-on="on"
                    src="../assets/icon-notifications.svg" 
                    alt="Notifications icon"
                  >
                </template>
                <span>{{$localize.get('notifications')}}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <img 
                    class="main-toolbar__nav-container__action-icons__item"
                    v-on="on"
                    src="../assets/icon-person-outline.svg" 
                    alt="Profile icon"
                  >
                </template>
                <span>{{$localize.get('profile')}}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div 
      class="main-toolbar__inner"
      v-if="!['Profile', 'Admin', 'Vacancy'].includes($route.name)"
      :view-name="$route.name"
    >
      <img 
        class="main-toolbar__logo"
        @click="$router.push('/').catch(error => {})"
        src="../assets/logo.png" 
        alt="Humanicl logo"
      >
      <div 
        id="nav"
        class="main-toolbar__nav-container"
        :route-name="$route.name"
      >
        <!-- landing-view: section anchors -->
        <template v-if="$route.name === 'Landing'">
          <div 
            class="main-toolbar__nav-container__item fade-in"
            v-for="(item, index) in landingPageAnchorItems"
            @click="$utils.scrollIntoView(item.nodeSelector)"
            :key="'item-' + index"
          >
            <a>
              {{$localize.get(item.name)}}
            </a>
          </div>
        </template>

        <!-- nav-items -->
        <div 
          class="main-toolbar__nav-container__inner"
          v-if="$route.name !== 'Landing'"
        >
          <div 
            class="main-toolbar__nav-container__item main-toolbar__nav-container__inner__item fade-in"
            v-for="(navItem, index) in navItemsLocalized"
            :key="'nav-item' + index"
          >
            <div
              v-if="navItem.onClick || navItem.onClickAction"
            >{{navItem.text}}
            </div>
            
            <a
              v-else-if="navItem.link"
              :href="navItem.link"
              target="_blank"
            >{{navItem.text}}
            </a>
            
            <router-link
              v-else 
              :to="navItem.to"
            >
              {{navItem.text}}
            </router-link>
          </div>
        </div>

        <div
          class="
            main-toolbar__nav-container__item
            main-toolbar__nav-container__action-items
            fade-in
          "
        >
          <h-button
            class="main-toolbar__nav-container__action-items__item"
            v-if="!user.loggedIn"
            @click.native="$store.dispatch('OPEN_LOGIN_PAGE')"
            outlined blue small-padding
          >
            {{$localize.get('join_platform')}}
          </h-button>
          <h-button
            class="main-toolbar__nav-container__action-items__item"
            v-if="user.loggedIn"
            @click.native="$router.push('/profile').catch(error => {})"
            outlined blue
          >
            {{$localize.get('my_profile')}}
          </h-button>
          <dropdown-menu
            :items="availableLanguages"
            v-model="selectedLanguage"
            item-text="name"
          >
            <template v-slot:prepend>
              <i class="mdi mdi-web"></i>
            </template>
          </dropdown-menu>
        </div>
      </div>
      <div class="main-toolbar__menu-button" @click="mainDrawerValue = true">
        <i class="mdi mdi-menu"></i>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MainToolbar',
  data () {
    return {
      
    }
  },
  computed: {
    ...mapGetters([
      'unreadNotifications',
      'navItemsLocalized',
      'navProfileItemsLocalized',
    ]),
    availableLanguages () {
      return this.$store.state.storageData.settings.localization.availableLanguages
    },
    user () {
      return this.$store.state.user
    },
    landingPageAnchorItems () {
      return this.$store.state.landingPageAnchorItems
    },
    selectedLanguage: {
      get () {
        return this.$store.state.storageData.settings.localization.selectedLanguage
      },
      set (value) {
        this.$store.state.storageData.settings.localization.selectedLanguage = value
      }
    },
    mainDrawerValue: {
      get () {
        return this.$store.state.mainDrawer.value
      },
      set (value) {
        this.$store.state.mainDrawer.value = value
        this.$store.state.overlays.window = value
      }
    }
  }
}
</script>

<style lang="scss">
.main-toolbar.v-toolbar {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: var(--main-toolbar-height) !important;
  background-color: #fff !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  @media (max-width: $xs) {
    height: var(--main-toolbar-height-xs) !important;
    border-bottom: none;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 375px) {
    height: var(--main-toolbar-height-xxs) !important;
  }
}
.v-toolbar__content {
  width: 100%;
  justify-content: center;
}
.main-toolbar {
  $breakpoint-single-row: 1400px;
  position: sticky;
  top: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: var(--main-toolbar-height);
  background-color: #fff;
  &[view="SignIn"] {
    display: none;
    @media (max-width: $breakpoint-single-row) {
      display: flex;
    }
  }
  &__menu-button {
    color: var(--blue-main);
    cursor: pointer;
    margin-top: 10px;
    i {
      font-size: 36px;
    }
    @media (min-width: 1451px) {
      display: none;
    }
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: var(--main-view-section-width);
    max-width: var(--max-content-width);
    @media (max-width: $xs) {
      width: var(--main-view-section-width-xs);
      padding: 0;
    }
    &[view-name="Profile"] {
      justify-content: unset;
      .main-toolbar__nav-container__item {
        margin: 0 32px;
      }
    }
  }
  &__logo {
    height: 32px;
    width: auto;
    cursor: pointer;
    @media (max-width: $xs) {
      height: 29px;
    }
  }
  &__main-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-left: 100px;
    &__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__nav-container {
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 600;
    @media (max-width: 1450px) {
      display: none;
    }
    &__action-items {
      display: flex;
      align-items: center;
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      padding-left: 24px;
      margin-left: 24px;
      &__item {
        &:not(:last-child) {
          margin: 0 16px;
        }
      }
    }
    &__action-icons {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &__item {
        &:not(:last-child) {
          margin-right: 32px;
        }
      }
    }
    &__item {
      font-size: 18px;
      &:not(:last-child) {
        margin: 0 24px;
      }
      &:last-child {
        margin-left: 24px;
      }
    }
    a {
      color: #2c3e50;
      text-decoration: none;
      padding: 36px 12px;
      transition: all 0.3s;
      &:hover {
        color: var(--blue-main);
        transition: all 0.3s;
      }
      &.router-link-exact-active {
        position: relative;
        color: var(--blue-main);
      }
      &.router-link-exact-active:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-bottom: 4px solid var(--main-color-1);
        bottom: -2px;
      }
    }
  }
}
.main-toolbar__nav-container__inner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-toolbar__nav-container__inner__item {
  cursor: pointer;
  padding: 36px 12px;
  transition: all 0.3s;
}
.main-toolbar__nav-container__inner__item:hover {
  color: var(--blue-main);
}
.main-toolbar__nav-container {
  justify-content: space-between;
  padding-left: 86px;
}
.main-toolbar__nav-container[route-name="Landing"] {
  .main-toolbar__nav-container__item {
    font-size: 16px;
    &:not(:last-child) {
      margin: 0 2px;
    }
  }
  .main-toolbar__nav-container__action-items {
    padding-left: 8px;
    margin-left: 8px;
  }
}
.v-application .main-toolbar__nav-container a {
  color: #2c3e50;
  user-select: none;
  white-space: nowrap;
   &:hover {
    color: var(--blue-main);
    transition: all 0.3s;
  }
} 
</style>