<template>
  <div id="landing-view">
    <content-section
      type="main"
      asset="17494b064bf99f6c990fc1801e84943e-left.jpg"
      :title="$localize.get('landing_view_page_main_title')"
      :subtitle="$localize.get('landing_view_page_main_subtitle')"
    >
      <template v-slot:buttons>
        <h-button
          class="button--join"
          @click.native="$store.dispatch('OPEN_LOGIN_PAGE')"
          large filled green
        >{{$localize.get('join_platform')}}
        </h-button>
      </template>
    </content-section>

    <content-section
      id="section-about-us"
      type="simple"
      :title="$localize.get('about_us')"
      blue-bg
      center-buttons
    >
      <template v-slot:content>
        <ul 
          class="section--about-us__content__list" 
          v-html="$localize.get('about_us_content')"
        ></ul>
      </template>
      <template v-slot:buttons>
        <h-button
          class="button--join"
          @click.native="$store.dispatch('OPEN_LOGIN_PAGE')"
          large filled green
        >{{$localize.get('join_platform')}}
        </h-button>
      </template>
    </content-section>
    
    <content-section
      id="section-features"
      type="simple"
      :title="$localize.get('features')"
      center-buttons
    >
      <template v-slot:content>
        <div class="card-container">
          <card 
            v-for="(card, index) in featuresCards"
            :key="'feature-card-' + index"
            feature-card
          >
            <div class="card__preview">
              <img :src="card.imgSrc">
            </div>
            <div class="card__sub-title">
              {{card.description}}
            </div>
          </card>
        </div>
      </template>
      <template v-slot:buttons>
        <h-button
          class="button--join"
          @click.native="$store.dispatch('OPEN_LOGIN_PAGE')"
          large filled green
        >{{$localize.get('join_platform')}}
        </h-button>
      </template>
    </content-section>
    
    <content-section
      id="section-workflow"
      type="workflow-section"
      :title="$localize.get('workflow')"
      :tabs="workflowSectionTabs"
      blue-bg
    />

    <content-section
      id="section-opportunities"
      type="simple"
      :title="$localize.get('equal_opportunities')"
      :subtitle="$localize.get('equal_opportunities_description')"
      title-color="blue"
    >
      <template v-slot:content>
        <div class="section--equal-opportunities__content">
          <div class="section--equal-opportunities__content__1">
            <div class="section--equal-opportunities__content__1__text-content">
              <div v-html="$localize.get('equal_opportunities_description')"></div>
            </div>
            <div class="section--equal-opportunities__content__1__media">
              <img src="../assets/dd44084a771a999af9f09f97e6c592ce.jpg">
              <div class="section--equal-opportunities__content__1__media__floating-block"></div>
            </div>
          </div>
          <div class="section--equal-opportunities__content__2">
            <div class="section--equal-opportunities__content__2__cards">
              <div class="section--equal-opportunities__content__2__cards__item">
                <img src="../assets/icon-globe-outline.svg">
                <div class="section--equal-opportunities__content__2__cards__item__text">
                  {{$localize.get('equal_opportunities_1')}}
                </div>
              </div>
              <div class="section--equal-opportunities__content__2__cards__item">
                <img src="../assets/icon-house.svg">
                <div class="section--equal-opportunities__content__2__cards__item__text">
                  {{$localize.get('equal_opportunities_2')}}
                </div>
              </div>
              <div class="section--equal-opportunities__content__2__cards__item">
                <img src="../assets/icon-growth.svg">
                <div class="section--equal-opportunities__content__2__cards__item__text">
                  {{$localize.get('equal_opportunities_3')}}
                </div>
              </div>
              <div class="section--equal-opportunities__content__2__cards__item">
                <img src="../assets/icon-money-bag.svg">
                <div class="section--equal-opportunities__content__2__cards__item__text">
                  {{$localize.get('equal_opportunities_4')}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:buttons>
        <h-button
          class="button--join"
          @click.native="$store.dispatch('OPEN_LOGIN_PAGE')"
          large filled green
        >{{$localize.get('apply_for_job')}}
        </h-button>
      </template>
    </content-section>
    
    <content-section
      id="section-why-us"
      type="simple"
      asset="73c7670e185944e0f7ee2e109ea43c27.jpg"
      :title="$localize.get('why_work_with_us')"
      blue-bg
    >
      <template v-slot:content>
        <div class="section--why-work-with-us__content">
          <div class="section--why-work-with-us__content__text-content">
            <div class="section--why-work-with-us__content__text-content__description-item">
              <img src="../assets/icon-num-1.svg">
              <div v-html="$localize.get('why_work_with_us_1')"></div>
            </div>
            <div class="section--why-work-with-us__content__text-content__description-item">
              <img src="../assets/icon-num-2.svg">
              <div v-html="$localize.get('why_work_with_us_2')"></div>
            </div>
            <div class="section--why-work-with-us__content__text-content__description-item">
              <img src="../assets/icon-num-3.svg">
              <div v-html="$localize.get('why_work_with_us_3')"></div>
            </div>
            <div class="section--why-work-with-us__content__text-content__description-item">
              <img src="../assets/icon-num-4.svg">
              <div v-html="$localize.get('why_work_with_us_4')"></div>
            </div>
          </div>
          <div class="section--why-work-with-us__content__media">
            <img src="../assets/73c7670e185944e0f7ee2e109ea43c27.jpg">
          </div>
        </div>
      </template>
      <template v-slot:buttons>
        <h-button
          class="button--join"
          @click.native="$store.dispatch('OPEN_LOGIN_PAGE')"
          large outlined green
        >{{$localize.get('join_platform')}}
        </h-button>
      </template>
    </content-section>
    
    <content-section
      id="section-testimonials"
      type="simple"
      :title="$localize.get('testimonials')"
      center-buttons
    >
      <template v-slot:content>
        <media-slider
          :items="testimonials"
        ></media-slider>
      </template>
      <template v-slot:buttons>
        <h-button
          class="button--join"
          @click.native="$store.dispatch('OPEN_LOGIN_PAGE')"
          large filled green
        >{{$localize.get('apply_for_job')}}
        </h-button>
      </template>
    </content-section>
  </div>
</template>

<script>
export default {
  name: 'Landing',
  computed: {
    testimonials () {return this.$store.state.testimonials},
    featuresCards () {
      return [
        {
          imgSrc: require('../assets/icon-contract.svg'),
          description: this.$localize.get('single_contact')
        },
        {
          imgSrc: require('../assets/icon-interview.svg'),
          description: this.$localize.get('automated_interview')
        },
        {
          imgSrc: require('../assets/icon-clock.svg'),
          description: this.$localize.get('automated_system')
        },
        {
          imgSrc: require('../assets/icon-auto-generation.svg'),
          description: this.$localize.get('automatic_generation')
        },
        {
          imgSrc: require('../assets/icon-chat.svg'),
          description: this.$localize.get('built_in_messaging')
        },
        {
          imgSrc: require('../assets/icon-cv.svg'),
          description: this.$localize.get('depersonalized_cv')
        },
      ]
    },
    workflowSectionTabs () {
      return {
        headers: [],
        content: [
          [
            {
              title: this.$localize.get('choose_your_project'),
              subtitle: this.$localize.get('choose_your_project_subtitle')
            },
            {
              title: this.$localize.get('project_interview'),
              subtitle: this.$localize.get('project_interview_subtitle')
            },
            {
              title: this.$localize.get('work_on_project'),
              subtitle: this.$localize.get('work_on_project_subtitle_3')
            },
            {
              title: this.$localize.get('completion_of_the_project'),
              subtitle: this.$localize.get('completion_of_the_project_subtitle_3')
            },
          ]
        ]
      }
    },
  }
}
</script>

<style lang="scss">
</style>