<template>
  <v-app id="app" :locale="selectedLanguage.locale">
    <div id="loader--main"></div>
    <main-toolbar/>
    <main-drawer/>
    <dialogs/>

    <v-main>
      <!-- Temp overlay component -->
      <transition name="fade-in-500ms">
        <div 
          class="window-overlay" 
          v-show="shoWindowOverlay"
          @click="
            $store.state.overlays.window = false
            $store.state.mainDrawer.value = false
          "
        ></div>
      </transition>
      <div class="app-content">
        <transition
          name="route-transition"
          mode="out-in"
        >
          <keep-alive :include="['Home']">
            <router-view/>
          </keep-alive>
        </transition>
      </div>
    </v-main>
    <main-footer/>
  </v-app>

</template>

<script>
export default {
  name: 'App',
  data () {
    return {
    }
  },
  mounted () {     
    this.autoSetUserLocale()
    // TODO: add PWA: installable, push notifications, offline, etc.
  },
  computed: {
    shoWindowOverlay () {
      return this.$store.state.overlays.window
    },
    availableLanguages () {
      return this.$store.state.storageData.settings.localization.availableLanguages
    },
    selectedLanguage: {
      get () {
        return this.$store.state.storageData.settings.localization.selectedLanguage
      },
      set (value) {
        this.$store.state.storageData.settings.localization.selectedLanguage = value
      }
    }
  },
  methods: {
    autoSetUserLocale () {
      let userLocale = navigator.language.toLowerCase()
      if (userLocale.startsWith('en-')) {
        userLocale = 'en'
      }
      const matchedLanguage = this.availableLanguages.find(item => item.locale === userLocale)
      if (matchedLanguage) {
        this.selectedLanguage = matchedLanguage
      }
    }
  }
}
</script>

<style lang="scss">
@import url('./assets/fonts/stylesheet.css');
:root {
  --content-horizontal-margin: 120px;
  --total-content-width: 1440px;
  --max-content-width: calc(
    var(--total-content-width) - (var(--content-horizontal-margin) * 2)
  );
  --main-view-section-width: 80%;
  --main-view-section-width-xs: 86%;
  --main-toolbar-height: 98px;
  --main-toolbar-height-xs: 84px;
  --main-toolbar-height-xxs: 71px;
  --main-color-1: #0051EE;
  --main-bg-color-1: #fff;
  --light-blue-bg-color: #F8FBFF;
  --blue-main: #0051EE;
  --blue-1: #00B4ED;
  --blue-2: #6E9FFF;
  --blue-3: #0098ED;
  --blue-4: #007BED;
  --blue-5: #0054ED;
  --red-1: #F93043;
  --green-main: #33CC66;
  --green-main-lighten-1: #35E36F;
  --green-main-darken-1: #06B941;
  --grey-1: #555555;
  --grey-2: #8692A6;
  --grey-3: #696F79;
  --grey-4: #939CB4;
  --grey-5: #F4F5FA;
  --black-1: #2e2e2e;
  --border-color-1: rgba(0, 0, 0, 0.1);
  --highlight-color-1: rgba(204, 220, 252, 1);
  --shadow-1: 0px 8px 8px rgba(0, 0, 0, 0.08);
}
body {
  margin: 0;
  height: 100vh;
  font-display: swap; // font loading optimization
}
#app {
  font-family: 'Proxima Nova Lt', Avenir, Helvetica, Arial, sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  // overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  .app-content {
    height: 100%;
  }
  span.link {
    color: var(--blue-main);
    cursor: pointer;
    font-family: 'Proxima Nova Rg';
  }
  .window-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 8;
  }
  #loader--main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--main-bg-color-1);
    z-index: 10;
    opacity: 1;
    pointer-events: none;
    animation: on-load-fade-out 2000ms ease;
    animation-fill-mode: forwards;
  }
  .line-clamp {
    &--2 {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 2;
    }
  } 
  .text--highlight {
    position: relative;
    &[text] {
      color: var(--main-color-1);
    }
    &[bold] {
      font-weight: 900;
      font-size: 48px;
    }
    &[shadow] {
      text-shadow: -8px 8px 2px var(--highlight-color-1);
    }
    &[shadow][small] {
      text-shadow: -8px 4px 2px var(--highlight-color-1);
    }
    &[blue-1] {
      color: var(--blue-1);
    }
    &[blue] {
      color: var(--blue-main);
    }
    &[blue-1] {
      color: var(--blue-1);
    }
    &[blue-3] {
      color: var(--blue-3);
    }
    &[green] {
      color: var(--green-main);
    }
    &[underline][overlapped]:after {
      content: "";
      position: absolute;
      bottom: 2px;
      left: -16px;
      width: 100%;
      z-index: -1;
      border-bottom: 32px solid var(--highlight-color-1);
    }
    &[underline]:after {
      content: "";
      position: absolute;
      bottom: 4px;
      left: 0px;
      width: 100%;
      z-index: -1;
      border-bottom: 8px solid var(--main-color-1);
    }
    &[hovering]:after {
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
    }
  }
}
// Vuetify
#app .v-input.v-input--is-focused > .v-input__control > .v-input__slot { 
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.08);
}
#app .v-input.v-input--is-focused.no-shadow > .v-input__control > .v-input__slot { 
  box-shadow: none;
}
#app .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) >.v-input__control >.v-input__slot,
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0 24px;
  }
  .v-application--is-ltr .v-text-field--outlined fieldset {
    padding-left: 20px !important;
  }
#app .padding-16.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) >.v-input__control >.v-input__slot,
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0 16px;
  }
  .v-application--is-ltr .v-text-field--outlined fieldset {
    padding-left: 12px !important;
  }
// Animations
.fade-in-500ms-enter-active {
  transition: all 0.5s ease;
}
.fade-in-500ms-leave-active {
  transition: all 0.5s ease;
}
.fade-in-500ms-enter,
.fade-in-500ms-leave-to {
  opacity: 0;
}
.dropdown-menu-enter-active {
  transition: all 0.2s ease;
}
.dropdown-menu-leave-active {
  transition: all 0.2s ease;
}
.dropdown-menu-enter,
.dropdown-menu-leave-to {
  opacity: 0;
  transform: scaleY(0);
}
.route-transition-enter-active {
  transition: all 0.3s ease;
}
.route-transition-leave-active {
  transition: all 0.3s ease;
}
.route-transition-enter,
.route-transition-leave-to {
  opacity: 0;
}
@keyframes on-load-slide-left-64 {
  0% { 
    transform: translateX(64px);
  }
  100% { 
    transform: translateX(0) scale(1);
  }
}
@keyframes on-load-slide-right-32 {
  0% { 
    transform: translateX(-32px);
  }
  100% { 
    transform: translateX(0);
  }
}
@keyframes on-load-fade-out {
  0% { 
    opacity: 1; 
  }
  100% { 
    opacity: 0; 
  }
}
@keyframes fade-in {
  0% { 
    opacity: 0; 
  }
  100% { 
    opacity: 1; 
  }
}
.fade-in {
  animation: fade-in 1000ms ease;
  animation-fill-mode: forwards;
}
</style>
