<template>
  <div>
    <!-- section | type: main -->
    <section 
      class="content__section content__section--main"
      v-if="type === 'main'"
    >
      <div class="content__section--main__inner">
        <div class="content__section--main__inner__text-content">
          <!-- main-title -->
          <div 
            class="
              content__section__text-content__main-title
              content__section--main__inner__text-content__main-title
            "
            v-html="title"
          ></div>

          <!-- subtitle -->
           <div 
            class="
              content__section__text-content__subtitle
              content__section--main__inner__text-content__subtitle
            "
            v-html="subtitle"
          ></div>

          <!-- buttons -->
          <div class="content__section--main__inner__text-content__button-container">
            <slot name="buttons"/>
          </div>
        </div>
        <div class="content__section--main__inner__media">
          <img :src="require(`../assets/${asset}`)">
          <div class="content__section--main__inner__media__hovering-block"></div>
        </div>
        <div class="content__section--main__inner__text-content__button-container" small>
          <slot name="buttons"/>
        </div>
      </div>
    </section>

    <!-- section | type: simple -->
    <section 
      class="content__section content__section--simple"
      v-if="type === 'simple'"
      :blue-bg="blueBg"
    >
      <div class="content__section--simple__inner">
        <div class="content__section--simple__inner__text-content">
          <!-- main-title -->
          <div 
            class="content__section--simple__inner__title"
            v-if="title"
            v-html="title"
            :title-color="titleСolor"
          ></div>

          <!-- content -->
          <div class="content__section--simple__inner__text-content__content-container">
            <slot name="content"/>
          </div>
        </div>
        <!-- buttons -->
        <div class="content__section--simple__inner__button-container" :center-buttons="centerButtons">
          <slot name="buttons"/>
        </div>
      </div>
    </section>

    <!-- section | type: view -->
    <section 
      class="content__section content__section--view"
      v-if="type === 'view'"
      :blue-bg="blueBg"
    >
      <div class="content__section--view__inner">
        <div class="content__section--view__inner__text-content">
          <!-- main-title -->
          <div 
            class="content__section--view__inner__title"
            v-if="title"
            v-html="title"
            :title-color="titleСolor"
          ></div>
          
          <div v-if="!title">
            <slot name="title"/>
          </div>

          <!-- content -->
          <div class="content__section--view__inner__text-content__content-container">
            <slot name="content"/>
          </div>
        </div>
        <!-- buttons -->
        <div class="content__section--view__inner__button-container" :center-buttons="centerButtons">
          <slot name="buttons"/>
        </div>
      </div>
    </section>

    <!-- section | type: sub-section -->
    <section 
      class="content__sub-section"
      v-if="type === 'sub-section'"
    >
      <div class="content__sub-section__inner">
        <div class="content__sub-section__inner__text-content">
          <div 
            class="
              content__sub-section__inner__text-content__title
            "
            v-html="title"
          ></div>
        </div>
      </div>
    </section>

    <!-- section | type: tab-section -->
    <section 
      class="content__section content__section--advantages"
      v-if="type === 'tab-section'"
    >
      <div class="content__section--advantages__inner">
        <div class="content__section--advantages__inner__text-content">
          <tabs-section 
            :tabItems="tabs.headers" 
            :blue-bg="blueBg"
            fill-width
          >
            <template v-slot:content-0>
              <slot name="content-0"/>
            </template>
            <template v-slot:content-1>
              <slot name="content-1"/>
            </template>
          </tabs-section>
        </div>
      </div>
    </section>
    
    <!-- section | type: advantages-section -->
    <section 
      class="content__section content__section--advantages"
      v-if="type === 'advantages-section'"
    >
      <div class="content__section--advantages__inner">
        <div class="content__section--advantages__inner__text-content">
          <tabs-section 
            :tabItems="tabs.headers" 
            :blue-bg="blueBg"
            fill-width
          >
            <!-- <template v-slot:content-0>
              <div class="advantages-graph">
                <img 
                  class="advantages-graph__feature-line"
                  src="../assets/adavantages-line.svg"
                >
                <div 
                  class="advantages-graph__feature-card"
                   v-for="(section, index) in tabs.content[0]"
                  :key="index"
                >
                  <div class="advantages-graph__feature-card__img-container">
                    <img :src="require(`../assets/${section.icon}`)">
                  </div>
                  <div class="advantages-graph__feature-card__title">
                    {{section.title}}
                  </div>
                  <div class="advantages-graph__feature-card__subtitle">
                    {{section.subtitle}}
                  </div>
                </div>
              </div>
              <h-button
                class="content__section--main__inner__text-content__button--join"
                @click.native="$store.dispatch('OPEN_LOGIN_PAGE')"
                large filled green
              >{{$localize.get('join_platform')}}
              </h-button>
            </template> -->
            <template v-slot:content-0>
              <div class="advantages-graph">
                <img 
                  class="advantages-graph__feature-line"
                  src="../assets/adavantages-line.svg"
                >
                <div 
                  class="advantages-graph__feature-card"
                   v-for="(section, index) in tabs.content[1]"
                  :key="index"
                >
                  <div class="advantages-graph__feature-card__img-container">
                    <img :src="require(`../assets/${section.icon}`)">
                  </div>
                  <div class="advantages-graph__feature-card__title">
                    {{section.title}}
                  </div>
                  <div class="advantages-graph__feature-card__subtitle">
                    {{section.subtitle}}
                  </div>
                </div>
              </div>
              <h-button
                class="content__section--main__inner__text-content__button--join"
                @click.native="$store.dispatch('OPEN_LOGIN_PAGE')"
                large filled green
              >{{$localize.get('join_platform')}}
              </h-button>
            </template>
          </tabs-section>
        </div>
      </div>
    </section>
    
    <!-- section | type: workflow-section -->
    <section 
      class="content__section content__section--workflow"
      v-if="type === 'workflow-section'"
    >
      <div class="content__section--workflow__inner">
        <div class="content__section--workflow__inner__text-content">
          <tabs-section 
            :tabItems="tabs.headers" 
            :blue-bg="blueBg"
            :has-title="title !== undefined"
          >
            <template v-slot:title>
              <div v-html="title"></div>
            </template>
            <!-- <template v-slot:content-0>
              <div class="workflow-graph">
                <img 
                  class="workflow-graph__work-cycle"
                  src="../assets/work-cycle.svg"
                >
                <span class="workflow-graph__work-cycle__title">
                  {{$localize.get('work_cycle')}}
                </span>
                <div 
                  :class="`workflow-graph__feature-card workflow-graph__feature-card--${index + 1}`"
                  v-for="(section, index) in tabs.content[0]"
                  :key="index"
                >
                  <div class="workflow-graph__feature-card__img-container">
                    <img :src="require(`../assets/digit-${index + 1}.svg`)">
                  </div>
                  <div class="workflow-graph__feature-card__text-container">
                    <div :class="`workflow-graph__feature-card__title workflow-graph__feature-card--${index + 1}__title`">
                      {{section.title}}
                    </div>
                    <div class="workflow-graph__feature-card__subtitle">
                      {{section.subtitle}}
                    </div>
                  </div>
                  <img 
                    class="workflow-graph__work-cycle-lines"
                    :src="require(`../assets/work-cycle-line-${index + 1}.svg`)"
                  >
                </div>
              </div>
              <h-button
                class="content__section--main__inner__text-content__button--join"
                @click.native="$store.dispatch('OPEN_LOGIN_PAGE')"
                large filled green
              >{{$localize.get('join_platform')}}
              </h-button>
            </template> -->
            <template v-slot:content-0 v-if="tabs.headers[0]">
              <div class="workflow-graph">
                <img 
                  class="workflow-graph__work-cycle"
                  src="../assets/work-cycle.svg"
                >
                <span class="workflow-graph__work-cycle__title">
                  {{$localize.get('work_cycle')}}
                </span>
                <div 
                  :class="`workflow-graph__feature-card workflow-graph__feature-card--${index + 1}`"
                  v-for="(section, index) in tabs.content[1]"
                  :key="index"
                >
                  <div class="workflow-graph__feature-card__img-container">
                    <img :src="require(`../assets/digit-${index + 1}.svg`)">
                  </div>
                  <div class="workflow-graph__feature-card__text-container">
                    <div :class="`workflow-graph__feature-card__title workflow-graph__feature-card--${index + 1}__title`">
                      {{section.title}}
                    </div>
                    <div class="workflow-graph__feature-card__subtitle">
                      {{section.subtitle}}
                    </div>
                  </div>
                  <img 
                    class="workflow-graph__work-cycle-lines"
                    :src="require(`../assets/work-cycle-line-${index + 1}.svg`)"
                  >
                </div>
              </div>
              <h-button
                class="content__section--main__inner__text-content__button--join"
                @click.native="$store.dispatch('OPEN_LOGIN_PAGE')"
                large filled green
              >{{$localize.get('join_platform')}}
              </h-button>
            </template>
          </tabs-section>
        </div>
      </div>
    </section>
     
    <!-- section | type: slogan-section -->
    <section 
      class="content__section content__section--slogan"
      v-if="type === 'slogan-section'"
    >
      <div class="content__section--slogan__inner">
        <div class="content__section--slogan__inner__text-content">
          <div 
            class="
              content__section__text-content__subtitle
              content__section--slogan__inner__text-content__subtitle
            "
            :lang="selectedLanguage.locale"
          >
            <div v-html="$localize.get('home_page_slogan')"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ContentSection',
  props: {
    type: String,
    title: String,
    subtitle: String,
    asset: String,
    titleСolor: String,
    blueBg: Boolean,
    centerButtons: Boolean,
    tabs: Object,
  },
  data () {
    return {
    }
  },
  computed: {
    selectedLanguage () {
      return this.$store.state.storageData.settings.localization.selectedLanguage
    }
  }
}
</script>

<style lang="scss">
.advantages-graph {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-bottom: 280px;
  @media (max-width: $md) {
    flex-direction: column;
    margin-bottom: 16px;
  }
  &__feature-line {
    left: 0;
    top: 0;
    width: 100%;
    max-width: 3000px;
    @media (max-width: $md) {
      display: none;
    }
  }
  &__feature-card {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 250px;
    &:nth-of-type(1) {
      left: 6%;
      top: 60%;
      @media (max-width: 1400px) {
        left: 4%;
      }
    }
    &:nth-of-type(2) {
      left: 29%;
      top: 25%;
      @media (max-width: 1400px) {
        left: 26%;
      }
    }
    &:nth-of-type(3) {
      left: 52%;
      top: 65%;
      @media (max-width: 1560px) {
        left: 50%;
        @media (max-width: 1400px) {
          left: 48%;
        }
      }
    }
    &:nth-of-type(4) {
      right: 4%;
      top: 15%;
      @media (max-width: 1400px) {
        right: 2%;
      }
    }
    @media (max-width: $md) {
      position: initial;
      margin-bottom: 32px;
      width: auto;
    }
    @media (max-width: 375px) {
      margin-bottom: 16px;
    }
    &__img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 128px;
      height: 128px;
      @media (min-width: $sm) and (max-width: 1560px) {
        width: 100px;
        height: 100px;
      }
      border-radius: 50%;
      box-shadow: 0px 24px 42px rgba(165, 165, 165, 0.2),
        0px -14px 12px -13px rgba(0,0,0,0.1) inset;
      margin-bottom: 24px;
      @media (max-width: $md) {
        box-shadow: none;
        margin-bottom: 0px;
      }
      @media (min-width: $md) {
        background: #FFFFFF;
      }
      img {
        width: 56px;
        height: 56px;
        @media (min-width: $sm) and (max-width: 1560px) {
          width: 42px;
          height: 42px;
        }
        @media (max-width: $xs) {
          height: 62px;
          width: 62px;
        }
      }
    } 
    &__title {
      font-size: 20px;
      font-weight: 600;
      font-family: 'Proxima Nova Rg';
      margin-bottom: 12px;
      color: var(--blue-main);
      text-transform: uppercase;
      @media (min-width: $sm) and (max-width: 1560px) {
        width: unset;
        font-size: 20px;
      }
      @media (max-width: $xs) {
        font-size: 20px;
        font-family: 'Proxima Nova Rg';
      }
      @media (max-width: 375px) {
        font-size: 18px;
      }
    }
    &__subtitle {
      margin-bottom: 12px;
      font-size: 20px;
      line-height: 32px;
      width: 100%;
      @media (min-width: $sm) and (max-width: 1560px) {
        font-size: 20px;
      }
      @media (max-width: $xs) {
        font-size: 20px;
        font-family: 'Proxima Nova Lt';
      }
    }
  }
}

.workflow-graph {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 700px;
  width: 100%;
  margin-bottom: 24px;
  @media (max-width: $md) {
    flex-direction: column;
    height: unset;
    margin: 0;
  }
  &__work-cycle {
    position: relative;
    width: 518px;
    height: 518px;
    z-index: 2;
    @media (max-width: $md) {
      display: none;
    }
    @media (min-width: 1460px) and (max-width: 1600px) {
      width: 74%;
      height: 74%;
    }
    @media (max-width: 1459px) {
      width: 60%;
      height: 60%;
    }
    img {
      z-index: 2;
    }
    &__title {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-transform: uppercase;
      color: var(--blue-main);
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      width: 120px;
      z-index: 3;
      @media (min-width: 1460px) and (max-width: 1600px) {
        font-size: 30px;
      }
      @media (max-width: 1459px) {
        font-size: 24px;
        line-height: 42px;
      }
      @media (max-width: $md) {
        display: none;
      }
    }
  }
  &__work-cycle-lines {
    top: 96px;
    @media (max-width: $md) {
      display: none;
    }
  }
  &__feature-card {
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 40%;
    @media (max-width: $md) {
      align-items: center;
    }
    &:nth-of-type(1) {
      left: 0%;
      top: 0%;
    }
    &:nth-of-type(2) {
      right: 0%;
      top: 0%;
      .workflow-graph__feature-card__title,
      .workflow-graph__feature-card__subtitle {
        transform: translateX(100px);
        @media (max-width: $md) {
          transform: translateX(0);
        }
      }
    }
    &:nth-of-type(3) {
      flex-direction: column-reverse;
      right: 0%;
      top: 60%;
      @media (max-width: $md) {
        flex-direction: column;
      }
      .workflow-graph__feature-card__title,
      .workflow-graph__feature-card__subtitle {
        transform: translateX(100px);
        @media (max-width: $md) {
          transform: translateX(0);
        }
      }
      img {
        margin-bottom: 16px;
        @media (max-width: $xs) {
          margin-bottom: 0;
        }
      }
    }
    &:nth-of-type(4) {
      flex-direction: column-reverse;
      left: 0%;
      top: 60%;
      @media (max-width: $md) {
        flex-direction: column;
      }
      img {
        margin-bottom: 16px;
        @media (max-width: $xs) {
          margin-bottom: 0;
        }
      }
    }
    @media (max-width: $md) {
      position: initial;
      margin-bottom: 64px;
      width: auto;
      text-align: center;
    }
    &__text-container {
      @media (max-width: $md) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }
    &__img-container {
      display: none;
      width: 128px;
      height: 128px;
      border-radius: 50%;
      background: #FFFFFF;
      box-shadow: 0px 24px 42px rgba(165, 165, 165, 0.2),
        0px -14px 12px -13px rgba(0,0,0,0.1) inset;
      margin-bottom: 24px;
      color: var(--blue-1);
      img {
        width: 36px;
        height: 36px;
        @media (max-width: $xs) {
          width: 24px;
          height: 24px;
        }
      }
      @media (max-width: $md) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @media (max-width: $xs) {
        width: 70px;
        height: 70px;
      }
    } 
    &__title {
      font-size: 24px;
      font-weight: 600;
      font-family: 'Proxima Nova Rg';
      width: 356px;
      min-height: 48px;
      text-transform: uppercase;
      line-height: 40px;
      color: var(--blue-1);
      @media (max-width: $xs) {
        width: unset;
      }
      @media (max-width: 375px) {
        font-size: 18px;
      }
    }
    &--1 {
      &__title {
        color: var(--blue-1);
      }
    }
    &--2 {
      &__title {
        color: var(--blue-3);
      }
    }
    &--3 {
      &__title {
        color: var(--blue-4);
      }
    }
    &--4 {
      &__title {
        color: var(--blue-5);
      }
    }
    &__subtitle {
      font-size: 18px;
      line-height: 160%;
      margin-bottom: 24px;
      width: 300px;
      min-height: 48px;
      @media (max-width: $md) {
        width: 80%;
      }
    }
  }
}

.content {
  &__sub-section {
    &[blue-bg] {
      background-color: var(--light-blue-bg-color);
    }
    height: 270px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    @media (max-width: $xs) {
      height: 180px;
    }
    &__inner {
      max-width: var(--max-content-width);
      width: var(--main-view-section-width);
      @media (max-width: $xs) {
        width: var(--main-view-section-width-xs);
      }
      &__text-content {
        height: 100%;
        display: flex;
        align-items: center;
        margin: auto;
        @media (max-width: $xs) {
          justify-content: center;
        }
        &__title {
          color: var(--main-color-1);
          font-weight: 900;
          line-height: 114%;
          font-family: 'Proxima Nova Bl';
          font-size: 60px;
          @media (max-width: $xs) {
            font-size: 32px;
          }
        }
      }
    }
  }
  &__section {
    &[blue-bg] {
      background-color: var(--light-blue-bg-color);
    }
    .button {
      &--join {
        margin-right: 12px;
        @media (max-width: $md) {
          margin-right: 0;
          margin-bottom: 12px;
        }
      }
    }
    &__text-content {
      &__subtitle {
        max-width: 600px;
        margin-bottom: 32px;
        font-size: 24px;
        line-height: 160%;
        text-align: left;
      }
    }
    &--simple, &--view {
      height: 100%;
      &__inner {
        height: 100%;
        padding: 112px 0;
        margin: 0 auto;
        width: var(--main-view-section-width);
        max-width: var(--max-content-width);
        @media (max-width: $xs) {
          width: var(--main-view-section-width-xs);
        }
        &__title {
          text-align: left;
          margin-bottom: 84px;
          color: var(--main-color-1);
          font-weight: 900;
          line-height: 114%;
          font-family: 'Proxima Nova Bl';
          font-size: 60px;
          @media (max-width: $xs) {
            font-size: 32px;
            margin-bottom: 56px;
            text-align: center;
          }
        }
        &__сontent-container {
          margin-bottom: 96px;
        }
        &__button-container {
          margin-top: 100px;
          &[center-buttons] {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          @media (max-width: $md) {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 64px;
          }
        }
      }
      .card-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(387px, 1fr));
        gap: 20px;
        @media (max-width: $xs) {
          padding: 0;
          width: 80%;
          margin: 0 auto;
          grid-template-columns: 1fr;
        }
      }
    }
    &--view {
      &__inner {
        padding: 52px 0;
        &__title {
          margin-bottom: 52px;
        }
      }
    }
    &--main {
      &__inner {
        display: grid;
        grid-template-columns: 1.4fr 1fr;
        align-items: center;
        margin: auto;
        gap: 48px;
        text-align: left;
        min-height: calc(100vh - var(--main-toolbar-height));
        width: var(--main-view-section-width);
        max-width: var(--max-content-width);
        @media (max-width: $md) {
          display: block;
        }
        @media (min-width: 2100px) {
          min-height: 800px;
        }
        @media (max-width: $xs) {
          width: var(--main-view-section-width-xs);
        }
        &__text-content {
          padding: 128px 0;
          @media (max-width: $md) {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 64px;
            padding-bottom: 0;
          }
          @media (min-width: $xs) {
            align-items: flex-start;
          }
          @media (max-width: $xs) {
            padding: 8px;
            padding-top: 36px;
          }
          &__main-title {
            margin-bottom: 24px;
            font-family: 'Proxima Nova Bl';
            line-height: 114%;
            font-size: 56px;
            animation: on-load-slide-right-32 1500ms ease, fade-in 1500ms ease;
            animation-fill-mode: forwards;
            @media (max-width: 720px) {
              font-size: 48px;
            }
            @media (max-width: $xs) {
              font-size: 32px;
              margin-bottom: 16px;
              text-align: center;
            }
          }
          &__subtitle {
            font-size: 20px;
            max-width: 450px;
            @media (max-width: $sm) {
              font-size: 20px;
              width: unset;
            }
            @media (max-width: $xs) {
              font-family: 'Proxima Nova Lt';
              font-size: 16px;
              text-align: center;
              margin-bottom: 16px;
              max-width: 300px;
            }
          }
          &__button-container {
            display: flex;
            align-items: center;
            flex-direction: row;
            @media (max-width: $md) {
              display: none;
            }
            &[small] {
              display: flex;
              flex-direction: column;
              margin: 36px 0;
              @media (min-width: $md) {
                display: none;
              }
            }
          }
          &__button {
            &--join {
              margin-right: 12px;
              @media (max-width: $md) {
                margin-right: 0;
                margin-bottom: 12px;
              }
            }
          }
        }
        &__media {
          position: relative;
          z-index: 1;
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          @media (max-width: $md) {
            justify-content: center;
            height: 600px;
          }
          @media (max-width: $sm) {
            justify-content: center;
          }
          @media (max-width: $xs) {
            justify-content: flex-start;
            height: 318px;
          }
          &__hovering-block {
            position: absolute;
            width: 110%;
            min-width: 400px;
            height: 100%;
            right: 0;
            top: 0;
            background: var(--main-color-1);
            transform: translateX(300px);
            z-index: -1;
            @media (min-width: $lg) {
              transform: translateX(400px);
            }
            @media (max-width: $xs) {
              transform: translateX(100px);
              min-width: 300px;
              width: 60%;
            }
            @media (max-width: $sm) {
              transform: translateX(100px);
              min-width: 300px;
              width: 80%;
            }
          }
          img {
            // margin: 84px 0;
            width: 100%;
            max-width: 550px;
            height: 620px;
            object-fit: cover;
            object-position: 70% 0%;
            box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.04);
            animation: on-load-slide-left-64 1500ms ease, fade-in 1000ms ease;
            animation-fill-mode: forwards;
            @media (max-width: $md) {
              max-width: unset;
              height: 500px;
            }
            @media (max-width: $xs) {
              max-width: unset;
              height: 281px;
            }
          }
        }
      }
    }
    &--features {
      &__inner {
        padding: 112px 0;
        margin: 0 auto;
        width: var(--main-view-section-width);
        max-width: var(--max-content-width);
        @media (max-width: $xs) {
          width: var(--main-view-section-width-xs);
        }
        &__title {
          text-align: left;
          margin-bottom: 84px;
          color: var(--main-color-1);
          font-weight: 900;
          line-height: 114%;
          font-family: 'Proxima Nova Bl';
          font-size: 60px;
          @media (max-width: $xs) {
            font-size: 32px;
            margin-bottom: 56px;
            text-align: center;
          }
        }
        &__text-content {
          margin-bottom: 96px;
        }
        &__actions {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .card-container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(387px, 1fr));
        gap: 20px;
        @media (max-width: $xs) {
          padding: 0;
          width: 80%;
          margin: 0 auto;
          grid-template-columns: 1fr;
        }
      }
    }
    &--advantages {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 100%;
        &__text-content {
          width: 100%;
        }
      }
    }
    &--workflow {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      &[has-tabs] {
        margin-top: -42px;
        @media (max-width: $xs) {
          margin-top: -44px;
        }
      }
      &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        width: 100%;
        &__text-content {
          width: 100%;
        }
      }
    }
    &--slogan {
      font-family: 'Proxima Nova Th';
      padding: 128px 0;
      margin: auto;
      width: var(--main-view-section-width);
      max-width: var(--max-content-width);
      @media (max-width: $xs) {
        width: var(--main-view-section-width-xs);
        font-family: 'Proxima Nova Bl';
      }
      &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        &__text-content {
            width: 100%;
          &__subtitle {
            font-weight: 900;
            color: #000;
            width: auto;
            max-width: unset;
            font-size: 52px;
            text-align: center;
            letter-spacing: -0.04em;
            @media (max-width: 1370px) {
              font-size: 42px;
            }
            @media (max-width: 1100px) {
              font-size: 32px
            }
            @media (max-width: 375px) {
              font-size: 28px;
            }
            & div {
              text-align: left;
              width: 100%;
            }
            & div:nth-child(1) {
              margin-left: 0;
              margin-bottom: 24px;
              @media (max-width: $xs) {
                margin-left: 0;
              }
            }
            & div:nth-child(2) {
              margin-left: 20%;
              margin-bottom: 24px;
              @media (max-width: $xs) {
                text-align: right;
                margin-left: 10px;
              }
            }
            & div:nth-child(3) {
              margin-left: 10%;
              @media (max-width: $xs) {
                margin-left: 10px;
              }
            }
          }
          &__subtitle[lang="ru"] {
            font-size: 42px;
            @media (max-width: 1370px) {
              font-size: 36px;
            }
            @media (max-width: 1100px) {
              font-size: 32px
            }
            @media (max-width: 375px) {
              font-size: 28px;
            }
            & div:nth-child(2) {
              margin-left: 10%;
              margin-bottom: 24px;
              @media (max-width: $xs) {
                text-align: right;
                margin-left: 10px;
              }
            }
            & div:nth-child(3) {
              margin-left: 6%;
              @media (max-width: $xs) {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
  }
}
.padding-24 {
  .content__section--view__inner {
    padding: 24px 0;
  }
}

.section {
  &--why-work-with-us {
    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
      &__text-content {
        margin-right: 64px;
        font-size: 18px;
        @media (max-width: 375px) {
          font-size: 16px;
        }
        &__description-item {
          display: flex;
          align-items: center;
          margin-bottom: 48px;
          img {
            margin-right: 16px;
          }
        }
      }
      &__media {
        img {
          box-shadow: var(--shadow-1);
        }
        @media (max-width: $md) {
          display: none;
        }
      }
    }
  }
  &--about-us {
    &__content {
      &__list {
        font-size: 20px;
        margin-bottom: 64px;
        @media (max-width: $xs) {
          font-size: 16px;
        }
        li {
          margin: 16px 0;
        }
      }
    }
  }
  &--equal-opportunities {
    &__content {
      display: grid;
      justify-content: center;
      gap: 122px;
      grid-template-columns: 1fr 1fr;
      max-width: var(--max-content-width);
      @media (max-width: $md) {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        gap: unset;
      }
      @media (max-width: $xs) {
        width: var(--main-view-section-width-xs);
      }
      &__1 {
        @media (max-width: $sm) {
          display: flex;
          flex-direction: column-reverse;
          margin-bottom: 64px;
        }
        &__text-content {
          max-width: 488px;
          font-size: 18px;
          line-height: 161%;
          letter-spacing: 0.04em;
          @media (max-width: $md) {
            max-width: 100%;
          }
        }
        &__media {
          margin: 36px 0;
          position: relative;
          max-width: 488px;
          height: 385px;
          @media (max-width: $md) {
            max-width: 100%;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          &__floating-block {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--blue-main);
            transform: translateX(-144px);
            z-index: -1;
          }
        }
      }
      &__2 {
        &__cards {
          display: grid;
          align-items: center;
          justify-content: center;
          grid-template-columns: 1fr 1fr;
          gap: 24px;
          @media (max-width: 375px) {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
          }
          &__item {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 272px;
            height: 272px;
            margin: 0 auto;
            border-radius: 50%;
            box-shadow: var(--shadow-1);
            &:hover {
              color: #fff;
              background-color: var(--blue-main);
              transition: background-color 0.2s;
              img {
                filter: brightness(100)
              }
            }
            @media (max-width: $sm) {
              width: 154px;
              height: 154px;
            }
            img {
              @media (max-width: $sm) {
                width: 42px;
                height: 42px;
              }
            }
            &__text {
              padding: 16px 48px;
              text-align: center;
              @media (max-width: $sm) {
                padding: 6px 20px;
                font-size: 14px;
              }
            }
          }
        }
      }
    } 
  }
}
#app[locale="ru"]
  .content__section--advantages
    .content__section--main__inner__text-content__button--join {
      margin-top: 64px;
    }
</style>